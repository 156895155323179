/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An object that represents the current status of approvals on a target.
 * @export
 * @interface ApprovalStatus
 */
export interface ApprovalStatus {
    /**
     * Currently supports only node id. Might be extended to support any other target that requires approvals.
     * @type {string}
     * @memberof ApprovalStatus
     */
    targetId?: string;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStatus
     */
    requiredCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStatus
     */
    completedCount?: number;
    /**
     * A list of user ids who already approved.
     * @type {Array<string>}
     * @memberof ApprovalStatus
     */
    approvedBy?: Array<string>;
}

export function ApprovalStatusFromJSON(json: any): ApprovalStatus {
    return ApprovalStatusFromJSONTyped(json, false);
}

export function ApprovalStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprovalStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetId': !exists(json, 'targetId') ? undefined : json['targetId'],
        'requiredCount': !exists(json, 'requiredCount') ? undefined : json['requiredCount'],
        'completedCount': !exists(json, 'completedCount') ? undefined : json['completedCount'],
        'approvedBy': !exists(json, 'approvedBy') ? undefined : (json['approvedBy'] as Array<string>),
    };
}

export function ApprovalStatusToJSON(value?: ApprovalStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetId': value.targetId,
        'requiredCount': value.requiredCount,
        'completedCount': value.completedCount,
        'approvedBy': value.approvedBy === undefined ? undefined : (value.approvedBy as Array<string>),
    };
}

