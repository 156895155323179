/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleAction } from './RuleAction';
import {
    RuleActionFromJSON,
    RuleActionFromJSONTyped,
    RuleActionToJSON,
} from './RuleAction';
import type { RuleCondition } from './RuleCondition';
import {
    RuleConditionFromJSON,
    RuleConditionFromJSONTyped,
    RuleConditionToJSON,
} from './RuleCondition';
import type { RuleType } from './RuleType';
import {
    RuleTypeFromJSON,
    RuleTypeFromJSONTyped,
    RuleTypeToJSON,
} from './RuleType';

/**
 * Full Rule Configuration object
 * @export
 * @interface RuleConfiguration
 */
export interface RuleConfiguration {
    /**
     * 
     * @type {string}
     * @memberof RuleConfiguration
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleConfiguration
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleConfiguration
     */
    workflowId?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleConfiguration
     */
    nodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleConfiguration
     */
    sectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleConfiguration
     */
    formElementId?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleConfiguration
     */
    optionId?: string;
    /**
     * 
     * @type {RuleType}
     * @memberof RuleConfiguration
     */
    ruleType?: RuleType;
    /**
     * 
     * @type {Array<RuleCondition>}
     * @memberof RuleConfiguration
     */
    conditions?: Array<RuleCondition>;
    /**
     * 
     * @type {Array<RuleAction>}
     * @memberof RuleConfiguration
     */
    actions?: Array<RuleAction>;
}

export function RuleConfigurationFromJSON(json: any): RuleConfiguration {
    return RuleConfigurationFromJSONTyped(json, false);
}

export function RuleConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'sectionId': !exists(json, 'sectionId') ? undefined : json['sectionId'],
        'formElementId': !exists(json, 'formElementId') ? undefined : json['formElementId'],
        'optionId': !exists(json, 'optionId') ? undefined : json['optionId'],
        'ruleType': !exists(json, 'ruleType') ? undefined : RuleTypeFromJSON(json['ruleType']),
        'conditions': !exists(json, 'conditions') ? undefined : (json['conditions'] as Array<RuleCondition>).map(RuleConditionFromJSON),
        'actions': !exists(json, 'actions') ? undefined : (json['actions'] as Array<RuleAction>).map(RuleActionFromJSON),
    };
}

export function RuleConfigurationToJSON(value?: RuleConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'workflowId': value.workflowId,
        'nodeId': value.nodeId,
        'sectionId': value.sectionId,
        'formElementId': value.formElementId,
        'optionId': value.optionId,
        'ruleType': RuleTypeToJSON(value.ruleType),
        'conditions': value.conditions === undefined ? undefined : (value.conditions as Array<RuleCondition>).map(RuleConditionToJSON),
        'actions': value.actions === undefined ? undefined : (value.actions as Array<RuleAction>).map(RuleActionToJSON),
    };
}

