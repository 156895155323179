/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleActionValue } from './RuleActionValue';
import {
    RuleActionValueFromJSON,
    RuleActionValueFromJSONTyped,
    RuleActionValueToJSON,
} from './RuleActionValue';
import type { TargetType } from './TargetType';
import {
    TargetTypeFromJSON,
    TargetTypeFromJSONTyped,
    TargetTypeToJSON,
} from './TargetType';

/**
 * Rule Action
 * @export
 * @interface RuleAction
 */
export interface RuleAction {
    /**
     * 
     * @type {string}
     * @memberof RuleAction
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleAction
     */
    targetId?: string;
    /**
     * 
     * @type {RuleActionValue}
     * @memberof RuleAction
     */
    ruleActionValue?: RuleActionValue;
    /**
     * 
     * @type {TargetType}
     * @memberof RuleAction
     */
    targetType?: TargetType;
    /**
     * 
     * @type {number}
     * @memberof RuleAction
     */
    order?: number;
}

export function RuleActionFromJSON(json: any): RuleAction {
    return RuleActionFromJSONTyped(json, false);
}

export function RuleActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'targetId': !exists(json, 'targetId') ? undefined : json['targetId'],
        'ruleActionValue': !exists(json, 'ruleActionValue') ? undefined : RuleActionValueFromJSON(json['ruleActionValue']),
        'targetType': !exists(json, 'targetType') ? undefined : TargetTypeFromJSON(json['targetType']),
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function RuleActionToJSON(value?: RuleAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'targetId': value.targetId,
        'ruleActionValue': RuleActionValueToJSON(value.ruleActionValue),
        'targetType': TargetTypeToJSON(value.targetType),
        'order': value.order,
    };
}

