import { apiConfig } from "@mc/application-settings";
import {
    ActionItemApi,
    AttachmentApi,
    CorrectionApi,
    ParticipantApi,
    RecordExecutionConfiguration,
    RecordExecutionLockApi,
    RecordExecutionNodeApi,
    RecordExecutionRecordApi,
    RecordExecutionRecordElementLockUnlockApi,
    RecordExecutionTaskApi,
    RecordExecutionUserApi,
    RecordLinkApi,
    RecordV2Api,
} from "./recordExecution.types";

/** @deprecated Use configuration from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionConfiguration = new RecordExecutionConfiguration(apiConfig);
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionActionItemApi = { v1: new ActionItemApi(recordExecutionConfiguration) };
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionAttachmentApi = { v1: new AttachmentApi(recordExecutionConfiguration) };
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionCorrectionApi = { v1: new CorrectionApi(recordExecutionConfiguration) };
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionLockApi = { v1: new RecordExecutionLockApi(recordExecutionConfiguration) };
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionParticipantApi = { v1: new ParticipantApi(recordExecutionConfiguration) };
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionRecordApi = {
    v1: new RecordExecutionRecordApi(recordExecutionConfiguration),
    v2: new RecordV2Api(recordExecutionConfiguration),
};
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionRecordElementLockUnlockApi = {
    v1: new RecordExecutionRecordElementLockUnlockApi(recordExecutionConfiguration),
};
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionRecordLinkApi = { v1: new RecordLinkApi(recordExecutionConfiguration) };
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionRecordNodeApi = { v1: new RecordExecutionNodeApi(recordExecutionConfiguration) };
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionTaskApi = { v1: new RecordExecutionTaskApi(recordExecutionConfiguration) };
/** @deprecated Use api from `/apps/aqem/shared/src/api/record-execution-api.ts` instead */
export const recordExecutionUserRecordApi = { v1: new RecordExecutionUserApi(recordExecutionConfiguration) };
