/* tslint:disable */
/* eslint-disable */
/**
 * Validation on Demand OpenAPI Definition
 * Validation on Demand negotiated endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LogbooksValidationRequest,
    LogbooksValidationRequestFromJSON,
    LogbooksValidationRequestToJSON,
    PCSBatchValidationPlanStatusesResponse,
    PCSBatchValidationPlanStatusesResponseFromJSON,
    PCSBatchValidationPlanStatusesResponseToJSON,
    ValidationCreatedResponse,
    ValidationCreatedResponseFromJSON,
    ValidationCreatedResponseToJSON,
} from '../models';

export interface CreateLogbooksValidationRequest {
    logbooksValidationRequest: LogbooksValidationRequest;
    pcsTenantid?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface GetLogbooksValidationStatusesRequest {
    validationPlanIds: Array<string>;
}

/**
 * 
 */
export class LogbooksApi extends runtime.BaseAPI {

    /**
     * Create and execute a validation plan on a logbook template. Guarded by s2s
     * Create and execute a validation plan on a logbook template. This is guarded by a s2s token
     */
    async createLogbooksValidationRaw(requestParameters: CreateLogbooksValidationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ValidationCreatedResponse>> {
        if (requestParameters.logbooksValidationRequest === null || requestParameters.logbooksValidationRequest === undefined) {
            throw new runtime.RequiredError('logbooksValidationRequest','Required parameter requestParameters.logbooksValidationRequest was null or undefined when calling createLogbooksValidation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/validation-on-demand/logbooks/validation/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogbooksValidationRequestToJSON(requestParameters.logbooksValidationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationCreatedResponseFromJSON(jsonValue));
    }

    /**
     * Create and execute a validation plan on a logbook template. Guarded by s2s
     * Create and execute a validation plan on a logbook template. This is guarded by a s2s token
     */
    async createLogbooksValidation(requestParameters: CreateLogbooksValidationRequest, initOverrides?: RequestInit): Promise<ValidationCreatedResponse> {
        const response = await this.createLogbooksValidationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a list of validation plan ids, returns a map of those ids to their associated statuses for PCS. This endpoint has a limit of 100 validationPlanIds, if more are sent, only the first 100 are looked at. Guarded by FULL/BASIC token. If a plan is not found, the id will be the key and the value will be null
     * GET pcs/validation-on-demand/logbooks/v1/validation/statuses?validationPlanIds={validationPlanIds}. example - /pcs/validation-on-demand/pcs/validation/statuses?validationPlanIds=ab2cb71a-b2e2-43f4-a69c-070e5edb119d,ab2cb71a-b2e2-43f4-a69c-070e5edb119e
     */
    async getLogbooksValidationStatusesRaw(requestParameters: GetLogbooksValidationStatusesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PCSBatchValidationPlanStatusesResponse>> {
        if (requestParameters.validationPlanIds === null || requestParameters.validationPlanIds === undefined) {
            throw new runtime.RequiredError('validationPlanIds','Required parameter requestParameters.validationPlanIds was null or undefined when calling getLogbooksValidationStatuses.');
        }

        const queryParameters: any = {};

        if (requestParameters.validationPlanIds) {
            queryParameters['validationPlanIds'] = requestParameters.validationPlanIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/validation-on-demand/logbooks/v1/validation/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PCSBatchValidationPlanStatusesResponseFromJSON(jsonValue));
    }

    /**
     * Takes a list of validation plan ids, returns a map of those ids to their associated statuses for PCS. This endpoint has a limit of 100 validationPlanIds, if more are sent, only the first 100 are looked at. Guarded by FULL/BASIC token. If a plan is not found, the id will be the key and the value will be null
     * GET pcs/validation-on-demand/logbooks/v1/validation/statuses?validationPlanIds={validationPlanIds}. example - /pcs/validation-on-demand/pcs/validation/statuses?validationPlanIds=ab2cb71a-b2e2-43f4-a69c-070e5edb119d,ab2cb71a-b2e2-43f4-a69c-070e5edb119e
     */
    async getLogbooksValidationStatuses(requestParameters: GetLogbooksValidationStatusesRequest, initOverrides?: RequestInit): Promise<PCSBatchValidationPlanStatusesResponse> {
        const response = await this.getLogbooksValidationStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
