/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationError } from './ValidationError';
import {
    ValidationErrorFromJSON,
    ValidationErrorFromJSONTyped,
    ValidationErrorToJSON,
} from './ValidationError';
import type { ValidationWarning } from './ValidationWarning';
import {
    ValidationWarningFromJSON,
    ValidationWarningFromJSONTyped,
    ValidationWarningToJSON,
} from './ValidationWarning';

/**
 * 
 * @export
 * @interface WorkflowConfigurationValidationResponseBody
 */
export interface WorkflowConfigurationValidationResponseBody {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof WorkflowConfigurationValidationResponseBody
     */
    workflowValidationErrors?: Array<ValidationError>;
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof WorkflowConfigurationValidationResponseBody
     */
    nodeValidationErrors?: Array<ValidationError>;
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof WorkflowConfigurationValidationResponseBody
     */
    sectionValidationErrors?: Array<ValidationError>;
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof WorkflowConfigurationValidationResponseBody
     */
    formElementValidationErrors?: Array<ValidationError>;
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof WorkflowConfigurationValidationResponseBody
     */
    roleValidationErrors?: Array<ValidationError>;
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof WorkflowConfigurationValidationResponseBody
     */
    ruleValidationErrors?: Array<ValidationError>;
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof WorkflowConfigurationValidationResponseBody
     */
    approvalValidationErrors?: Array<ValidationError>;
    /**
     * 
     * @type {Array<ValidationWarning>}
     * @memberof WorkflowConfigurationValidationResponseBody
     */
    workflowValidationWarnings?: Array<ValidationWarning>;
}

export function WorkflowConfigurationValidationResponseBodyFromJSON(json: any): WorkflowConfigurationValidationResponseBody {
    return WorkflowConfigurationValidationResponseBodyFromJSONTyped(json, false);
}

export function WorkflowConfigurationValidationResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowConfigurationValidationResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflowValidationErrors': !exists(json, 'workflowValidationErrors') ? undefined : (json['workflowValidationErrors'] as Array<ValidationError>).map(ValidationErrorFromJSON),
        'nodeValidationErrors': !exists(json, 'nodeValidationErrors') ? undefined : (json['nodeValidationErrors'] as Array<ValidationError>).map(ValidationErrorFromJSON),
        'sectionValidationErrors': !exists(json, 'sectionValidationErrors') ? undefined : (json['sectionValidationErrors'] as Array<ValidationError>).map(ValidationErrorFromJSON),
        'formElementValidationErrors': !exists(json, 'formElementValidationErrors') ? undefined : (json['formElementValidationErrors'] as Array<ValidationError>).map(ValidationErrorFromJSON),
        'roleValidationErrors': !exists(json, 'roleValidationErrors') ? undefined : (json['roleValidationErrors'] as Array<ValidationError>).map(ValidationErrorFromJSON),
        'ruleValidationErrors': !exists(json, 'ruleValidationErrors') ? undefined : (json['ruleValidationErrors'] as Array<ValidationError>).map(ValidationErrorFromJSON),
        'approvalValidationErrors': !exists(json, 'approvalValidationErrors') ? undefined : (json['approvalValidationErrors'] as Array<ValidationError>).map(ValidationErrorFromJSON),
        'workflowValidationWarnings': !exists(json, 'workflowValidationWarnings') ? undefined : (json['workflowValidationWarnings'] as Array<ValidationWarning>).map(ValidationWarningFromJSON),
    };
}

export function WorkflowConfigurationValidationResponseBodyToJSON(value?: WorkflowConfigurationValidationResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflowValidationErrors': value.workflowValidationErrors === undefined ? undefined : (value.workflowValidationErrors as Array<ValidationError>).map(ValidationErrorToJSON),
        'nodeValidationErrors': value.nodeValidationErrors === undefined ? undefined : (value.nodeValidationErrors as Array<ValidationError>).map(ValidationErrorToJSON),
        'sectionValidationErrors': value.sectionValidationErrors === undefined ? undefined : (value.sectionValidationErrors as Array<ValidationError>).map(ValidationErrorToJSON),
        'formElementValidationErrors': value.formElementValidationErrors === undefined ? undefined : (value.formElementValidationErrors as Array<ValidationError>).map(ValidationErrorToJSON),
        'roleValidationErrors': value.roleValidationErrors === undefined ? undefined : (value.roleValidationErrors as Array<ValidationError>).map(ValidationErrorToJSON),
        'ruleValidationErrors': value.ruleValidationErrors === undefined ? undefined : (value.ruleValidationErrors as Array<ValidationError>).map(ValidationErrorToJSON),
        'approvalValidationErrors': value.approvalValidationErrors === undefined ? undefined : (value.approvalValidationErrors as Array<ValidationError>).map(ValidationErrorToJSON),
        'workflowValidationWarnings': value.workflowValidationWarnings === undefined ? undefined : (value.workflowValidationWarnings as Array<ValidationWarning>).map(ValidationWarningToJSON),
    };
}

