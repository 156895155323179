/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Lock } from './Lock';
import {
    LockFromJSON,
    LockFromJSONTyped,
    LockToJSON,
} from './Lock';

/**
 * 
 * @export
 * @interface GetLocksResponse
 */
export interface GetLocksResponse {
    /**
     * 
     * @type {Array<Lock>}
     * @memberof GetLocksResponse
     */
    locks?: Array<Lock>;
}

export function GetLocksResponseFromJSON(json: any): GetLocksResponse {
    return GetLocksResponseFromJSONTyped(json, false);
}

export function GetLocksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLocksResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locks': !exists(json, 'locks') ? undefined : (json['locks'] as Array<Lock>).map(LockFromJSON),
    };
}

export function GetLocksResponseToJSON(value?: GetLocksResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locks': value.locks === undefined ? undefined : (value.locks as Array<Lock>).map(LockToJSON),
    };
}

