/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A workflow series id plus selected metadata (e.g. the workflow name) for the frontend to use without incurring the overhead of looking up the whole workflow.
 * @export
 * @interface WorkflowSeriesInfo
 */
export interface WorkflowSeriesInfo {
    /**
     * 
     * @type {string}
     * @memberof WorkflowSeriesInfo
     */
    seriesId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowSeriesInfo
     */
    name?: string;
}

export function WorkflowSeriesInfoFromJSON(json: any): WorkflowSeriesInfo {
    return WorkflowSeriesInfoFromJSONTyped(json, false);
}

export function WorkflowSeriesInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowSeriesInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seriesId': !exists(json, 'seriesId') ? undefined : json['seriesId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function WorkflowSeriesInfoToJSON(value?: WorkflowSeriesInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seriesId': value.seriesId,
        'name': value.name,
    };
}

