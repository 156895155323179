/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopyWorkflowRequest
 */
export interface CopyWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof CopyWorkflowRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CopyWorkflowRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyWorkflowRequest
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CopyWorkflowRequest
     */
    numberingPrefix: string;
    /**
     * 
     * @type {boolean}
     * @memberof CopyWorkflowRequest
     */
    includeNumberingYearInitiated?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CopyWorkflowRequest
     */
    resetNumberingAfterYearEnd?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CopyWorkflowRequest
     */
    startNumberingAt?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CopyWorkflowRequest
     */
    requireValidation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CopyWorkflowRequest
     */
    validationJustification?: string;
}

export function CopyWorkflowRequestFromJSON(json: any): CopyWorkflowRequest {
    return CopyWorkflowRequestFromJSONTyped(json, false);
}

export function CopyWorkflowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyWorkflowRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': json['type'],
        'numberingPrefix': json['numberingPrefix'],
        'includeNumberingYearInitiated': !exists(json, 'includeNumberingYearInitiated') ? undefined : json['includeNumberingYearInitiated'],
        'resetNumberingAfterYearEnd': !exists(json, 'resetNumberingAfterYearEnd') ? undefined : json['resetNumberingAfterYearEnd'],
        'startNumberingAt': !exists(json, 'startNumberingAt') ? undefined : json['startNumberingAt'],
        'requireValidation': !exists(json, 'requireValidation') ? undefined : json['requireValidation'],
        'validationJustification': !exists(json, 'validationJustification') ? undefined : json['validationJustification'],
    };
}

export function CopyWorkflowRequestToJSON(value?: CopyWorkflowRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'type': value.type,
        'numberingPrefix': value.numberingPrefix,
        'includeNumberingYearInitiated': value.includeNumberingYearInitiated,
        'resetNumberingAfterYearEnd': value.resetNumberingAfterYearEnd,
        'startNumberingAt': value.startNumberingAt,
        'requireValidation': value.requireValidation,
        'validationJustification': value.validationJustification,
    };
}

