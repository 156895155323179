/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteNodesCommand
 */
export interface DeleteNodesCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteNodesCommand
     */
    nodeIds?: Array<string>;
}

export function DeleteNodesCommandFromJSON(json: any): DeleteNodesCommand {
    return DeleteNodesCommandFromJSONTyped(json, false);
}

export function DeleteNodesCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteNodesCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeIds': !exists(json, 'nodeIds') ? undefined : (json['nodeIds'] as Array<string>),
    };
}

export function DeleteNodesCommandToJSON(value?: DeleteNodesCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeIds': value.nodeIds === undefined ? undefined : (value.nodeIds as Array<string>),
    };
}

