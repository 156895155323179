/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnlinkCQxDocumentRequest
 */
export interface UnlinkCQxDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof UnlinkCQxDocumentRequest
     */
    linkId?: string;
    /**
     * 
     * @type {string}
     * @memberof UnlinkCQxDocumentRequest
     */
    taskId?: string;
}

export function UnlinkCQxDocumentRequestFromJSON(json: any): UnlinkCQxDocumentRequest {
    return UnlinkCQxDocumentRequestFromJSONTyped(json, false);
}

export function UnlinkCQxDocumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnlinkCQxDocumentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'linkId': !exists(json, 'linkId') ? undefined : json['linkId'],
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
    };
}

export function UnlinkCQxDocumentRequestToJSON(value?: UnlinkCQxDocumentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'linkId': value.linkId,
        'taskId': value.taskId,
    };
}

