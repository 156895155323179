/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DropdownAllOf } from './DropdownAllOf';
import {
    DropdownAllOfFromJSON,
    DropdownAllOfFromJSONTyped,
    DropdownAllOfToJSON,
} from './DropdownAllOf';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';

/**
 * 
 * @export
 * @interface Dropdown
 */
export interface Dropdown extends FormElement {
    /**
     * 
     * @type {string}
     * @memberof Dropdown
     */
    placeholder?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Dropdown
     */
    multiple?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dropdown
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Dropdown
     */
    hintText?: string;
    /**
     * Local Options for this form element.  This field is mutually exclusive with the 'optionsListId' field.
     * @type {Array<Option>}
     * @memberof Dropdown
     */
    options?: Array<Option>;
    /**
     * ID of a managed List which contains the options for this form element.  This field is mutually exclusive with the 'options' field.
     * @type {string}
     * @memberof Dropdown
     */
    optionsListId?: string;
    /**
     * ID of a managed List which was the source of local Options for this form element. Note that this is a read-only field.
     * @type {string}
     * @memberof Dropdown
     */
    originalOptionsListId?: string;
}

export function DropdownFromJSON(json: any): Dropdown {
    return DropdownFromJSONTyped(json, false);
}

export function DropdownFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dropdown {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'multiple': !exists(json, 'multiple') ? undefined : json['multiple'],
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
        'options': !exists(json, 'options') ? undefined : (json['options'] as Array<Option>).map(OptionFromJSON),
        'optionsListId': !exists(json, 'optionsListId') ? undefined : json['optionsListId'],
        'originalOptionsListId': !exists(json, 'originalOptionsListId') ? undefined : json['originalOptionsListId'],
    };
}

export function DropdownToJSON(value?: Dropdown | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'placeholder': value.placeholder,
        'multiple': value.multiple,
        'showHintText': value.showHintText,
        'hintText': value.hintText,
        'options': value.options === undefined ? undefined : (value.options as Array<Option>).map(OptionToJSON),
        'optionsListId': value.optionsListId,
        'originalOptionsListId': value.originalOptionsListId,
    };
}

