/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ElementData } from './ElementData';
import {
    ElementDataFromJSON,
    ElementDataFromJSONTyped,
    ElementDataToJSON,
} from './ElementData';
import type { ExecutionPlanElement } from './ExecutionPlanElement';
import {
    ExecutionPlanElementFromJSON,
    ExecutionPlanElementFromJSONTyped,
    ExecutionPlanElementToJSON,
} from './ExecutionPlanElement';
import type { ExecutionPlanSection } from './ExecutionPlanSection';
import {
    ExecutionPlanSectionFromJSON,
    ExecutionPlanSectionFromJSONTyped,
    ExecutionPlanSectionToJSON,
} from './ExecutionPlanSection';

/**
 * 
 * @export
 * @interface TaskElementDataResponse
 */
export interface TaskElementDataResponse {
    /**
     * 
     * @type {Array<ExecutionPlanSection>}
     * @memberof TaskElementDataResponse
     */
    sections?: Array<ExecutionPlanSection>;
    /**
     * 
     * @type {Array<ExecutionPlanElement>}
     * @memberof TaskElementDataResponse
     */
    elements?: Array<ExecutionPlanElement>;
    /**
     * 
     * @type {Array<ElementData>}
     * @memberof TaskElementDataResponse
     */
    elementDatas?: Array<ElementData>;
}

export function TaskElementDataResponseFromJSON(json: any): TaskElementDataResponse {
    return TaskElementDataResponseFromJSONTyped(json, false);
}

export function TaskElementDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskElementDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': !exists(json, 'sections') ? undefined : (json['sections'] as Array<ExecutionPlanSection>).map(ExecutionPlanSectionFromJSON),
        'elements': !exists(json, 'elements') ? undefined : (json['elements'] as Array<ExecutionPlanElement>).map(ExecutionPlanElementFromJSON),
        'elementDatas': !exists(json, 'elementDatas') ? undefined : (json['elementDatas'] as Array<ElementData>).map(ElementDataFromJSON),
    };
}

export function TaskElementDataResponseToJSON(value?: TaskElementDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': value.sections === undefined ? undefined : (value.sections as Array<ExecutionPlanSection>).map(ExecutionPlanSectionToJSON),
        'elements': value.elements === undefined ? undefined : (value.elements as Array<ExecutionPlanElement>).map(ExecutionPlanElementToJSON),
        'elementDatas': value.elementDatas === undefined ? undefined : (value.elementDatas as Array<ElementData>).map(ElementDataToJSON),
    };
}

