/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataType } from './DataType';
import {
    DataTypeFromJSON,
    DataTypeFromJSONTyped,
    DataTypeToJSON,
} from './DataType';
import type { RecordStatus } from './RecordStatus';
import {
    RecordStatusFromJSON,
    RecordStatusFromJSONTyped,
    RecordStatusToJSON,
} from './RecordStatus';
import type { RecordType } from './RecordType';
import {
    RecordTypeFromJSON,
    RecordTypeFromJSONTyped,
    RecordTypeToJSON,
} from './RecordType';
import type { WorkflowState } from './WorkflowState';
import {
    WorkflowStateFromJSON,
    WorkflowStateFromJSONTyped,
    WorkflowStateToJSON,
} from './WorkflowState';

/**
 * 
 * @export
 * @interface Record
 */
export interface Record {
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    parentRecordId?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    recordName?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    recordNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    executionPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    tenantId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Record
     */
    launchDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Record
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {RecordStatus}
     * @memberof Record
     */
    recordStatus?: RecordStatus;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    recordConfigurationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    childWorkflowId?: string;
    /**
     * 
     * @type {WorkflowState}
     * @memberof Record
     */
    workflowState?: WorkflowState;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    workflowRevision?: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Record
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    durationUnit?: RecordDurationUnitEnum;
    /**
     * 
     * @type {Date}
     * @memberof Record
     */
    dueDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Record
     */
    completedDate?: Date;
    /**
     * 
     * @type {RecordType}
     * @memberof Record
     */
    recordType?: RecordType;
    /**
     * 
     * @type {boolean}
     * @memberof Record
     */
    isDurationInBusinessDays?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    creatorUserId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Record
     */
    recordOwnerUsers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Record
     */
    recordOwnerRoles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Record
     */
    affectedSites?: Array<string>;
    /**
     * whether the current user has permission to access this record
     * @type {boolean}
     * @memberof Record
     */
    accessible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    seriesId?: string;
    /**
     * 
     * @type {number}
     * @memberof Record
     */
    revisionNumber?: number;
    /**
     * 
     * @type {DataType}
     * @memberof Record
     */
    dataType?: DataType;
}

/**
* @export
* @enum {string}
*/
export enum RecordDurationUnitEnum {
    Hours = 'HOURS',
    Days = 'DAYS',
    Weeks = 'WEEKS',
    Months = 'MONTHS'
}

export function RecordFromJSON(json: any): Record {
    return RecordFromJSONTyped(json, false);
}

export function RecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): Record {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parentRecordId': !exists(json, 'parentRecordId') ? undefined : json['parentRecordId'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'recordName': !exists(json, 'recordName') ? undefined : json['recordName'],
        'recordNumber': !exists(json, 'recordNumber') ? undefined : json['recordNumber'],
        'executionPlanId': !exists(json, 'executionPlanId') ? undefined : json['executionPlanId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'launchDate': !exists(json, 'launchDate') || json['launchDate'] === '' ? undefined : new Date(json['launchDate']),
        'modifiedDate': !exists(json, 'modifiedDate') || json['modifiedDate'] === '' ? undefined : new Date(json['modifiedDate']),
        'recordStatus': !exists(json, 'recordStatus') ? undefined : RecordStatusFromJSON(json['recordStatus']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'recordConfigurationId': !exists(json, 'recordConfigurationId') ? undefined : json['recordConfigurationId'],
        'childWorkflowId': !exists(json, 'childWorkflowId') ? undefined : json['childWorkflowId'],
        'workflowState': !exists(json, 'workflowState') ? undefined : WorkflowStateFromJSON(json['workflowState']),
        'workflowRevision': !exists(json, 'workflowRevision') ? undefined : json['workflowRevision'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationUnit': !exists(json, 'durationUnit') ? undefined : json['durationUnit'],
        'dueDate': !exists(json, 'dueDate') || json['dueDate'] === '' ? undefined : new Date(json['dueDate']),
        'completedDate': !exists(json, 'completedDate') || json['completedDate'] === '' ? undefined : new Date(json['completedDate']),
        'recordType': !exists(json, 'recordType') ? undefined : RecordTypeFromJSON(json['recordType']),
        'isDurationInBusinessDays': !exists(json, 'isDurationInBusinessDays') ? undefined : json['isDurationInBusinessDays'],
        'creatorUserId': !exists(json, 'creatorUserId') ? undefined : json['creatorUserId'],
        'recordOwnerUsers': !exists(json, 'recordOwnerUsers') ? undefined : (json['recordOwnerUsers'] as Array<string>),
        'recordOwnerRoles': !exists(json, 'recordOwnerRoles') ? undefined : (json['recordOwnerRoles'] as Array<string>),
        'affectedSites': !exists(json, 'affectedSites') ? undefined : (json['affectedSites'] as Array<string>),
        'accessible': !exists(json, 'accessible') ? undefined : json['accessible'],
        'seriesId': !exists(json, 'seriesId') ? undefined : json['seriesId'],
        'revisionNumber': !exists(json, 'revisionNumber') ? undefined : json['revisionNumber'],
        'dataType': !exists(json, 'dataType') ? undefined : DataTypeFromJSON(json['dataType']),
    };
}

export function RecordToJSON(value?: Record | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentRecordId': value.parentRecordId,
        'owner': value.owner,
        'recordName': value.recordName,
        'recordNumber': value.recordNumber,
        'executionPlanId': value.executionPlanId,
        'tenantId': value.tenantId,
        'launchDate': value.launchDate === undefined ? undefined : value.launchDate.toISOString(),
        'modifiedDate': value.modifiedDate === undefined ? undefined : value.modifiedDate.toISOString(),
        'recordStatus': RecordStatusToJSON(value.recordStatus),
        'title': value.title,
        'recordConfigurationId': value.recordConfigurationId,
        'childWorkflowId': value.childWorkflowId,
        'workflowState': WorkflowStateToJSON(value.workflowState),
        'workflowRevision': value.workflowRevision,
        'description': value.description,
        'duration': value.duration,
        'durationUnit': value.durationUnit,
        'dueDate': value.dueDate === undefined ? undefined : value.dueDate.toISOString(),
        'completedDate': value.completedDate === undefined ? undefined : value.completedDate.toISOString(),
        'recordType': RecordTypeToJSON(value.recordType),
        'isDurationInBusinessDays': value.isDurationInBusinessDays,
        'creatorUserId': value.creatorUserId,
        'recordOwnerUsers': value.recordOwnerUsers === undefined ? undefined : (value.recordOwnerUsers as Array<string>),
        'recordOwnerRoles': value.recordOwnerRoles === undefined ? undefined : (value.recordOwnerRoles as Array<string>),
        'affectedSites': value.affectedSites === undefined ? undefined : (value.affectedSites as Array<string>),
        'accessible': value.accessible,
        'seriesId': value.seriesId,
        'revisionNumber': value.revisionNumber,
        'dataType': DataTypeToJSON(value.dataType),
    };
}

