/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Workflow } from './Workflow';
import {
    WorkflowFromJSON,
    WorkflowFromJSONTyped,
    WorkflowToJSON,
} from './Workflow';

/**
 * 
 * @export
 * @interface UpdateWorkflowResponse
 */
export interface UpdateWorkflowResponse {
    /**
     * 
     * @type {Workflow}
     * @memberof UpdateWorkflowResponse
     */
    workflow: Workflow;
}

export function UpdateWorkflowResponseFromJSON(json: any): UpdateWorkflowResponse {
    return UpdateWorkflowResponseFromJSONTyped(json, false);
}

export function UpdateWorkflowResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkflowResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflow': WorkflowFromJSON(json['workflow']),
    };
}

export function UpdateWorkflowResponseToJSON(value?: UpdateWorkflowResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflow': WorkflowToJSON(value.workflow),
    };
}

