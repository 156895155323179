/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PcsListStatus } from './PcsListStatus';
import {
    PcsListStatusFromJSON,
    PcsListStatusFromJSONTyped,
    PcsListStatusToJSON,
} from './PcsListStatus';

/**
 * for updating a large list
 * @export
 * @interface UpdateLargePcsListCommandV2
 */
export interface UpdateLargePcsListCommandV2 {
    /**
     * 
     * @type {string}
     * @memberof UpdateLargePcsListCommandV2
     */
    name: string;
    /**
     * 
     * @type {PcsListStatus}
     * @memberof UpdateLargePcsListCommandV2
     */
    status: PcsListStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateLargePcsListCommandV2
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLargePcsListCommandV2
     */
    sourceKey?: string;
}

export function UpdateLargePcsListCommandV2FromJSON(json: any): UpdateLargePcsListCommandV2 {
    return UpdateLargePcsListCommandV2FromJSONTyped(json, false);
}

export function UpdateLargePcsListCommandV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLargePcsListCommandV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'status': PcsListStatusFromJSON(json['status']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sourceKey': !exists(json, 'sourceKey') ? undefined : json['sourceKey'],
    };
}

export function UpdateLargePcsListCommandV2ToJSON(value?: UpdateLargePcsListCommandV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': PcsListStatusToJSON(value.status),
        'description': value.description,
        'sourceKey': value.sourceKey,
    };
}

