/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ElementData
 */
export interface ElementData {
    /**
     * 
     * @type {string}
     * @memberof ElementData
     */
    elementId?: string;
    /**
     * 
     * @type {string}
     * @memberof ElementData
     */
    executionPlanElementId?: string;
    /**
     * This is a JSON node.
     * @type {object}
     * @memberof ElementData
     */
    data?: object;
    /**
     * 
     * @type {string}
     * @memberof ElementData
     */
    recordId?: string;
    /**
     * 
     * @type {string}
     * @memberof ElementData
     */
    sectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ElementData
     */
    nodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof ElementData
     */
    userId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ElementData
     */
    timestamp?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ElementData
     */
    isNA?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ElementData
     */
    tableRowId?: string;
    /**
     * 
     * @type {number}
     * @memberof ElementData
     */
    displayOrder?: number;
}

export function ElementDataFromJSON(json: any): ElementData {
    return ElementDataFromJSONTyped(json, false);
}

export function ElementDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElementData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elementId': !exists(json, 'elementId') ? undefined : json['elementId'],
        'executionPlanElementId': !exists(json, 'executionPlanElementId') ? undefined : json['executionPlanElementId'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'sectionId': !exists(json, 'sectionId') ? undefined : json['sectionId'],
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'timestamp': !exists(json, 'timestamp') || json['timestamp'] === '' ? undefined : new Date(json['timestamp']),
        'isNA': !exists(json, 'isNA') ? undefined : json['isNA'],
        'tableRowId': !exists(json, 'tableRowId') ? undefined : json['tableRowId'],
        'displayOrder': !exists(json, 'displayOrder') ? undefined : json['displayOrder'],
    };
}

export function ElementDataToJSON(value?: ElementData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elementId': value.elementId,
        'executionPlanElementId': value.executionPlanElementId,
        'data': value.data,
        'recordId': value.recordId,
        'sectionId': value.sectionId,
        'nodeId': value.nodeId,
        'userId': value.userId,
        'timestamp': value.timestamp === undefined ? undefined : value.timestamp.toISOString(),
        'isNA': value.isNA,
        'tableRowId': value.tableRowId,
        'displayOrder': value.displayOrder,
    };
}

