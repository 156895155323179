/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SettingKey {
    Language = 'LANGUAGE',
    UtcOffset = 'UTC_OFFSET',
    DateFormat = 'DATE_FORMAT',
    MilitaryTime = 'MILITARY_TIME'
}

export function SettingKeyFromJSON(json: any): SettingKey {
    return SettingKeyFromJSONTyped(json, false);
}

export function SettingKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingKey {
    return json as SettingKey;
}

export function SettingKeyToJSON(value?: SettingKey | null): any {
    return value as any;
}

