/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Rule } from './Rule';
import {
    RuleFromJSON,
    RuleFromJSONTyped,
    RuleToJSON,
} from './Rule';
import type { RuleActionValue } from './RuleActionValue';
import {
    RuleActionValueFromJSON,
    RuleActionValueFromJSONTyped,
    RuleActionValueToJSON,
} from './RuleActionValue';
import type { RuleType } from './RuleType';
import {
    RuleTypeFromJSON,
    RuleTypeFromJSONTyped,
    RuleTypeToJSON,
} from './RuleType';

/**
 * 
 * @export
 * @interface FormOptionSectionRule
 */
export interface FormOptionSectionRule extends Rule {
    /**
     * the section that is the target of this rule
     * @type {string}
     * @memberof FormOptionSectionRule
     */
    targetSectionId?: string;
    /**
     * the form element on which this rule is defined on
     * @type {string}
     * @memberof FormOptionSectionRule
     */
    sourceFormElementId?: string;
    /**
     * the option on which this rule is defined on
     * @type {string}
     * @memberof FormOptionSectionRule
     */
    sourceOptionId?: string;
    /**
     * 
     * @type {RuleActionValue}
     * @memberof FormOptionSectionRule
     */
    ruleActionValue?: RuleActionValue;
}

export function FormOptionSectionRuleFromJSON(json: any): FormOptionSectionRule {
    return FormOptionSectionRuleFromJSONTyped(json, false);
}

export function FormOptionSectionRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormOptionSectionRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...RuleFromJSONTyped(json, ignoreDiscriminator),
        'targetSectionId': !exists(json, 'targetSectionId') ? undefined : json['targetSectionId'],
        'sourceFormElementId': !exists(json, 'sourceFormElementId') ? undefined : json['sourceFormElementId'],
        'sourceOptionId': !exists(json, 'sourceOptionId') ? undefined : json['sourceOptionId'],
        'ruleActionValue': !exists(json, 'ruleActionValue') ? undefined : RuleActionValueFromJSON(json['ruleActionValue']),
    };
}

export function FormOptionSectionRuleToJSON(value?: FormOptionSectionRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...RuleToJSON(value),
        'targetSectionId': value.targetSectionId,
        'sourceFormElementId': value.sourceFormElementId,
        'sourceOptionId': value.sourceOptionId,
        'ruleActionValue': RuleActionValueToJSON(value.ruleActionValue),
    };
}

