/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';
import type { NumberAllOf } from './NumberAllOf';
import {
    NumberAllOfFromJSON,
    NumberAllOfFromJSONTyped,
    NumberAllOfToJSON,
} from './NumberAllOf';

/**
 * 
 * @export
 * @interface Number
 */
export interface Number extends FormElement {
    /**
     * 
     * @type {string}
     * @memberof Number
     */
    placeholder?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Number
     */
    allowNegative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Number
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Number
     */
    hintText?: string;
}

export function NumberFromJSON(json: any): Number {
    return NumberFromJSONTyped(json, false);
}

export function NumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Number {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'allowNegative': !exists(json, 'allowNegative') ? undefined : json['allowNegative'],
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
    };
}

export function NumberToJSON(value?: Number | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'placeholder': value.placeholder,
        'allowNegative': value.allowNegative,
        'showHintText': value.showHintText,
        'hintText': value.hintText,
    };
}

