/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecutionPlanWorkflow
 */
export interface ExecutionPlanWorkflow {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ExecutionPlanWorkflow
     */
    layout?: { [key: string]: Array<string>; };
}

export function ExecutionPlanWorkflowFromJSON(json: any): ExecutionPlanWorkflow {
    return ExecutionPlanWorkflowFromJSONTyped(json, false);
}

export function ExecutionPlanWorkflowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionPlanWorkflow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'layout': !exists(json, 'layout') ? undefined : json['layout'],
    };
}

export function ExecutionPlanWorkflowToJSON(value?: ExecutionPlanWorkflow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'layout': value.layout,
    };
}

