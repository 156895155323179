/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActorType } from './ActorType';
import {
    ActorTypeFromJSON,
    ActorTypeFromJSONTyped,
    ActorTypeToJSON,
} from './ActorType';

/**
 * An object that represents an acting entity, like a User, a Role, or a permission Rule that identifies a user or set of users.  The actorId is a user, role id, or rule id (based on the actorType).
 * @export
 * @interface Actor
 */
export interface Actor {
    /**
     * 
     * @type {string}
     * @memberof Actor
     */
    actorId?: string;
    /**
     * 
     * @type {ActorType}
     * @memberof Actor
     */
    actorType?: ActorType;
}

export function ActorFromJSON(json: any): Actor {
    return ActorFromJSONTyped(json, false);
}

export function ActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Actor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actorId': !exists(json, 'actorId') ? undefined : json['actorId'],
        'actorType': !exists(json, 'actorType') ? undefined : ActorTypeFromJSON(json['actorType']),
    };
}

export function ActorToJSON(value?: Actor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actorId': value.actorId,
        'actorType': ActorTypeToJSON(value.actorType),
    };
}

