/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FormElementType {
    Attachment = 'ATTACHMENT',
    DateTime = 'DATE_TIME',
    DateTimeCapture = 'DATE_TIME_CAPTURE',
    Dropdown = 'DROPDOWN',
    ExternalLink = 'EXTERNAL_LINK',
    Link = 'LINK',
    LongText = 'LONG_TEXT',
    MultipleSelect = 'MULTIPLE_SELECT',
    Number = 'NUMBER',
    RecordLink = 'RECORD_LINK',
    RiskMatrix = 'RISK_MATRIX',
    ShortText = 'SHORT_TEXT',
    SingleSelect = 'SINGLE_SELECT',
    Toggle = 'TOGGLE',
    Table = 'TABLE'
}

export function FormElementTypeFromJSON(json: any): FormElementType {
    return FormElementTypeFromJSONTyped(json, false);
}

export function FormElementTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormElementType {
    return json as FormElementType;
}

export function FormElementTypeToJSON(value?: FormElementType | null): any {
    return value as any;
}

