/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Workflow } from './Workflow';
import {
    WorkflowFromJSON,
    WorkflowFromJSONTyped,
    WorkflowToJSON,
} from './Workflow';

/**
 * 
 * @export
 * @interface CopyWorkflowResponse
 */
export interface CopyWorkflowResponse {
    /**
     * 
     * @type {Workflow}
     * @memberof CopyWorkflowResponse
     */
    workflow: Workflow;
}

export function CopyWorkflowResponseFromJSON(json: any): CopyWorkflowResponse {
    return CopyWorkflowResponseFromJSONTyped(json, false);
}

export function CopyWorkflowResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyWorkflowResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflow': WorkflowFromJSON(json['workflow']),
    };
}

export function CopyWorkflowResponseToJSON(value?: CopyWorkflowResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflow': WorkflowToJSON(value.workflow),
    };
}

