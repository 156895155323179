/* tslint:disable */
/* eslint-disable */
/**
 * Session Service OpenAPI Definition
 * Title
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthStart
 */
export interface AuthStart {
    /**
     * 
     * @type {string}
     * @memberof AuthStart
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof AuthStart
     */
    qxTenantId: string;
    /**
     * Duration of idle session timeout in seconds
     * @type {number}
     * @memberof AuthStart
     */
    ttl: number;
}

export function AuthStartFromJSON(json: any): AuthStart {
    return AuthStartFromJSONTyped(json, false);
}

export function AuthStartFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthStart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'qxTenantId': json['qxTenantId'],
        'ttl': json['ttl'],
    };
}

export function AuthStartToJSON(value?: AuthStart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'qxTenantId': value.qxTenantId,
        'ttl': value.ttl,
    };
}

