/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateWorkflowVodResponseBody
 */
export interface ValidateWorkflowVodResponseBody {
    /**
     * ID of the validation plan
     * @type {string}
     * @memberof ValidateWorkflowVodResponseBody
     */
    validationPlanId?: string;
}

export function ValidateWorkflowVodResponseBodyFromJSON(json: any): ValidateWorkflowVodResponseBody {
    return ValidateWorkflowVodResponseBodyFromJSONTyped(json, false);
}

export function ValidateWorkflowVodResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateWorkflowVodResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validationPlanId': !exists(json, 'validationPlanId') ? undefined : json['validationPlanId'],
    };
}

export function ValidateWorkflowVodResponseBodyToJSON(value?: ValidateWorkflowVodResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'validationPlanId': value.validationPlanId,
    };
}

