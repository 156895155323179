import { apiConfig } from "@mc/application-settings";
import { PcsApi, PcsReportApi, ReportApi, ValidationOnDemandConfiguration, ValidationOnDemandLogbooksApi } from ".";

export const validationOnDemandConfiguration = new ValidationOnDemandConfiguration(apiConfig);

export const validationOnDemandLogbooksApi = { v1: new ValidationOnDemandLogbooksApi(validationOnDemandConfiguration) };
export const validationOnDemandPcsReportApi = {
    v1: new PcsReportApi(validationOnDemandConfiguration),
    v2: new ReportApi(validationOnDemandConfiguration),
};

export const pcsApi = { v1: new PcsApi(validationOnDemandConfiguration) };
