/* tslint:disable */
/* eslint-disable */
/**
 * File Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetFileContentsResponse
 */
export interface GetFileContentsResponse {
    /**
     * A base64-encoded byte array
     * @type {string}
     * @memberof GetFileContentsResponse
     */
    fileContents?: string;
}

export function GetFileContentsResponseFromJSON(json: any): GetFileContentsResponse {
    return GetFileContentsResponseFromJSONTyped(json, false);
}

export function GetFileContentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFileContentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileContents': !exists(json, 'fileContents') ? undefined : json['fileContents'],
    };
}

export function GetFileContentsResponseToJSON(value?: GetFileContentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileContents': value.fileContents,
    };
}

