/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CheckItemsResponse,
    CheckItemsResponseFromJSON,
    CheckItemsResponseToJSON,
    CheckNameResponse,
    CheckNameResponseFromJSON,
    CheckNameResponseToJSON,
    CheckPcsListItemsCommand,
    CheckPcsListItemsCommandFromJSON,
    CheckPcsListItemsCommandToJSON,
    CreatePcsListCommand,
    CreatePcsListCommandFromJSON,
    CreatePcsListCommandToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MultiplePcsListResponse,
    MultiplePcsListResponseFromJSON,
    MultiplePcsListResponseToJSON,
    PcsList,
    PcsListFromJSON,
    PcsListToJSON,
    PcsListQueryResponse,
    PcsListQueryResponseFromJSON,
    PcsListQueryResponseToJSON,
    PcsListStatus,
    PcsListStatusFromJSON,
    PcsListStatusToJSON,
    PcsListType,
    PcsListTypeFromJSON,
    PcsListTypeToJSON,
    UpdatePcsListCommand,
    UpdatePcsListCommandFromJSON,
    UpdatePcsListCommandToJSON,
} from '../models';

export interface CheckPcsListRequest {
    id: string;
    checkPcsListItemsCommand: CheckPcsListItemsCommand;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface CheckUniqueNameRequest {
    name: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface CreateMcListRequest {
    createPcsListCommand: CreatePcsListCommand;
}

export interface CreatePcsListRequest {
    createPcsListCommand: CreatePcsListCommand;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface GetAllPcsListsRequest {
    pageNumber: number;
    pageSize: number;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
    pcsListName?: string;
    pcsListStatus?: PcsListStatus;
    pcsListType?: PcsListType;
}

export interface GetPcsListRequest {
    id: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface GetPcsListsByIdsRequest {
    ids: Array<string>;
    pcsTenantid?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface SearchPcsListsRequest {
    name?: string;
    pageNumber?: number;
    pageSize?: number;
    sortProperty?: SearchPcsListsSortPropertyEnum;
    sortDirection?: SearchPcsListsSortDirectionEnum;
}

export interface UpdatePcsListRequest {
    id: string;
    updatePcsListCommand: UpdatePcsListCommand;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

/**
 * 
 */
export class ListV1Api extends runtime.BaseAPI {

    /**
     * Checks if pcs-list contains items.
     */
    async checkPcsListRaw(requestParameters: CheckPcsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckItemsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling checkPcsList.');
        }

        if (requestParameters.checkPcsListItemsCommand === null || requestParameters.checkPcsListItemsCommand === undefined) {
            throw new runtime.RequiredError('checkPcsListItemsCommand','Required parameter requestParameters.checkPcsListItemsCommand was null or undefined when calling checkPcsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v1/{id}/action/check-items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckPcsListItemsCommandToJSON(requestParameters.checkPcsListItemsCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckItemsResponseFromJSON(jsonValue));
    }

    /**
     * Checks if pcs-list contains items.
     */
    async checkPcsList(requestParameters: CheckPcsListRequest, initOverrides?: RequestInit): Promise<CheckItemsResponse> {
        const response = await this.checkPcsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks if the pcs-list name is unique across tenant
     * Check if pcs-list name is unique
     */
    async checkUniqueNameRaw(requestParameters: CheckUniqueNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckNameResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling checkUniqueName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v1/action/check-name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckNameResponseFromJSON(jsonValue));
    }

    /**
     * Checks if the pcs-list name is unique across tenant
     * Check if pcs-list name is unique
     */
    async checkUniqueName(requestParameters: CheckUniqueNameRequest, initOverrides?: RequestInit): Promise<CheckNameResponse> {
        const response = await this.checkUniqueNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a MC Core List
     */
    async createMcListRaw(requestParameters: CreateMcListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsList>> {
        if (requestParameters.createPcsListCommand === null || requestParameters.createPcsListCommand === undefined) {
            throw new runtime.RequiredError('createPcsListCommand','Required parameter requestParameters.createPcsListCommand was null or undefined when calling createMcList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/mc-list/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePcsListCommandToJSON(requestParameters.createPcsListCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListFromJSON(jsonValue));
    }

    /**
     * Create a MC Core List
     */
    async createMcList(requestParameters: CreateMcListRequest, initOverrides?: RequestInit): Promise<PcsList> {
        const response = await this.createMcListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a pcs-list
     */
    async createPcsListRaw(requestParameters: CreatePcsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsList>> {
        if (requestParameters.createPcsListCommand === null || requestParameters.createPcsListCommand === undefined) {
            throw new runtime.RequiredError('createPcsListCommand','Required parameter requestParameters.createPcsListCommand was null or undefined when calling createPcsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePcsListCommandToJSON(requestParameters.createPcsListCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListFromJSON(jsonValue));
    }

    /**
     * Create a pcs-list
     */
    async createPcsList(requestParameters: CreatePcsListRequest, initOverrides?: RequestInit): Promise<PcsList> {
        const response = await this.createPcsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all pcs-list details for an authenticated user or service by tenancy. Required license Full or Basic
     * Get all pcs-list details for an authenticated user or service by tenancy
     */
    async getAllPcsListsRaw(requestParameters: GetAllPcsListsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListQueryResponse>> {
        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getAllPcsLists.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getAllPcsLists.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pcsListName !== undefined) {
            queryParameters['pcsListName'] = requestParameters.pcsListName;
        }

        if (requestParameters.pcsListStatus !== undefined) {
            queryParameters['pcsListStatus'] = requestParameters.pcsListStatus;
        }

        if (requestParameters.pcsListType !== undefined) {
            queryParameters['pcsListType'] = requestParameters.pcsListType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListQueryResponseFromJSON(jsonValue));
    }

    /**
     * Get all pcs-list details for an authenticated user or service by tenancy. Required license Full or Basic
     * Get all pcs-list details for an authenticated user or service by tenancy
     */
    async getAllPcsLists(requestParameters: GetAllPcsListsRequest, initOverrides?: RequestInit): Promise<PcsListQueryResponse> {
        const response = await this.getAllPcsListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list for the specified pcs-list id
     */
    async getPcsListRaw(requestParameters: GetPcsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPcsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v1/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListFromJSON(jsonValue));
    }

    /**
     * Get a list for the specified pcs-list id
     */
    async getPcsList(requestParameters: GetPcsListRequest, initOverrides?: RequestInit): Promise<PcsList> {
        const response = await this.getPcsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get pcs-list details for the specified pcs-list ids.
     */
    async getPcsListsByIdsRaw(requestParameters: GetPcsListsByIdsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MultiplePcsListResponse>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getPcsListsByIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v1/lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultiplePcsListResponseFromJSON(jsonValue));
    }

    /**
     * Get pcs-list details for the specified pcs-list ids.
     */
    async getPcsListsByIds(requestParameters: GetPcsListsByIdsRequest, initOverrides?: RequestInit): Promise<MultiplePcsListResponse> {
        const response = await this.getPcsListsByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search pcs-lists for the specified filters.
     */
    async searchPcsListsRaw(requestParameters: SearchPcsListsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortProperty !== undefined) {
            queryParameters['sortProperty'] = requestParameters.sortProperty;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v1/action/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListQueryResponseFromJSON(jsonValue));
    }

    /**
     * Search pcs-lists for the specified filters.
     */
    async searchPcsLists(requestParameters: SearchPcsListsRequest = {}, initOverrides?: RequestInit): Promise<PcsListQueryResponse> {
        const response = await this.searchPcsListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a PcsList
     */
    async updatePcsListRaw(requestParameters: UpdatePcsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePcsList.');
        }

        if (requestParameters.updatePcsListCommand === null || requestParameters.updatePcsListCommand === undefined) {
            throw new runtime.RequiredError('updatePcsListCommand','Required parameter requestParameters.updatePcsListCommand was null or undefined when calling updatePcsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v1/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePcsListCommandToJSON(requestParameters.updatePcsListCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListFromJSON(jsonValue));
    }

    /**
     * Update a PcsList
     */
    async updatePcsList(requestParameters: UpdatePcsListRequest, initOverrides?: RequestInit): Promise<PcsList> {
        const response = await this.updatePcsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SearchPcsListsSortPropertyEnum {
    Name = 'name',
    Status = 'status',
    Type = 'type',
    CreatedTimestamp = 'created_timestamp',
    LastModifiedTimestamp = 'last_modified_timestamp'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchPcsListsSortDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}
