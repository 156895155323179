/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Approval } from './Approval';
import {
    ApprovalFromJSON,
    ApprovalFromJSONTyped,
    ApprovalToJSON,
} from './Approval';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';
import type { RuleConfiguration } from './RuleConfiguration';
import {
    RuleConfigurationFromJSON,
    RuleConfigurationFromJSONTyped,
    RuleConfigurationToJSON,
} from './RuleConfiguration';
import type { Section } from './Section';
import {
    SectionFromJSON,
    SectionFromJSONTyped,
    SectionToJSON,
} from './Section';
import type { ValidationPlan } from './ValidationPlan';
import {
    ValidationPlanFromJSON,
    ValidationPlanFromJSONTyped,
    ValidationPlanToJSON,
} from './ValidationPlan';
import type { ValidationReport } from './ValidationReport';
import {
    ValidationReportFromJSON,
    ValidationReportFromJSONTyped,
    ValidationReportToJSON,
} from './ValidationReport';
import type { Workflow } from './Workflow';
import {
    WorkflowFromJSON,
    WorkflowFromJSONTyped,
    WorkflowToJSON,
} from './Workflow';

/**
 * 
 * @export
 * @interface WorkflowConfigurationPackage
 */
export interface WorkflowConfigurationPackage {
    /**
     * 
     * @type {Workflow}
     * @memberof WorkflowConfigurationPackage
     */
    workflow?: Workflow;
    /**
     * 
     * @type {Array<Node>}
     * @memberof WorkflowConfigurationPackage
     */
    nodes?: Array<Node>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof WorkflowConfigurationPackage
     */
    nodeLayout?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<Section>}
     * @memberof WorkflowConfigurationPackage
     */
    sections?: Array<Section>;
    /**
     * 
     * @type {Array<FormElement>}
     * @memberof WorkflowConfigurationPackage
     */
    formElements?: Array<FormElement>;
    /**
     * 
     * @type {Array<RuleConfiguration>}
     * @memberof WorkflowConfigurationPackage
     */
    rules?: Array<RuleConfiguration>;
    /**
     * 
     * @type {Array<Approval>}
     * @memberof WorkflowConfigurationPackage
     */
    approvals?: Array<Approval>;
    /**
     * 
     * @type {Array<ValidationPlan>}
     * @memberof WorkflowConfigurationPackage
     */
    validationPlans?: Array<ValidationPlan>;
    /**
     * 
     * @type {Array<ValidationReport>}
     * @memberof WorkflowConfigurationPackage
     */
    validationReports?: Array<ValidationReport>;
}

export function WorkflowConfigurationPackageFromJSON(json: any): WorkflowConfigurationPackage {
    return WorkflowConfigurationPackageFromJSONTyped(json, false);
}

export function WorkflowConfigurationPackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowConfigurationPackage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflow': !exists(json, 'workflow') ? undefined : WorkflowFromJSON(json['workflow']),
        'nodes': !exists(json, 'nodes') ? undefined : (json['nodes'] as Array<Node>).map(NodeFromJSON),
        'nodeLayout': !exists(json, 'nodeLayout') ? undefined : json['nodeLayout'],
        'sections': !exists(json, 'sections') ? undefined : (json['sections'] as Array<Section>).map(SectionFromJSON),
        'formElements': !exists(json, 'formElements') ? undefined : (json['formElements'] as Array<FormElement>).map(FormElementFromJSON),
        'rules': !exists(json, 'rules') ? undefined : (json['rules'] as Array<RuleConfiguration>).map(RuleConfigurationFromJSON),
        'approvals': !exists(json, 'approvals') ? undefined : (json['approvals'] as Array<Approval>).map(ApprovalFromJSON),
        'validationPlans': !exists(json, 'validationPlans') ? undefined : (json['validationPlans'] as Array<ValidationPlan>).map(ValidationPlanFromJSON),
        'validationReports': !exists(json, 'validationReports') ? undefined : (json['validationReports'] as Array<ValidationReport>).map(ValidationReportFromJSON),
    };
}

export function WorkflowConfigurationPackageToJSON(value?: WorkflowConfigurationPackage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflow': WorkflowToJSON(value.workflow),
        'nodes': value.nodes === undefined ? undefined : (value.nodes as Array<Node>).map(NodeToJSON),
        'nodeLayout': value.nodeLayout,
        'sections': value.sections === undefined ? undefined : (value.sections as Array<Section>).map(SectionToJSON),
        'formElements': value.formElements === undefined ? undefined : (value.formElements as Array<FormElement>).map(FormElementToJSON),
        'rules': value.rules === undefined ? undefined : (value.rules as Array<RuleConfiguration>).map(RuleConfigurationToJSON),
        'approvals': value.approvals === undefined ? undefined : (value.approvals as Array<Approval>).map(ApprovalToJSON),
        'validationPlans': value.validationPlans === undefined ? undefined : (value.validationPlans as Array<ValidationPlan>).map(ValidationPlanToJSON),
        'validationReports': value.validationReports === undefined ? undefined : (value.validationReports as Array<ValidationReport>).map(ValidationReportToJSON),
    };
}

