/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RuleType {
    FormOptionSection = 'FORM_OPTION_SECTION',
    FormOptionNode = 'FORM_OPTION_NODE',
    FormOptionFormElement = 'FORM_OPTION_FORM_ELEMENT',
    EventOwnerPermission = 'EVENT_OWNER_PERMISSION',
    EventInitiatorPermission = 'EVENT_INITIATOR_PERMISSION',
    NodeParticipantPermission = 'NODE_PARTICIPANT_PERMISSION'
}

export function RuleTypeFromJSON(json: any): RuleType {
    return RuleTypeFromJSONTyped(json, false);
}

export function RuleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleType {
    return json as RuleType;
}

export function RuleTypeToJSON(value?: RuleType | null): any {
    return value as any;
}

