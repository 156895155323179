/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Signature
 */
export interface Signature {
    /**
     * 
     * @type {string}
     * @memberof Signature
     */
    id?: string;
    /**
     * The person who approved.
     * @type {string}
     * @memberof Signature
     */
    userId?: string;
    /**
     * The Role that the approver assumed when they approved.
     * @type {string}
     * @memberof Signature
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof Signature
     */
    recordId?: string;
    /**
     * Node that is being approved. This can be null for Record-level approvals.
     * @type {string}
     * @memberof Signature
     */
    nodeId?: string;
    /**
     * The "approval" type Task that resulted in this approval creation.
     * @type {string}
     * @memberof Signature
     */
    taskId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Signature
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Signature
     */
    notes?: string;
}

export function SignatureFromJSON(json: any): Signature {
    return SignatureFromJSONTyped(json, false);
}

export function SignatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Signature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
        'createdAt': !exists(json, 'created_at') || json['created_at'] === '' ? undefined : new Date(json['created_at']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function SignatureToJSON(value?: Signature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'roleId': value.roleId,
        'recordId': value.recordId,
        'nodeId': value.nodeId,
        'taskId': value.taskId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'notes': value.notes,
    };
}

