/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StatusLabelEnum } from './StatusLabelEnum';
import {
    StatusLabelEnumFromJSON,
    StatusLabelEnumFromJSONTyped,
    StatusLabelEnumToJSON,
} from './StatusLabelEnum';

/**
 * 
 * @export
 * @interface NodeStatusLabels
 */
export interface NodeStatusLabels {
    /**
     * 
     * @type {{ [key: string]: StatusLabelEnum; }}
     * @memberof NodeStatusLabels
     */
    mapByNodeId?: { [key: string]: StatusLabelEnum; };
}

export function NodeStatusLabelsFromJSON(json: any): NodeStatusLabels {
    return NodeStatusLabelsFromJSONTyped(json, false);
}

export function NodeStatusLabelsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeStatusLabels {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mapByNodeId': !exists(json, 'mapByNodeId') ? undefined : mapValues(json['mapByNodeId'], StatusLabelEnumFromJSON),
    };
}

export function NodeStatusLabelsToJSON(value?: NodeStatusLabels | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mapByNodeId': value.mapByNodeId === undefined ? undefined : mapValues(value.mapByNodeId, StatusLabelEnumToJSON),
    };
}

