/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NodeItemPriorityEnum {
    Unknown = 'UNKNOWN',
    Low = 'LOW',
    Medium = 'MEDIUM',
    High = 'HIGH'
}

export function NodeItemPriorityEnumFromJSON(json: any): NodeItemPriorityEnum {
    return NodeItemPriorityEnumFromJSONTyped(json, false);
}

export function NodeItemPriorityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeItemPriorityEnum {
    return json as NodeItemPriorityEnum;
}

export function NodeItemPriorityEnumToJSON(value?: NodeItemPriorityEnum | null): any {
    return value as any;
}

