/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Edge } from './Edge';
import {
    EdgeFromJSON,
    EdgeFromJSONTyped,
    EdgeToJSON,
} from './Edge';
import type { SideEffects } from './SideEffects';
import {
    SideEffectsFromJSON,
    SideEffectsFromJSONTyped,
    SideEffectsToJSON,
} from './SideEffects';

/**
 * 
 * @export
 * @interface MoveNodeResponse
 */
export interface MoveNodeResponse {
    /**
     * 
     * @type {Array<Edge>}
     * @memberof MoveNodeResponse
     */
    addedEdges?: Array<Edge>;
    /**
     * 
     * @type {Array<Edge>}
     * @memberof MoveNodeResponse
     */
    removedEdges?: Array<Edge>;
    /**
     * 
     * @type {SideEffects}
     * @memberof MoveNodeResponse
     */
    sideEffects?: SideEffects;
}

export function MoveNodeResponseFromJSON(json: any): MoveNodeResponse {
    return MoveNodeResponseFromJSONTyped(json, false);
}

export function MoveNodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoveNodeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addedEdges': !exists(json, 'addedEdges') ? undefined : (json['addedEdges'] as Array<Edge>).map(EdgeFromJSON),
        'removedEdges': !exists(json, 'removedEdges') ? undefined : (json['removedEdges'] as Array<Edge>).map(EdgeFromJSON),
        'sideEffects': !exists(json, 'sideEffects') ? undefined : SideEffectsFromJSON(json['sideEffects']),
    };
}

export function MoveNodeResponseToJSON(value?: MoveNodeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addedEdges': value.addedEdges === undefined ? undefined : (value.addedEdges as Array<Edge>).map(EdgeToJSON),
        'removedEdges': value.removedEdges === undefined ? undefined : (value.removedEdges as Array<Edge>).map(EdgeToJSON),
        'sideEffects': SideEffectsToJSON(value.sideEffects),
    };
}

