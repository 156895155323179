/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateRecordCommandResponse,
    CreateRecordCommandResponseFromJSON,
    CreateRecordCommandResponseToJSON,
    CreateRecordRequestV2,
    CreateRecordRequestV2FromJSON,
    CreateRecordRequestV2ToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface CreateRecordV2Request {
    createRecordRequestV2: CreateRecordRequestV2;
    pcsTenantid?: string;
    pcsUserid?: string;
}

/**
 * 
 */
export class RecordV2Api extends runtime.BaseAPI {

    /**
     * Create a record with sites from a workflow - license required is Full or Basic.  Supports both user-auth and s2s-auth. 
     * Create a record from a workflow
     */
    async createRecordV2Raw(requestParameters: CreateRecordV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateRecordCommandResponse>> {
        if (requestParameters.createRecordRequestV2 === null || requestParameters.createRecordRequestV2 === undefined) {
            throw new runtime.RequiredError('createRecordRequestV2','Required parameter requestParameters.createRecordRequestV2 was null or undefined when calling createRecordV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/action/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRecordRequestV2ToJSON(requestParameters.createRecordRequestV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateRecordCommandResponseFromJSON(jsonValue));
    }

    /**
     * Create a record with sites from a workflow - license required is Full or Basic.  Supports both user-auth and s2s-auth. 
     * Create a record from a workflow
     */
    async createRecordV2(requestParameters: CreateRecordV2Request, initOverrides?: RequestInit): Promise<CreateRecordCommandResponse> {
        const response = await this.createRecordV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
