/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActorType } from './ActorType';
import {
    ActorTypeFromJSON,
    ActorTypeFromJSONTyped,
    ActorTypeToJSON,
} from './ActorType';
import type { ParticipantType } from './ParticipantType';
import {
    ParticipantTypeFromJSON,
    ParticipantTypeFromJSONTyped,
    ParticipantTypeToJSON,
} from './ParticipantType';
import type { TargetType } from './TargetType';
import {
    TargetTypeFromJSON,
    TargetTypeFromJSONTyped,
    TargetTypeToJSON,
} from './TargetType';

/**
 * Add a participant to a node or record. The actor id is a user or role id. Note that adding a RULE-type participant is currently prohibited.
 * @export
 * @interface CreateParticipantRequest
 */
export interface CreateParticipantRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    targetId?: string;
    /**
     * 
     * @type {TargetType}
     * @memberof CreateParticipantRequest
     */
    targetType?: TargetType;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    actorId?: string;
    /**
     * 
     * @type {ActorType}
     * @memberof CreateParticipantRequest
     */
    actorType?: ActorType;
    /**
     * 
     * @type {ParticipantType}
     * @memberof CreateParticipantRequest
     */
    participantType?: ParticipantType;
    /**
     * 
     * @type {string}
     * @memberof CreateParticipantRequest
     */
    message?: string;
}

export function CreateParticipantRequestFromJSON(json: any): CreateParticipantRequest {
    return CreateParticipantRequestFromJSONTyped(json, false);
}

export function CreateParticipantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateParticipantRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetId': !exists(json, 'targetId') ? undefined : json['targetId'],
        'targetType': !exists(json, 'targetType') ? undefined : TargetTypeFromJSON(json['targetType']),
        'actorId': !exists(json, 'actorId') ? undefined : json['actorId'],
        'actorType': !exists(json, 'actorType') ? undefined : ActorTypeFromJSON(json['actorType']),
        'participantType': !exists(json, 'participantType') ? undefined : ParticipantTypeFromJSON(json['participantType']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function CreateParticipantRequestToJSON(value?: CreateParticipantRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetId': value.targetId,
        'targetType': TargetTypeToJSON(value.targetType),
        'actorId': value.actorId,
        'actorType': ActorTypeToJSON(value.actorType),
        'participantType': ParticipantTypeToJSON(value.participantType),
        'message': value.message,
    };
}

