/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OperandType } from './OperandType';
import {
    OperandTypeFromJSON,
    OperandTypeFromJSONTyped,
    OperandTypeToJSON,
} from './OperandType';
import type { TargetType } from './TargetType';
import {
    TargetTypeFromJSON,
    TargetTypeFromJSONTyped,
    TargetTypeToJSON,
} from './TargetType';
import type { ValueType } from './ValueType';
import {
    ValueTypeFromJSON,
    ValueTypeFromJSONTyped,
    ValueTypeToJSON,
} from './ValueType';

/**
 * Rule Operand
 * @export
 * @interface RuleOperand
 */
export interface RuleOperand {
    /**
     * 
     * @type {string}
     * @memberof RuleOperand
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleOperand
     */
    targetId?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleOperand
     */
    targetValue?: string;
    /**
     * 
     * @type {OperandType}
     * @memberof RuleOperand
     */
    operandType?: OperandType;
    /**
     * 
     * @type {TargetType}
     * @memberof RuleOperand
     */
    targetType?: TargetType;
    /**
     * 
     * @type {ValueType}
     * @memberof RuleOperand
     */
    valueType?: ValueType;
    /**
     * 
     * @type {string}
     * @memberof RuleOperand
     */
    jsonPath?: string;
}

export function RuleOperandFromJSON(json: any): RuleOperand {
    return RuleOperandFromJSONTyped(json, false);
}

export function RuleOperandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleOperand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'targetId': !exists(json, 'targetId') ? undefined : json['targetId'],
        'targetValue': !exists(json, 'targetValue') ? undefined : json['targetValue'],
        'operandType': !exists(json, 'operandType') ? undefined : OperandTypeFromJSON(json['operandType']),
        'targetType': !exists(json, 'targetType') ? undefined : TargetTypeFromJSON(json['targetType']),
        'valueType': !exists(json, 'valueType') ? undefined : ValueTypeFromJSON(json['valueType']),
        'jsonPath': !exists(json, 'jsonPath') ? undefined : json['jsonPath'],
    };
}

export function RuleOperandToJSON(value?: RuleOperand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'targetId': value.targetId,
        'targetValue': value.targetValue,
        'operandType': OperandTypeToJSON(value.operandType),
        'targetType': TargetTypeToJSON(value.targetType),
        'valueType': ValueTypeToJSON(value.valueType),
        'jsonPath': value.jsonPath,
    };
}

