/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DueDateExtensionStatusEnum {
    Created = 'CREATED',
    Cancelled = 'CANCELLED',
    Approved = 'APPROVED',
    Denied = 'DENIED'
}

export function DueDateExtensionStatusEnumFromJSON(json: any): DueDateExtensionStatusEnum {
    return DueDateExtensionStatusEnumFromJSONTyped(json, false);
}

export function DueDateExtensionStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DueDateExtensionStatusEnum {
    return json as DueDateExtensionStatusEnum;
}

export function DueDateExtensionStatusEnumToJSON(value?: DueDateExtensionStatusEnum | null): any {
    return value as any;
}

