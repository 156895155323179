/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';

import {
     AttachmentFromJSONTyped,
     DateTimeFromJSONTyped,
     DateTimeCaptureFromJSONTyped,
     DropdownFromJSONTyped,
     ExternalLinkFromJSONTyped,
     LinkFromJSONTyped,
     LongTextFromJSONTyped,
     MultipleSelectFromJSONTyped,
     NumberFromJSONTyped,
     RecordLinkFromJSONTyped,
     RiskMatrixFromJSONTyped,
     ShortTextFromJSONTyped,
     SingleSelectFromJSONTyped,
     TableFromJSONTyped,
     ToggleFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface FormElement
 */
export interface FormElement {
    /**
     * ID of the Form Element
     * @type {string}
     * @memberof FormElement
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FormElement
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FormElement
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FormElement
     */
    sectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormElement
     */
    parentId?: string;
    /**
     * 
     * @type {FormElementType}
     * @memberof FormElement
     */
    type: FormElementType;
    /**
     * 
     * @type {number}
     * @memberof FormElement
     */
    displayOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FormElement
     */
    required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormElement
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormElement
     */
    visible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormElement
     */
    allowNotApplicable?: boolean;
}

export function FormElementFromJSON(json: any): FormElement {
    return FormElementFromJSONTyped(json, false);
}

export function FormElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'ATTACHMENT') {
            return AttachmentFromJSONTyped(json, true);
        }
        if (json['type'] === 'DATE_TIME') {
            return DateTimeFromJSONTyped(json, true);
        }
        if (json['type'] === 'DATE_TIME_CAPTURE') {
            return DateTimeCaptureFromJSONTyped(json, true);
        }
        if (json['type'] === 'DROPDOWN') {
            return DropdownFromJSONTyped(json, true);
        }
        if (json['type'] === 'EXTERNAL_LINK') {
            return ExternalLinkFromJSONTyped(json, true);
        }
        if (json['type'] === 'LINK') {
            return LinkFromJSONTyped(json, true);
        }
        if (json['type'] === 'LONG_TEXT') {
            return LongTextFromJSONTyped(json, true);
        }
        if (json['type'] === 'MULTIPLE_SELECT') {
            return MultipleSelectFromJSONTyped(json, true);
        }
        if (json['type'] === 'NUMBER') {
            return NumberFromJSONTyped(json, true);
        }
        if (json['type'] === 'RECORD_LINK') {
            return RecordLinkFromJSONTyped(json, true);
        }
        if (json['type'] === 'RISK_MATRIX') {
            return RiskMatrixFromJSONTyped(json, true);
        }
        if (json['type'] === 'SHORT_TEXT') {
            return ShortTextFromJSONTyped(json, true);
        }
        if (json['type'] === 'SINGLE_SELECT') {
            return SingleSelectFromJSONTyped(json, true);
        }
        if (json['type'] === 'TABLE') {
            return TableFromJSONTyped(json, true);
        }
        if (json['type'] === 'TOGGLE') {
            return ToggleFromJSONTyped(json, true);
        }
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sectionId': !exists(json, 'sectionId') ? undefined : json['sectionId'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'type': FormElementTypeFromJSON(json['type']),
        'displayOrder': !exists(json, 'displayOrder') ? undefined : json['displayOrder'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'visible': !exists(json, 'visible') ? undefined : json['visible'],
        'allowNotApplicable': !exists(json, 'allowNotApplicable') ? undefined : json['allowNotApplicable'],
    };
}

export function FormElementToJSON(value?: FormElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'sectionId': value.sectionId,
        'parentId': value.parentId,
        'type': FormElementTypeToJSON(value.type),
        'displayOrder': value.displayOrder,
        'required': value.required,
        'enabled': value.enabled,
        'visible': value.visible,
        'allowNotApplicable': value.allowNotApplicable,
    };
}

