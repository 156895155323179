/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MovePathRequest
 */
export interface MovePathRequest {
    /**
     * Node Id is the first node after the split
     * @type {string}
     * @memberof MovePathRequest
     */
    nodeId?: string;
    /**
     * index(0-based) position to move the path to
     * @type {number}
     * @memberof MovePathRequest
     */
    toIndex?: number;
}

export function MovePathRequestFromJSON(json: any): MovePathRequest {
    return MovePathRequestFromJSONTyped(json, false);
}

export function MovePathRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovePathRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'toIndex': !exists(json, 'toIndex') ? undefined : json['toIndex'],
    };
}

export function MovePathRequestToJSON(value?: MovePathRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeId': value.nodeId,
        'toIndex': value.toIndex,
    };
}

