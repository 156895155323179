/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ValueType {
    String = 'STRING',
    Number = 'NUMBER',
    Option = 'OPTION',
    MetadataList = 'METADATA_LIST'
}

export function ValueTypeFromJSON(json: any): ValueType {
    return ValueTypeFromJSONTyped(json, false);
}

export function ValueTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValueType {
    return json as ValueType;
}

export function ValueTypeToJSON(value?: ValueType | null): any {
    return value as any;
}

