/* tslint:disable */
/* eslint-disable */
/**
 * Task Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateTaskRequest,
    CreateTaskRequestFromJSON,
    CreateTaskRequestToJSON,
    CreateTaskResponse,
    CreateTaskResponseFromJSON,
    CreateTaskResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetTasksByAggregateIdResponse,
    GetTasksByAggregateIdResponseFromJSON,
    GetTasksByAggregateIdResponseToJSON,
    GetUserTasksResponse,
    GetUserTasksResponseFromJSON,
    GetUserTasksResponseToJSON,
} from '../models';

export interface CancelTaskRequest {
    pcsTenantid: string;
    pcsAppid: string;
    aggregateId: string;
}

export interface CompleteTaskRequest {
    pcsTenantid: string;
    pcsAppid: string;
    taskId: string;
}

export interface CreateTasksRequest {
    pcsTenantid: string;
    pcsAppid: string;
    createTaskRequest: CreateTaskRequest;
}

export interface GetTasksByAggregateIdRequest {
    pcsTenantid: string;
    pcsAppid: string;
    aggregateId: string;
}

export interface GetUserTasksRequest {
    pageNumber?: number;
    pageSize?: number;
}

/**
 * 
 */
export class TaskApi extends runtime.BaseAPI {

    /**
     * Cancels a task.
     */
    async cancelTaskRaw(requestParameters: CancelTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pcsTenantid === null || requestParameters.pcsTenantid === undefined) {
            throw new runtime.RequiredError('pcsTenantid','Required parameter requestParameters.pcsTenantid was null or undefined when calling cancelTask.');
        }

        if (requestParameters.pcsAppid === null || requestParameters.pcsAppid === undefined) {
            throw new runtime.RequiredError('pcsAppid','Required parameter requestParameters.pcsAppid was null or undefined when calling cancelTask.');
        }

        if (requestParameters.aggregateId === null || requestParameters.aggregateId === undefined) {
            throw new runtime.RequiredError('aggregateId','Required parameter requestParameters.aggregateId was null or undefined when calling cancelTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/task/task/v1/{aggregateId}`.replace(`{${"aggregateId"}}`, encodeURIComponent(String(requestParameters.aggregateId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancels a task.
     */
    async cancelTask(requestParameters: CancelTaskRequest, initOverrides?: RequestInit): Promise<void> {
        await this.cancelTaskRaw(requestParameters, initOverrides);
    }

    /**
     * Completes a task.
     */
    async completeTaskRaw(requestParameters: CompleteTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pcsTenantid === null || requestParameters.pcsTenantid === undefined) {
            throw new runtime.RequiredError('pcsTenantid','Required parameter requestParameters.pcsTenantid was null or undefined when calling completeTask.');
        }

        if (requestParameters.pcsAppid === null || requestParameters.pcsAppid === undefined) {
            throw new runtime.RequiredError('pcsAppid','Required parameter requestParameters.pcsAppid was null or undefined when calling completeTask.');
        }

        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling completeTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/task/task/v1/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Completes a task.
     */
    async completeTask(requestParameters: CompleteTaskRequest, initOverrides?: RequestInit): Promise<void> {
        await this.completeTaskRaw(requestParameters, initOverrides);
    }

    /**
     * Creates a task for one or more users
     */
    async createTasksRaw(requestParameters: CreateTasksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateTaskResponse>> {
        if (requestParameters.pcsTenantid === null || requestParameters.pcsTenantid === undefined) {
            throw new runtime.RequiredError('pcsTenantid','Required parameter requestParameters.pcsTenantid was null or undefined when calling createTasks.');
        }

        if (requestParameters.pcsAppid === null || requestParameters.pcsAppid === undefined) {
            throw new runtime.RequiredError('pcsAppid','Required parameter requestParameters.pcsAppid was null or undefined when calling createTasks.');
        }

        if (requestParameters.createTaskRequest === null || requestParameters.createTaskRequest === undefined) {
            throw new runtime.RequiredError('createTaskRequest','Required parameter requestParameters.createTaskRequest was null or undefined when calling createTasks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/task/task/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaskRequestToJSON(requestParameters.createTaskRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTaskResponseFromJSON(jsonValue));
    }

    /**
     * Creates a task for one or more users
     */
    async createTasks(requestParameters: CreateTasksRequest, initOverrides?: RequestInit): Promise<CreateTaskResponse> {
        const response = await this.createTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancels a task.
     */
    async getTasksByAggregateIdRaw(requestParameters: GetTasksByAggregateIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTasksByAggregateIdResponse>> {
        if (requestParameters.pcsTenantid === null || requestParameters.pcsTenantid === undefined) {
            throw new runtime.RequiredError('pcsTenantid','Required parameter requestParameters.pcsTenantid was null or undefined when calling getTasksByAggregateId.');
        }

        if (requestParameters.pcsAppid === null || requestParameters.pcsAppid === undefined) {
            throw new runtime.RequiredError('pcsAppid','Required parameter requestParameters.pcsAppid was null or undefined when calling getTasksByAggregateId.');
        }

        if (requestParameters.aggregateId === null || requestParameters.aggregateId === undefined) {
            throw new runtime.RequiredError('aggregateId','Required parameter requestParameters.aggregateId was null or undefined when calling getTasksByAggregateId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/task/task/v1/{aggregateId}`.replace(`{${"aggregateId"}}`, encodeURIComponent(String(requestParameters.aggregateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTasksByAggregateIdResponseFromJSON(jsonValue));
    }

    /**
     * Cancels a task.
     */
    async getTasksByAggregateId(requestParameters: GetTasksByAggregateIdRequest, initOverrides?: RequestInit): Promise<GetTasksByAggregateIdResponse> {
        const response = await this.getTasksByAggregateIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of tasks for user.
     */
    async getUserTasksRaw(requestParameters: GetUserTasksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetUserTasksResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/task/task/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserTasksResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a list of tasks for user.
     */
    async getUserTasks(requestParameters: GetUserTasksRequest = {}, initOverrides?: RequestInit): Promise<GetUserTasksResponse> {
        const response = await this.getUserTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
