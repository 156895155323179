/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NodeType {
    Start = 'START',
    End = 'END',
    Step = 'STEP',
    SplitConditional = 'SPLIT_CONDITIONAL',
    SplitParallel = 'SPLIT_PARALLEL',
    Join = 'JOIN'
}

export function NodeTypeFromJSON(json: any): NodeType {
    return NodeTypeFromJSONTyped(json, false);
}

export function NodeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeType {
    return json as NodeType;
}

export function NodeTypeToJSON(value?: NodeType | null): any {
    return value as any;
}

