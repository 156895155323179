/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConditionOperator {
    Equals = 'EQUALS',
    NotEquals = 'NOT_EQUALS',
    SetContains = 'SET_CONTAINS',
    SetNotContains = 'SET_NOT_CONTAINS'
}

export function ConditionOperatorFromJSON(json: any): ConditionOperator {
    return ConditionOperatorFromJSONTyped(json, false);
}

export function ConditionOperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionOperator {
    return json as ConditionOperator;
}

export function ConditionOperatorToJSON(value?: ConditionOperator | null): any {
    return value as any;
}

