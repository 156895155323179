/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Esignature } from './Esignature';
import {
    EsignatureFromJSON,
    EsignatureFromJSONTyped,
    EsignatureToJSON,
} from './Esignature';
import type { ReopenChangeReason } from './ReopenChangeReason';
import {
    ReopenChangeReasonFromJSON,
    ReopenChangeReasonFromJSONTyped,
    ReopenChangeReasonToJSON,
} from './ReopenChangeReason';

/**
 * When the reason is OTHER, comments are required.
 * @export
 * @interface DirectReopenRecordRequest
 */
export interface DirectReopenRecordRequest {
    /**
     * 
     * @type {ReopenChangeReason}
     * @memberof DirectReopenRecordRequest
     */
    reason: ReopenChangeReason;
    /**
     * 
     * @type {string}
     * @memberof DirectReopenRecordRequest
     */
    comments?: string;
    /**
     * 
     * @type {Esignature}
     * @memberof DirectReopenRecordRequest
     */
    signature: Esignature;
}

export function DirectReopenRecordRequestFromJSON(json: any): DirectReopenRecordRequest {
    return DirectReopenRecordRequestFromJSONTyped(json, false);
}

export function DirectReopenRecordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectReopenRecordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': ReopenChangeReasonFromJSON(json['reason']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'signature': EsignatureFromJSON(json['signature']),
    };
}

export function DirectReopenRecordRequestToJSON(value?: DirectReopenRecordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': ReopenChangeReasonToJSON(value.reason),
        'comments': value.comments,
        'signature': EsignatureToJSON(value.signature),
    };
}

