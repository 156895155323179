/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';

/**
 * 
 * @export
 * @interface NextNodesResponseV2
 */
export interface NextNodesResponseV2 {
    /**
     * 
     * @type {Array<Node>}
     * @memberof NextNodesResponseV2
     */
    nextNodes?: Array<Node>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof NextNodesResponseV2
     */
    roleUserMap?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<Participant>}
     * @memberof NextNodesResponseV2
     */
    participants?: Array<Participant>;
}

export function NextNodesResponseV2FromJSON(json: any): NextNodesResponseV2 {
    return NextNodesResponseV2FromJSONTyped(json, false);
}

export function NextNodesResponseV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): NextNodesResponseV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextNodes': !exists(json, 'nextNodes') ? undefined : (json['nextNodes'] as Array<Node>).map(NodeFromJSON),
        'roleUserMap': !exists(json, 'roleUserMap') ? undefined : json['roleUserMap'],
        'participants': !exists(json, 'participants') ? undefined : (json['participants'] as Array<Participant>).map(ParticipantFromJSON),
    };
}

export function NextNodesResponseV2ToJSON(value?: NextNodesResponseV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextNodes': value.nextNodes === undefined ? undefined : (value.nextNodes as Array<Node>).map(NodeToJSON),
        'roleUserMap': value.roleUserMap,
        'participants': value.participants === undefined ? undefined : (value.participants as Array<Participant>).map(ParticipantToJSON),
    };
}

