/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DurationUnit } from './DurationUnit';
import {
    DurationUnitFromJSON,
    DurationUnitFromJSONTyped,
    DurationUnitToJSON,
} from './DurationUnit';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
} from './NodeType';

/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    id?: string;
    /**
     * 
     * @type {NodeType}
     * @memberof Node
     */
    type: NodeType;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Node
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Node
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Node
     */
    duration?: number;
    /**
     * 
     * @type {DurationUnit}
     * @memberof Node
     */
    durationUnit?: DurationUnit;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    requestChanges?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    eSigRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    eSigNotesRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    allowNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    allowNotApplicable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    restrictToSingleClaimant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    allowCollaboratorManagement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Node
     */
    allowEventOwnerManageCollaboratorsOnFuture?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Node
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Node
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    lastModifiedBy?: string;
}

export function NodeFromJSON(json: any): Node {
    return NodeFromJSONTyped(json, false);
}

export function NodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Node {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': NodeTypeFromJSON(json['type']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'groupIds': !exists(json, 'groupIds') ? undefined : (json['groupIds'] as Array<string>),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationUnit': !exists(json, 'durationUnit') ? undefined : DurationUnitFromJSON(json['durationUnit']),
        'requestChanges': !exists(json, 'requestChanges') ? undefined : json['requestChanges'],
        'eSigRequired': !exists(json, 'eSigRequired') ? undefined : json['eSigRequired'],
        'eSigNotesRequired': !exists(json, 'eSigNotesRequired') ? undefined : json['eSigNotesRequired'],
        'allowNotes': !exists(json, 'allowNotes') ? undefined : json['allowNotes'],
        'allowNotApplicable': !exists(json, 'allowNotApplicable') ? undefined : json['allowNotApplicable'],
        'restrictToSingleClaimant': !exists(json, 'restrictToSingleClaimant') ? undefined : json['restrictToSingleClaimant'],
        'allowCollaboratorManagement': !exists(json, 'allowCollaboratorManagement') ? undefined : json['allowCollaboratorManagement'],
        'allowEventOwnerManageCollaboratorsOnFuture': !exists(json, 'allowEventOwnerManageCollaboratorsOnFuture') ? undefined : json['allowEventOwnerManageCollaboratorsOnFuture'],
        'createdDate': !exists(json, 'createdDate') || json['createdDate'] === '' ? undefined : new Date(json['createdDate']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') || json['lastModifiedDate'] === '' ? undefined : new Date(json['lastModifiedDate']),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
    };
}

export function NodeToJSON(value?: Node | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': NodeTypeToJSON(value.type),
        'name': value.name,
        'description': value.description,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'groupIds': value.groupIds === undefined ? undefined : (value.groupIds as Array<string>),
        'duration': value.duration,
        'durationUnit': DurationUnitToJSON(value.durationUnit),
        'requestChanges': value.requestChanges,
        'eSigRequired': value.eSigRequired,
        'eSigNotesRequired': value.eSigNotesRequired,
        'allowNotes': value.allowNotes,
        'allowNotApplicable': value.allowNotApplicable,
        'restrictToSingleClaimant': value.restrictToSingleClaimant,
        'allowCollaboratorManagement': value.allowCollaboratorManagement,
        'allowEventOwnerManageCollaboratorsOnFuture': value.allowEventOwnerManageCollaboratorsOnFuture,
        'createdDate': value.createdDate === undefined ? undefined : value.createdDate.toISOString(),
        'createdBy': value.createdBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : value.lastModifiedDate.toISOString(),
        'lastModifiedBy': value.lastModifiedBy,
    };
}

