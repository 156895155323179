/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VodPlanStatus } from './VodPlanStatus';
import {
    VodPlanStatusFromJSON,
    VodPlanStatusFromJSONTyped,
    VodPlanStatusToJSON,
} from './VodPlanStatus';

/**
 * Validation Plan
 * @export
 * @interface ValidationPlan
 */
export interface ValidationPlan {
    /**
     * ID of the validation plan
     * @type {string}
     * @memberof ValidationPlan
     */
    id?: string;
    /**
     * ID of the validation plan from VOD service
     * @type {string}
     * @memberof ValidationPlan
     */
    validationPlanId?: string;
    /**
     * 
     * @type {VodPlanStatus}
     * @memberof ValidationPlan
     */
    status?: VodPlanStatus;
    /**
     * 
     * @type {string}
     * @memberof ValidationPlan
     */
    statusMessage?: string;
    /**
     * 
     * @type {Date}
     * @memberof ValidationPlan
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ValidationPlan
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ValidationPlan
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ValidationPlan
     */
    lastModifiedBy?: string;
}

export function ValidationPlanFromJSON(json: any): ValidationPlan {
    return ValidationPlanFromJSONTyped(json, false);
}

export function ValidationPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'validationPlanId': !exists(json, 'validationPlanId') ? undefined : json['validationPlanId'],
        'status': !exists(json, 'status') ? undefined : VodPlanStatusFromJSON(json['status']),
        'statusMessage': !exists(json, 'statusMessage') ? undefined : json['statusMessage'],
        'createdDate': !exists(json, 'createdDate') || json['createdDate'] === '' ? undefined : new Date(json['createdDate']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') || json['lastModifiedDate'] === '' ? undefined : new Date(json['lastModifiedDate']),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
    };
}

export function ValidationPlanToJSON(value?: ValidationPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'validationPlanId': value.validationPlanId,
        'status': VodPlanStatusToJSON(value.status),
        'statusMessage': value.statusMessage,
        'createdDate': value.createdDate === undefined ? undefined : value.createdDate.toISOString(),
        'createdBy': value.createdBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : value.lastModifiedDate.toISOString(),
        'lastModifiedBy': value.lastModifiedBy,
    };
}

