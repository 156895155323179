/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';
import type { MultipleSelectAllOf } from './MultipleSelectAllOf';
import {
    MultipleSelectAllOfFromJSON,
    MultipleSelectAllOfFromJSONTyped,
    MultipleSelectAllOfToJSON,
} from './MultipleSelectAllOf';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';

/**
 * 
 * @export
 * @interface MultipleSelect
 */
export interface MultipleSelect extends FormElement {
    /**
     * 
     * @type {boolean}
     * @memberof MultipleSelect
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MultipleSelect
     */
    hintText?: string;
    /**
     * Local Options for this form element.  This field is mutually exclusive with the 'optionsListId' field.
     * @type {Array<Option>}
     * @memberof MultipleSelect
     */
    options?: Array<Option>;
    /**
     * ID of a managed List which contains the options for this form element.  This field is mutually exclusive with the 'options' field.
     * @type {string}
     * @memberof MultipleSelect
     */
    optionsListId?: string;
    /**
     * ID of a managed List which was the source of local Options for this form element. Note that this is a read-only field.
     * @type {string}
     * @memberof MultipleSelect
     */
    originalOptionsListId?: string;
}

export function MultipleSelectFromJSON(json: any): MultipleSelect {
    return MultipleSelectFromJSONTyped(json, false);
}

export function MultipleSelectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipleSelect {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
        'options': !exists(json, 'options') ? undefined : (json['options'] as Array<Option>).map(OptionFromJSON),
        'optionsListId': !exists(json, 'optionsListId') ? undefined : json['optionsListId'],
        'originalOptionsListId': !exists(json, 'originalOptionsListId') ? undefined : json['originalOptionsListId'],
    };
}

export function MultipleSelectToJSON(value?: MultipleSelect | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'showHintText': value.showHintText,
        'hintText': value.hintText,
        'options': value.options === undefined ? undefined : (value.options as Array<Option>).map(OptionToJSON),
        'optionsListId': value.optionsListId,
        'originalOptionsListId': value.originalOptionsListId,
    };
}

