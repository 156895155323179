/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MoveNodeRequest
 */
export interface MoveNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof MoveNodeRequest
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof MoveNodeRequest
     */
    to?: string;
}

export function MoveNodeRequestFromJSON(json: any): MoveNodeRequest {
    return MoveNodeRequestFromJSONTyped(json, false);
}

export function MoveNodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MoveNodeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
    };
}

export function MoveNodeRequestToJSON(value?: MoveNodeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
    };
}

