/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordLink
 */
export interface RecordLink {
    /**
     * 
     * @type {string}
     * @memberof RecordLink
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordLink
     */
    fromRecordId?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordLink
     */
    fromNodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordLink
     */
    toRecordId?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordLink
     */
    fromElementId?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordLink
     */
    userId?: string;
    /**
     * 
     * @type {Date}
     * @memberof RecordLink
     */
    timeStamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof RecordLink
     */
    recordTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordLink
     */
    recordNumber?: string;
}

export function RecordLinkFromJSON(json: any): RecordLink {
    return RecordLinkFromJSONTyped(json, false);
}

export function RecordLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fromRecordId': !exists(json, 'fromRecordId') ? undefined : json['fromRecordId'],
        'fromNodeId': !exists(json, 'fromNodeId') ? undefined : json['fromNodeId'],
        'toRecordId': !exists(json, 'toRecordId') ? undefined : json['toRecordId'],
        'fromElementId': !exists(json, 'fromElementId') ? undefined : json['fromElementId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'timeStamp': !exists(json, 'timeStamp') || json['timeStamp'] === '' ? undefined : new Date(json['timeStamp']),
        'recordTitle': !exists(json, 'recordTitle') ? undefined : json['recordTitle'],
        'recordNumber': !exists(json, 'recordNumber') ? undefined : json['recordNumber'],
    };
}

export function RecordLinkToJSON(value?: RecordLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fromRecordId': value.fromRecordId,
        'fromNodeId': value.fromNodeId,
        'toRecordId': value.toRecordId,
        'fromElementId': value.fromElementId,
        'userId': value.userId,
        'timeStamp': value.timeStamp === undefined ? undefined : value.timeStamp.toISOString(),
        'recordTitle': value.recordTitle,
        'recordNumber': value.recordNumber,
    };
}

