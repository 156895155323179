/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NodeItemPriorityEnum } from './NodeItemPriorityEnum';
import {
    NodeItemPriorityEnumFromJSON,
    NodeItemPriorityEnumFromJSONTyped,
    NodeItemPriorityEnumToJSON,
} from './NodeItemPriorityEnum';
import type { NodeItemTypeEnum } from './NodeItemTypeEnum';
import {
    NodeItemTypeEnumFromJSON,
    NodeItemTypeEnumFromJSONTyped,
    NodeItemTypeEnumToJSON,
} from './NodeItemTypeEnum';
import type { ParticipantType } from './ParticipantType';
import {
    ParticipantTypeFromJSON,
    ParticipantTypeFromJSONTyped,
    ParticipantTypeToJSON,
} from './ParticipantType';
import type { TaskStatus } from './TaskStatus';
import {
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
} from './TaskStatus';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    id?: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof Task
     */
    status?: TaskStatus;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    executionPlanNodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    dueDate?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof Task
     */
    labels?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    attachmentCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    esigRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    esigNotesRequired?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Task
     */
    focusDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    appId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    participantId?: string;
    /**
     * 
     * @type {ParticipantType}
     * @memberof Task
     */
    participantType?: ParticipantType;
    /**
     * date when first element data was saved that was related to this task
     * @type {Date}
     * @memberof Task
     */
    initiatedDate?: Date;
    /**
     * last date element data was saved related to this task
     * @type {Date}
     * @memberof Task
     */
    lastUpdatedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    recordTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    recordId?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    recordName?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    recordNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    recordOwner?: string;
    /**
     * date when task was completed
     * @type {Date}
     * @memberof Task
     */
    completeDate?: Date;
    /**
     * date when task was launched for the user
     * @type {Date}
     * @memberof Task
     */
    launchDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    completionNotes?: string;
    /**
     * 
     * @type {NodeItemTypeEnum}
     * @memberof Task
     */
    nodeItemType?: NodeItemTypeEnum;
    /**
     * 
     * @type {NodeItemPriorityEnum}
     * @memberof Task
     */
    priority?: NodeItemPriorityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    awaitingActionItems?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    awaitingCorrections?: boolean;
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : TaskStatusFromJSON(json['status']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'executionPlanNodeId': !exists(json, 'executionPlanNodeId') ? undefined : json['executionPlanNodeId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dueDate': !exists(json, 'dueDate') || json['dueDate'] === '' ? undefined : new Date(json['dueDate']),
        'labels': !exists(json, 'labels') ? undefined : (json['labels'] as Array<string>),
        'attachmentCount': !exists(json, 'attachmentCount') ? undefined : json['attachmentCount'],
        'esigRequired': !exists(json, 'esigRequired') ? undefined : json['esigRequired'],
        'esigNotesRequired': !exists(json, 'esigNotesRequired') ? undefined : json['esigNotesRequired'],
        'focusDate': !exists(json, 'focusDate') || json['focusDate'] === '' ? undefined : new Date(json['focusDate']),
        'appId': !exists(json, 'appId') ? undefined : json['appId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'participantId': !exists(json, 'participantId') ? undefined : json['participantId'],
        'participantType': !exists(json, 'participantType') ? undefined : ParticipantTypeFromJSON(json['participantType']),
        'initiatedDate': !exists(json, 'initiatedDate') || json['initiatedDate'] === '' ? undefined : new Date(json['initiatedDate']),
        'lastUpdatedDate': !exists(json, 'lastUpdatedDate') || json['lastUpdatedDate'] === '' ? undefined : new Date(json['lastUpdatedDate']),
        'recordTitle': !exists(json, 'recordTitle') ? undefined : json['recordTitle'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'recordName': !exists(json, 'recordName') ? undefined : json['recordName'],
        'recordNumber': !exists(json, 'recordNumber') ? undefined : json['recordNumber'],
        'recordOwner': !exists(json, 'recordOwner') ? undefined : json['recordOwner'],
        'completeDate': !exists(json, 'completeDate') || json['completeDate'] === '' ? undefined : new Date(json['completeDate']),
        'launchDate': !exists(json, 'launchDate') || json['launchDate'] === '' ? undefined : new Date(json['launchDate']),
        'completionNotes': !exists(json, 'completionNotes') ? undefined : json['completionNotes'],
        'nodeItemType': !exists(json, 'nodeItemType') ? undefined : NodeItemTypeEnumFromJSON(json['nodeItemType']),
        'priority': !exists(json, 'priority') ? undefined : NodeItemPriorityEnumFromJSON(json['priority']),
        'awaitingActionItems': !exists(json, 'awaitingActionItems') ? undefined : json['awaitingActionItems'],
        'awaitingCorrections': !exists(json, 'awaitingCorrections') ? undefined : json['awaitingCorrections'],
    };
}

export function TaskToJSON(value?: Task | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': TaskStatusToJSON(value.status),
        'name': value.name,
        'executionPlanNodeId': value.executionPlanNodeId,
        'description': value.description,
        'dueDate': value.dueDate === undefined ? undefined : value.dueDate.toISOString(),
        'labels': value.labels === undefined ? undefined : (value.labels as Array<string>),
        'attachmentCount': value.attachmentCount,
        'esigRequired': value.esigRequired,
        'esigNotesRequired': value.esigNotesRequired,
        'focusDate': value.focusDate === undefined ? undefined : value.focusDate.toISOString(),
        'appId': value.appId,
        'userId': value.userId,
        'roleId': value.roleId,
        'participantId': value.participantId,
        'participantType': ParticipantTypeToJSON(value.participantType),
        'initiatedDate': value.initiatedDate === undefined ? undefined : value.initiatedDate.toISOString(),
        'lastUpdatedDate': value.lastUpdatedDate === undefined ? undefined : value.lastUpdatedDate.toISOString(),
        'recordTitle': value.recordTitle,
        'tenantId': value.tenantId,
        'recordId': value.recordId,
        'recordName': value.recordName,
        'recordNumber': value.recordNumber,
        'recordOwner': value.recordOwner,
        'completeDate': value.completeDate === undefined ? undefined : value.completeDate.toISOString(),
        'launchDate': value.launchDate === undefined ? undefined : value.launchDate.toISOString(),
        'completionNotes': value.completionNotes,
        'nodeItemType': NodeItemTypeEnumToJSON(value.nodeItemType),
        'priority': NodeItemPriorityEnumToJSON(value.priority),
        'awaitingActionItems': value.awaitingActionItems,
        'awaitingCorrections': value.awaitingCorrections,
    };
}

