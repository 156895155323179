/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckNameResponse
 */
export interface CheckNameResponse {
    /**
     * 
     * @type {string}
     * @memberof CheckNameResponse
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckNameResponse
     */
    availability?: boolean;
}

export function CheckNameResponseFromJSON(json: any): CheckNameResponse {
    return CheckNameResponseFromJSONTyped(json, false);
}

export function CheckNameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckNameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'availability': !exists(json, 'availability') ? undefined : json['availability'],
    };
}

export function CheckNameResponseToJSON(value?: CheckNameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'availability': value.availability,
    };
}

