/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OperandType {
    Left = 'LEFT',
    Right = 'RIGHT'
}

export function OperandTypeFromJSON(json: any): OperandType {
    return OperandTypeFromJSONTyped(json, false);
}

export function OperandTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperandType {
    return json as OperandType;
}

export function OperandTypeToJSON(value?: OperandType | null): any {
    return value as any;
}

