/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';
import type { RiskMatrixAllOf } from './RiskMatrixAllOf';
import {
    RiskMatrixAllOfFromJSON,
    RiskMatrixAllOfFromJSONTyped,
    RiskMatrixAllOfToJSON,
} from './RiskMatrixAllOf';

/**
 * 
 * @export
 * @interface RiskMatrix
 */
export interface RiskMatrix extends FormElement {
    /**
     * 
     * @type {string}
     * @memberof RiskMatrix
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrix
     */
    riskMatrixId?: string;
    /**
     * 
     * @type {number}
     * @memberof RiskMatrix
     */
    riskMatrixRevisionId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrix
     */
    requireRationale?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrix
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrix
     */
    hintText?: string;
}

export function RiskMatrixFromJSON(json: any): RiskMatrix {
    return RiskMatrixFromJSONTyped(json, false);
}

export function RiskMatrixFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskMatrix {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'riskMatrixId': !exists(json, 'riskMatrixId') ? undefined : json['riskMatrixId'],
        'riskMatrixRevisionId': !exists(json, 'riskMatrixRevisionId') ? undefined : json['riskMatrixRevisionId'],
        'requireRationale': !exists(json, 'requireRationale') ? undefined : json['requireRationale'],
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
    };
}

export function RiskMatrixToJSON(value?: RiskMatrix | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'placeholder': value.placeholder,
        'riskMatrixId': value.riskMatrixId,
        'riskMatrixRevisionId': value.riskMatrixRevisionId,
        'requireRationale': value.requireRationale,
        'showHintText': value.showHintText,
        'hintText': value.hintText,
    };
}

