/* tslint:disable */
/* eslint-disable */
/**
 * Session Service OpenAPI Definition
 * Title
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
    AuthResult,
    AuthResultFromJSON,
    AuthResultToJSON,
    AuthResultV2,
    AuthResultV2FromJSON,
    AuthResultV2ToJSON,
    AuthStart,
    AuthStartFromJSON,
    AuthStartToJSON,
    AuthStartV2,
    AuthStartV2FromJSON,
    AuthStartV2ToJSON,
    ClaimsResponse,
    ClaimsResponseFromJSON,
    ClaimsResponseToJSON,
    Introspection,
    IntrospectionFromJSON,
    IntrospectionToJSON,
    SessionDetail,
    SessionDetailFromJSON,
    SessionDetailToJSON,
    SessionResult,
    SessionResultFromJSON,
    SessionResultToJSON,
    SessionStart,
    SessionStartFromJSON,
    SessionStartToJSON,
    TokenRefreshResult,
    TokenRefreshResultFromJSON,
    TokenRefreshResultToJSON,
    VodAuthStart,
    VodAuthStartFromJSON,
    VodAuthStartToJSON,
} from "../models";

export interface DeleteSessionByPcsSessionRequest {
    sessionId: string;
}

export interface DeleteSessionByQxSessionRequest {
    sessionId: string;
}

export interface GetPcsSessionStatusRequest {
    sessionId: string;
}

export interface IntrospectTokenRequest {
    introspection: Introspection;
}

export interface PcsSessionExtendAndNewTokenRequest {
    sessionId: string;
}

export interface PcsSessionKeepAliveRequest {
    sessionId: string;
}

export interface PcsSessionV1QxTenantIdStatusPostRequest {
    tenantId: string;
    sessionDetail?: Array<SessionDetail>;
}

export interface StartPCSSessionRequest {
    authStart: AuthStart;
}

export interface StartPcsSessionAndLinkQxSessionRequest {
    authStartV2: AuthStartV2;
}

export interface StartQxSessionRequest {
    sessionStart: SessionStart;
}

export interface StartVodSessionRequest {
    vodAuthStart: VodAuthStart;
}

/**
 *
 */
export class SessionApi extends runtime.BaseAPI {
    /**
     * Terminate a session based on PCS session id. Requires a service-to-service token.
     */
    async deleteSessionByPcsSessionRaw(
        requestParameters: DeleteSessionByPcsSessionRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError(
                "sessionId",
                "Required parameter requestParameters.sessionId was null or undefined when calling deleteSessionByPcsSession.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/pcs/session/v1/pcs/{sessionId}`.replace(
                    `{${"sessionId"}}`,
                    encodeURIComponent(String(requestParameters.sessionId)),
                ),
                method: "DELETE",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Terminate a session based on PCS session id. Requires a service-to-service token.
     */
    async deleteSessionByPcsSession(
        requestParameters: DeleteSessionByPcsSessionRequest,
        initOverrides?: RequestInit,
    ): Promise<void> {
        await this.deleteSessionByPcsSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Terminate a session based on Qx session id. Requires a service-to-service token.
     */
    async deleteSessionByQxSessionRaw(
        requestParameters: DeleteSessionByQxSessionRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError(
                "sessionId",
                "Required parameter requestParameters.sessionId was null or undefined when calling deleteSessionByQxSession.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/pcs/session/v1/qx/{sessionId}`.replace(
                    `{${"sessionId"}}`,
                    encodeURIComponent(String(requestParameters.sessionId)),
                ),
                method: "DELETE",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Terminate a session based on Qx session id. Requires a service-to-service token.
     */
    async deleteSessionByQxSession(
        requestParameters: DeleteSessionByQxSessionRequest,
        initOverrides?: RequestInit,
    ): Promise<void> {
        await this.deleteSessionByQxSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Get the status of a session based on PCS session id. Requires a service-to-service token.
     */
    async getPcsSessionStatusRaw(
        requestParameters: GetPcsSessionStatusRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<SessionResult>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError(
                "sessionId",
                "Required parameter requestParameters.sessionId was null or undefined when calling getPcsSessionStatus.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/pcs/session/v1/pcs/{sessionId}/status`.replace(
                    `{${"sessionId"}}`,
                    encodeURIComponent(String(requestParameters.sessionId)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionResultFromJSON(jsonValue));
    }

    /**
     * Get the status of a session based on PCS session id. Requires a service-to-service token.
     */
    async getPcsSessionStatus(
        requestParameters: GetPcsSessionStatusRequest,
        initOverrides?: RequestInit,
    ): Promise<SessionResult> {
        const response = await this.getPcsSessionStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if the provided JWT is valid.
     */
    async introspectTokenRaw(
        requestParameters: IntrospectTokenRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<ClaimsResponse>> {
        if (requestParameters.introspection === null || requestParameters.introspection === undefined) {
            throw new runtime.RequiredError(
                "introspection",
                "Required parameter requestParameters.introspection was null or undefined when calling introspectToken.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request(
            {
                path: `/pcs/session/v1/introspect`,
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: IntrospectionToJSON(requestParameters.introspection),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaimsResponseFromJSON(jsonValue));
    }

    /**
     * Check if the provided JWT is valid.
     */
    async introspectToken(
        requestParameters: IntrospectTokenRequest,
        initOverrides?: RequestInit,
    ): Promise<ClaimsResponse> {
        const response = await this.introspectTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Json Web Key Set
     */
    async jwksRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<{ [key: string]: object }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/pcs/session/v1/jwks`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Json Web Key Set
     */
    async jwks(initOverrides?: RequestInit): Promise<{ [key: string]: object }> {
        const response = await this.jwksRaw(initOverrides);
        return await response.value();
    }

    /**
     * Extend the TTL and get the status of a session and a new access token, based on the PCS session ID.
     */
    async pcsSessionExtendAndNewTokenRaw(
        requestParameters: PcsSessionExtendAndNewTokenRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<TokenRefreshResult>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError(
                "sessionId",
                "Required parameter requestParameters.sessionId was null or undefined when calling pcsSessionExtendAndNewToken.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/pcs/session/v1/pcs/{sessionId}/token`.replace(
                    `{${"sessionId"}}`,
                    encodeURIComponent(String(requestParameters.sessionId)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRefreshResultFromJSON(jsonValue));
    }

    /**
     * Extend the TTL and get the status of a session and a new access token, based on the PCS session ID.
     */
    async pcsSessionExtendAndNewToken(
        requestParameters: PcsSessionExtendAndNewTokenRequest,
        initOverrides?: RequestInit,
    ): Promise<TokenRefreshResult> {
        const response = await this.pcsSessionExtendAndNewTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extend the TTL and get the status of a session and a new access token, based on the PCS session ID retrieved from JWT.
     */
    async pcsSessionExtendAndNewTokenWithJwtRaw(
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<TokenRefreshResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/pcs/session/v1/pcs/token`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRefreshResultFromJSON(jsonValue));
    }

    /**
     * Extend the TTL and get the status of a session and a new access token, based on the PCS session ID retrieved from JWT.
     */
    async pcsSessionExtendAndNewTokenWithJwt(initOverrides?: RequestInit): Promise<TokenRefreshResult> {
        const response = await this.pcsSessionExtendAndNewTokenWithJwtRaw(initOverrides);
        return await response.value();
    }

    /**
     * Extend the TTL and get the status of a session based on PCS session id. Requires a service-to-service token.
     */
    async pcsSessionKeepAliveRaw(
        requestParameters: PcsSessionKeepAliveRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<SessionResult>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError(
                "sessionId",
                "Required parameter requestParameters.sessionId was null or undefined when calling pcsSessionKeepAlive.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request(
            {
                path: `/pcs/session/v1/pcs/{sessionId}/keepalive`.replace(
                    `{${"sessionId"}}`,
                    encodeURIComponent(String(requestParameters.sessionId)),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionResultFromJSON(jsonValue));
    }

    /**
     * Extend the TTL and get the status of a session based on PCS session id. Requires a service-to-service token.
     */
    async pcsSessionKeepAlive(
        requestParameters: PcsSessionKeepAliveRequest,
        initOverrides?: RequestInit,
    ): Promise<SessionResult> {
        const response = await this.pcsSessionKeepAliveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extend the TTL of included session ids for tenant and get all active Qx session ids for given tenant id. Requires a service-to-service auth token.
     */
    async pcsSessionV1QxTenantIdStatusPostRaw(
        requestParameters: PcsSessionV1QxTenantIdStatusPostRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<Array<SessionDetail>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError(
                "tenantId",
                "Required parameter requestParameters.tenantId was null or undefined when calling pcsSessionV1QxTenantIdStatusPost.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/pcs/session/v1/qx/{tenantId}/status`.replace(
                    `{${"tenantId"}}`,
                    encodeURIComponent(String(requestParameters.tenantId)),
                ),
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.sessionDetail?.map(SessionDetailToJSON),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionDetailFromJSON));
    }

    /**
     * Extend the TTL of included session ids for tenant and get all active Qx session ids for given tenant id. Requires a service-to-service auth token.
     */
    async pcsSessionV1QxTenantIdStatusPost(
        requestParameters: PcsSessionV1QxTenantIdStatusPostRequest,
        initOverrides?: RequestInit,
    ): Promise<Array<SessionDetail>> {
        const response = await this.pcsSessionV1QxTenantIdStatusPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start a new PCS session based on an Okta token. Publicly open.
     */
    async startPCSSessionRaw(
        requestParameters: StartPCSSessionRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<AuthResult>> {
        if (requestParameters.authStart === null || requestParameters.authStart === undefined) {
            throw new runtime.RequiredError(
                "authStart",
                "Required parameter requestParameters.authStart was null or undefined when calling startPCSSession.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request(
            {
                path: `/pcs/session/auth/v1/start`,
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: AuthStartToJSON(requestParameters.authStart),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthResultFromJSON(jsonValue));
    }

    /**
     * Start a new PCS session based on an Okta token. Publicly open.
     */
    async startPCSSession(requestParameters: StartPCSSessionRequest, initOverrides?: RequestInit): Promise<AuthResult> {
        const response = await this.startPCSSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start a new PCS session and link the Qx Session. Publicly open.
     */
    async startPcsSessionAndLinkQxSessionRaw(
        requestParameters: StartPcsSessionAndLinkQxSessionRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<AuthResultV2>> {
        if (requestParameters.authStartV2 === null || requestParameters.authStartV2 === undefined) {
            throw new runtime.RequiredError(
                "authStartV2",
                "Required parameter requestParameters.authStartV2 was null or undefined when calling startPcsSessionAndLinkQxSession.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request(
            {
                path: `/pcs/session/auth/v2/start`,
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: AuthStartV2ToJSON(requestParameters.authStartV2),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthResultV2FromJSON(jsonValue));
    }

    /**
     * Start a new PCS session and link the Qx Session. Publicly open.
     */
    async startPcsSessionAndLinkQxSession(
        requestParameters: StartPcsSessionAndLinkQxSessionRequest,
        initOverrides?: RequestInit,
    ): Promise<AuthResultV2> {
        const response = await this.startPcsSessionAndLinkQxSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start/Link a Qx session and its underlying PCS session. Requires a service-to-service token.
     */
    async startQxSessionRaw(
        requestParameters: StartQxSessionRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sessionStart === null || requestParameters.sessionStart === undefined) {
            throw new runtime.RequiredError(
                "sessionStart",
                "Required parameter requestParameters.sessionStart was null or undefined when calling startQxSession.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/pcs/session/v1/start`,
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: SessionStartToJSON(requestParameters.sessionStart),
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start/Link a Qx session and its underlying PCS session. Requires a service-to-service token.
     */
    async startQxSession(requestParameters: StartQxSessionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.startQxSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Start a new PCS session based on supplied values. Requires a service-to-service token.
     */
    async startVodSessionRaw(
        requestParameters: StartVodSessionRequest,
        initOverrides?: RequestInit,
    ): Promise<runtime.ApiResponse<AuthResult>> {
        if (requestParameters.vodAuthStart === null || requestParameters.vodAuthStart === undefined) {
            throw new runtime.RequiredError(
                "vodAuthStart",
                "Required parameter requestParameters.vodAuthStart was null or undefined when calling startVodSession.",
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        const response = await this.request(
            {
                path: `/pcs/session/auth/v1/vod`,
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: VodAuthStartToJSON(requestParameters.vodAuthStart),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthResultFromJSON(jsonValue));
    }

    /**
     * Start a new PCS session based on supplied values. Requires a service-to-service token.
     */
    async startVodSession(requestParameters: StartVodSessionRequest, initOverrides?: RequestInit): Promise<AuthResult> {
        const response = await this.startVodSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
