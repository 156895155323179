/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RecordType {
    OutOfSpecification = 'OUT_OF_SPECIFICATION',
    EventReport = 'EVENT_REPORT',
    ManagementOfChange = 'MANAGEMENT_OF_CHANGE',
    IssueReview = 'ISSUE_REVIEW',
    Deviation = 'DEVIATION',
    Nonconformance = 'NONCONFORMANCE',
    CustomerComplaint = 'CUSTOMER_COMPLAINT',
    Capa = 'CAPA',
    Other = 'OTHER',
    ActionItem = 'ACTION_ITEM',
    LogbookSystem = 'LOGBOOK_SYSTEM',
    LogbookEntry = 'LOGBOOK_ENTRY',
    None = 'NONE'
}

export function RecordTypeFromJSON(json: any): RecordType {
    return RecordTypeFromJSONTyped(json, false);
}

export function RecordTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordType {
    return json as RecordType;
}

export function RecordTypeToJSON(value?: RecordType | null): any {
    return value as any;
}

