import { applicationConfig } from "../application-configuration";
import { applicationSettingsStore } from "../store";
import { ApiConfiguration } from "../types";
const prodHostNames = ["apps.mastercontrol.com", "test.apps.mastercontrol.com"];

export const apiConfig: ApiConfiguration = {
    get accessToken() {
        return applicationSettingsStore.getState().accessToken;
    },
    get basePath() {
        const basePath = applicationConfig?.api?.rest?.urls?.default ?? "";
        if (!basePath) {
            // NOTE: We can't provide a valid configuration without
            // a base API path.
            throw new Error("No API base path is configured.");
        }
        return basePath;
    },
    get headers() {
        const localFlags = localStorage.getItem("featureFlags");
        const result: Record<string, string> = {
            "Mc-Application-Id": window.location.pathname.split("/")[1],
        };
        const isProd = prodHostNames.includes(window.location.hostname);
        if (!isProd && localFlags) {
            result["X-Mc-Flag-Overrides"] = localFlags;
        }
        return result;
    },
};
