/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRecordCommandResponse
 */
export interface CreateRecordCommandResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateRecordCommandResponse
     */
    recordId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecordCommandResponse
     */
    taskId?: string;
}

export function CreateRecordCommandResponseFromJSON(json: any): CreateRecordCommandResponse {
    return CreateRecordCommandResponseFromJSONTyped(json, false);
}

export function CreateRecordCommandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRecordCommandResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
    };
}

export function CreateRecordCommandResponseToJSON(value?: CreateRecordCommandResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordId': value.recordId,
        'taskId': value.taskId,
    };
}

