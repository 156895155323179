/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';
import type { Record } from './Record';
import {
    RecordFromJSON,
    RecordFromJSONTyped,
    RecordToJSON,
} from './Record';

/**
 * 
 * @export
 * @interface RecordPreview
 */
export interface RecordPreview {
    /**
     * 
     * @type {Array<Node>}
     * @memberof RecordPreview
     */
    nodes?: Array<Node>;
    /**
     * 
     * @type {Record}
     * @memberof RecordPreview
     */
    record?: Record;
}

export function RecordPreviewFromJSON(json: any): RecordPreview {
    return RecordPreviewFromJSONTyped(json, false);
}

export function RecordPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodes': !exists(json, 'nodes') ? undefined : (json['nodes'] as Array<Node>).map(NodeFromJSON),
        'record': !exists(json, 'record') ? undefined : RecordFromJSON(json['record']),
    };
}

export function RecordPreviewToJSON(value?: RecordPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodes': value.nodes === undefined ? undefined : (value.nodes as Array<Node>).map(NodeToJSON),
        'record': RecordToJSON(value.record),
    };
}

