/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateParticipantCommandResponse,
    CreateParticipantCommandResponseFromJSON,
    CreateParticipantCommandResponseToJSON,
    CreateParticipantRequest,
    CreateParticipantRequestFromJSON,
    CreateParticipantRequestToJSON,
    DeleteParticipantsCommand,
    DeleteParticipantsCommandFromJSON,
    DeleteParticipantsCommandToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetUserIdsResponse,
    GetUserIdsResponseFromJSON,
    GetUserIdsResponseToJSON,
    ReplaceParticipantRequest,
    ReplaceParticipantRequestFromJSON,
    ReplaceParticipantRequestToJSON,
    UpdateParticipantRequest,
    UpdateParticipantRequestFromJSON,
    UpdateParticipantRequestToJSON,
} from '../models';

export interface CreateParticipantOperationRequest {
    createParticipantRequest: CreateParticipantRequest;
}

export interface DeleteNodeParticipantsRequest {
    nodeId: string;
    deleteParticipantsCommand: DeleteParticipantsCommand;
}

export interface GetUserIdsRequest {
    participantId: string;
}

export interface RemoveParticipantRequest {
    participantId: string;
}

export interface ReplaceParticipantOperationRequest {
    participantId: string;
    replaceParticipantRequest: ReplaceParticipantRequest;
}

export interface UpdateParticipantOperationRequest {
    participantId: string;
    updateParticipantRequest: UpdateParticipantRequest;
}

/**
 * 
 */
export class ParticipantApi extends runtime.BaseAPI {

    /**
     * Create a participant on a node or record. License required is Full or Basic.  Note that creating a RULE-type participant is currently prohibited.
     * Create a user or role on a node or on a record, in case of record owners.
     */
    async createParticipantRaw(requestParameters: CreateParticipantOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateParticipantCommandResponse>> {
        if (requestParameters.createParticipantRequest === null || requestParameters.createParticipantRequest === undefined) {
            throw new runtime.RequiredError('createParticipantRequest','Required parameter requestParameters.createParticipantRequest was null or undefined when calling createParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/participant/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateParticipantRequestToJSON(requestParameters.createParticipantRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateParticipantCommandResponseFromJSON(jsonValue));
    }

    /**
     * Create a participant on a node or record. License required is Full or Basic.  Note that creating a RULE-type participant is currently prohibited.
     * Create a user or role on a node or on a record, in case of record owners.
     */
    async createParticipant(requestParameters: CreateParticipantOperationRequest, initOverrides?: RequestInit): Promise<CreateParticipantCommandResponse> {
        const response = await this.createParticipantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Supports deleting node participants by actor and participant types
     * Delete node participants by actor and participant types
     */
    async deleteNodeParticipantsRaw(requestParameters: DeleteNodeParticipantsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling deleteNodeParticipants.');
        }

        if (requestParameters.deleteParticipantsCommand === null || requestParameters.deleteParticipantsCommand === undefined) {
            throw new runtime.RequiredError('deleteParticipantsCommand','Required parameter requestParameters.deleteParticipantsCommand was null or undefined when calling deleteNodeParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/participants`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteParticipantsCommandToJSON(requestParameters.deleteParticipantsCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supports deleting node participants by actor and participant types
     * Delete node participants by actor and participant types
     */
    async deleteNodeParticipants(requestParameters: DeleteNodeParticipantsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteNodeParticipantsRaw(requestParameters, initOverrides);
    }

    /**
     * Query identity user Ids of given participantId. Requires Full or Basic license.
     * Query identity user Ids of given participantId
     */
    async getUserIdsRaw(requestParameters: GetUserIdsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetUserIdsResponse>> {
        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling getUserIds.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/participant/v1/{participantId}/user-ids`.replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserIdsResponseFromJSON(jsonValue));
    }

    /**
     * Query identity user Ids of given participantId. Requires Full or Basic license.
     * Query identity user Ids of given participantId
     */
    async getUserIds(requestParameters: GetUserIdsRequest, initOverrides?: RequestInit): Promise<GetUserIdsResponse> {
        const response = await this.getUserIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove single participant with the given type of participant and actor. Requires Full or Basic license.  Note that removing a RULE-type participant is currently prohibited.
     * Remove single participant
     */
    async removeParticipantRaw(requestParameters: RemoveParticipantRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling removeParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/participant/v1/{participantId}`.replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove single participant with the given type of participant and actor. Requires Full or Basic license.  Note that removing a RULE-type participant is currently prohibited.
     * Remove single participant
     */
    async removeParticipant(requestParameters: RemoveParticipantRequest, initOverrides?: RequestInit): Promise<void> {
        await this.removeParticipantRaw(requestParameters, initOverrides);
    }

    /**
     * Replace participant. Requires Full or Basic license. Note that replacing a RULE-type participant is currently prohibited.
     * Replace participant
     */
    async replaceParticipantRaw(requestParameters: ReplaceParticipantOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling replaceParticipant.');
        }

        if (requestParameters.replaceParticipantRequest === null || requestParameters.replaceParticipantRequest === undefined) {
            throw new runtime.RequiredError('replaceParticipantRequest','Required parameter requestParameters.replaceParticipantRequest was null or undefined when calling replaceParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/participant/v1/{participantId}/actor`.replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ReplaceParticipantRequestToJSON(requestParameters.replaceParticipantRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Replace participant. Requires Full or Basic license. Note that replacing a RULE-type participant is currently prohibited.
     * Replace participant
     */
    async replaceParticipant(requestParameters: ReplaceParticipantOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.replaceParticipantRaw(requestParameters, initOverrides);
    }

    /**
     * Update participant. Requires Full or Basic license. Note that updating a RULE-type participant is currently prohibited.
     * Update participant
     */
    async updateParticipantRaw(requestParameters: UpdateParticipantOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
            throw new runtime.RequiredError('participantId','Required parameter requestParameters.participantId was null or undefined when calling updateParticipant.');
        }

        if (requestParameters.updateParticipantRequest === null || requestParameters.updateParticipantRequest === undefined) {
            throw new runtime.RequiredError('updateParticipantRequest','Required parameter requestParameters.updateParticipantRequest was null or undefined when calling updateParticipant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/participant/v1/{participantId}`.replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateParticipantRequestToJSON(requestParameters.updateParticipantRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update participant. Requires Full or Basic license. Note that updating a RULE-type participant is currently prohibited.
     * Update participant
     */
    async updateParticipant(requestParameters: UpdateParticipantOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateParticipantRaw(requestParameters, initOverrides);
    }

}
