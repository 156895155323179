/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PcsListStatus } from './PcsListStatus';
import {
    PcsListStatusFromJSON,
    PcsListStatusFromJSONTyped,
    PcsListStatusToJSON,
} from './PcsListStatus';

/**
 * for updating a small list
 * @export
 * @interface UpdateSmallPcsListCommandV2
 */
export interface UpdateSmallPcsListCommandV2 {
    /**
     * 
     * @type {string}
     * @memberof UpdateSmallPcsListCommandV2
     */
    name: string;
    /**
     * 
     * @type {PcsListStatus}
     * @memberof UpdateSmallPcsListCommandV2
     */
    status: PcsListStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateSmallPcsListCommandV2
     */
    description?: string;
    /**
     * each item within the pcs-list
     * @type {Array<string>}
     * @memberof UpdateSmallPcsListCommandV2
     */
    items?: Array<string>;
}

export function UpdateSmallPcsListCommandV2FromJSON(json: any): UpdateSmallPcsListCommandV2 {
    return UpdateSmallPcsListCommandV2FromJSONTyped(json, false);
}

export function UpdateSmallPcsListCommandV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSmallPcsListCommandV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'status': PcsListStatusFromJSON(json['status']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<string>),
    };
}

export function UpdateSmallPcsListCommandV2ToJSON(value?: UpdateSmallPcsListCommandV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': PcsListStatusToJSON(value.status),
        'description': value.description,
        'items': value.items === undefined ? undefined : (value.items as Array<string>),
    };
}

