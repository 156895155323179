/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancelChangeReason } from './CancelChangeReason';
import {
    CancelChangeReasonFromJSON,
    CancelChangeReasonFromJSONTyped,
    CancelChangeReasonToJSON,
} from './CancelChangeReason';

/**
 * When the reason is OTHER, comments are required.
 * @export
 * @interface CancelRecordRequest
 */
export interface CancelRecordRequest {
    /**
     * 
     * @type {CancelChangeReason}
     * @memberof CancelRecordRequest
     */
    reason: CancelChangeReason;
    /**
     * 
     * @type {string}
     * @memberof CancelRecordRequest
     */
    comments?: string;
}

export function CancelRecordRequestFromJSON(json: any): CancelRecordRequest {
    return CancelRecordRequestFromJSONTyped(json, false);
}

export function CancelRecordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelRecordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': CancelChangeReasonFromJSON(json['reason']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function CancelRecordRequestToJSON(value?: CancelRecordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': CancelChangeReasonToJSON(value.reason),
        'comments': value.comments,
    };
}

