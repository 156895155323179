export interface CapitalizeOptions {
    onlyCapitalizeFirstWord?: boolean;
}

export function lowercase<TValue extends string | unknown>(value: TValue, locale?: string) {
    if (typeof value !== "string") {
        return value as TValue;
    }
    return value.toLocaleLowerCase(locale);
}

export function uppercase<TValue extends string | unknown>(value: TValue, locale?: string) {
    if (typeof value !== "string") {
        return value;
    }
    return value.toLocaleUpperCase(locale);
}

export function underscore<TValue extends string | unknown>(value: TValue) {
    if (typeof value !== "string") {
        return value;
    }
    return value.replaceAll(/\s+/g, "_");
}

export function removeUnderscore<TValue extends string | unknown>(value: TValue) {
    if (typeof value !== "string") {
        return value;
    }
    return value.replaceAll(/_/g, " ");
}

export function capitalize<TValue extends string | unknown>(
    value: TValue,
    locale?: string,
    { onlyCapitalizeFirstWord = true }: CapitalizeOptions = {},
) {
    if (typeof value !== "string") {
        return value;
    }

    const capitalizeRegex = /^([a-z])|\s([a-z])/;

    if (onlyCapitalizeFirstWord) {
        return lowercase<string>(value, locale)
            ?.trim()
            ?.replace(capitalizeRegex, (letter) => uppercase(letter, locale));
    }

    return lowercase<string>(value, locale)
        ?.trim()
        ?.replaceAll(new RegExp(capitalizeRegex, "g"), (letter) => uppercase(letter, locale));
}

export const i18nFormatterFunctions: Array<
    (value: string, locale?: string, options?: Record<string, unknown>) => string
> = [lowercase, uppercase, underscore, removeUnderscore, capitalize];
