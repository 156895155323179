/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationResult
 */
export interface PaginationResult {
    /**
     * 
     * @type {number}
     * @memberof PaginationResult
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResult
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResult
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResult
     */
    totalResults?: number;
}

export function PaginationResultFromJSON(json: any): PaginationResult {
    return PaginationResultFromJSONTyped(json, false);
}

export function PaginationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalResults': !exists(json, 'totalResults') ? undefined : json['totalResults'],
    };
}

export function PaginationResultToJSON(value?: PaginationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalPages': value.totalPages,
        'totalResults': value.totalResults,
    };
}

