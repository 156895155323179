/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    formElementId: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    value?: string;
    /**
     * 
     * @type {number}
     * @memberof Option
     */
    displayOrder: number;
}

export function OptionFromJSON(json: any): Option {
    return OptionFromJSONTyped(json, false);
}

export function OptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Option {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'formElementId': json['formElementId'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'displayOrder': json['displayOrder'],
    };
}

export function OptionToJSON(value?: Option | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'formElementId': value.formElementId,
        'value': value.value,
        'displayOrder': value.displayOrder,
    };
}

