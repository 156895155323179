/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AttachmentMediaType {
    Document = 'DOCUMENT',
    Image = 'IMAGE',
    Pdf = 'PDF',
    Presentation = 'PRESENTATION',
    Spreadsheet = 'SPREADSHEET'
}

export function AttachmentMediaTypeFromJSON(json: any): AttachmentMediaType {
    return AttachmentMediaTypeFromJSONTyped(json, false);
}

export function AttachmentMediaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentMediaType {
    return json as AttachmentMediaType;
}

export function AttachmentMediaTypeToJSON(value?: AttachmentMediaType | null): any {
    return value as any;
}

