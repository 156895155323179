import flagsmith from "flagsmith";
import { loadApplicationConfiguration } from "./application-configuration";
import { identifyFeatureFlagUser } from "./feature-flags";
import { setUpScriptProvider } from "./scripts";
import { applicationSettingsStore } from "./store";
import { ApplicationConfiguration } from "./types";

function getNonce() {
    let nonce = undefined;
    if (globalThis.document) {
        const scripts = globalThis.document.getElementsByTagName("script");
        for (let i = 0, length = scripts.length; i < length; i++) {
            nonce = scripts[i].nonce;
            if (nonce) {
                break;
            }
        }
    }
    return nonce;
}
/**
 * This should be called ONCE by the adapt host.
 * After that, updates to the authentication context, theme, and
 * api configuration will be reflected in the applicationSettingsStore.
 *
 * @returns The settings used by applications
 */
export async function init(initI18n: (applicationConfig: ApplicationConfiguration) => Promise<void>) {
    const applicationConfiguration = await loadApplicationConfiguration();
    applicationSettingsStore.getState().setApplicationConfiguration(applicationConfiguration);
    await flagsmith.init({
        cacheFlags: true,
        cacheOptions: {
            skipAPI: true,
            ttl: 5 * 60 * 1000,
        },
        environmentID: applicationConfiguration?.featureFlag?.key,
    });
    identifyFeatureFlagUser();
    setUpScriptProvider();
    await initI18n(applicationConfiguration);
}
export const nonce = getNonce();
export { apiConfig } from "./api-configuration/api-configuration";
export {
    API_URI_OVERRIDE_STORAGE_KEY,
    applicationConfig,
    MOCK_ACCESS_TOKEN_STORAGE_KEY,
    MOCK_AUTH_STORAGE_KEY,
} from "./application-configuration";
export * from "./auth/auth-storage";
export * from "./auth/auth.hooks";
export * from "./auth/local-storage.utils";
export * from "./auth/utils";
export { getLocalFeatureFlags, hasFeature, localFeatureFlagsStorageKey } from "./feature-flags";
export type { FeatureFlagsMap } from "./feature-flags";
export * from "./queryClient";
export * from "./scripts";
export * from "./store";
export * from "./types";
