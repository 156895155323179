/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResult } from './PaginationResult';
import {
    PaginationResultFromJSON,
    PaginationResultFromJSONTyped,
    PaginationResultToJSON,
} from './PaginationResult';

/**
 * 
 * @export
 * @interface PcsListItems
 */
export interface PcsListItems {
    /**
     * each item within the pcs-list
     * @type {Array<string>}
     * @memberof PcsListItems
     */
    items?: Array<string>;
    /**
     * 
     * @type {PaginationResult}
     * @memberof PcsListItems
     */
    pagination?: PaginationResult;
}

export function PcsListItemsFromJSON(json: any): PcsListItems {
    return PcsListItemsFromJSONTyped(json, false);
}

export function PcsListItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PcsListItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<string>),
        'pagination': !exists(json, 'pagination') ? undefined : PaginationResultFromJSON(json['pagination']),
    };
}

export function PcsListItemsToJSON(value?: PcsListItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items as Array<string>),
        'pagination': PaginationResultToJSON(value.pagination),
    };
}

