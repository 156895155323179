/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DurationUnit } from './DurationUnit';
import {
    DurationUnitFromJSON,
    DurationUnitFromJSONTyped,
    DurationUnitToJSON,
} from './DurationUnit';

/**
 * 
 * @export
 * @interface UpdateWorkflowRequest
 */
export interface UpdateWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowRequest
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowRequest
     */
    numberingPrefix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkflowRequest
     */
    includeNumberingYearInitiated?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkflowRequest
     */
    resetNumberingAfterYearEnd?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkflowRequest
     */
    startNumberingAt?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateWorkflowRequest
     */
    duration?: number;
    /**
     * 
     * @type {DurationUnit}
     * @memberof UpdateWorkflowRequest
     */
    durationUnit?: DurationUnit;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkflowRequest
     */
    durationInBusinessDays?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateWorkflowRequest
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateWorkflowRequest
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateWorkflowRequest
     */
    sites?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkflowRequest
     */
    allSites?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkflowRequest
     */
    requireValidation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowRequest
     */
    validationJustification?: string;
}

export function UpdateWorkflowRequestFromJSON(json: any): UpdateWorkflowRequest {
    return UpdateWorkflowRequestFromJSONTyped(json, false);
}

export function UpdateWorkflowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkflowRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'numberingPrefix': !exists(json, 'numberingPrefix') ? undefined : json['numberingPrefix'],
        'includeNumberingYearInitiated': !exists(json, 'includeNumberingYearInitiated') ? undefined : json['includeNumberingYearInitiated'],
        'resetNumberingAfterYearEnd': !exists(json, 'resetNumberingAfterYearEnd') ? undefined : json['resetNumberingAfterYearEnd'],
        'startNumberingAt': !exists(json, 'startNumberingAt') ? undefined : json['startNumberingAt'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationUnit': !exists(json, 'durationUnit') ? undefined : DurationUnitFromJSON(json['durationUnit']),
        'durationInBusinessDays': !exists(json, 'durationInBusinessDays') ? undefined : json['durationInBusinessDays'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'groupIds': !exists(json, 'groupIds') ? undefined : (json['groupIds'] as Array<string>),
        'sites': !exists(json, 'sites') ? undefined : (json['sites'] as Array<string>),
        'allSites': !exists(json, 'allSites') ? undefined : json['allSites'],
        'requireValidation': !exists(json, 'requireValidation') ? undefined : json['requireValidation'],
        'validationJustification': !exists(json, 'validationJustification') ? undefined : json['validationJustification'],
    };
}

export function UpdateWorkflowRequestToJSON(value?: UpdateWorkflowRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'type': value.type,
        'numberingPrefix': value.numberingPrefix,
        'includeNumberingYearInitiated': value.includeNumberingYearInitiated,
        'resetNumberingAfterYearEnd': value.resetNumberingAfterYearEnd,
        'startNumberingAt': value.startNumberingAt,
        'duration': value.duration,
        'durationUnit': DurationUnitToJSON(value.durationUnit),
        'durationInBusinessDays': value.durationInBusinessDays,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'groupIds': value.groupIds === undefined ? undefined : (value.groupIds as Array<string>),
        'sites': value.sites === undefined ? undefined : (value.sites as Array<string>),
        'allSites': value.allSites,
        'requireValidation': value.requireValidation,
        'validationJustification': value.validationJustification,
    };
}

