/* tslint:disable */
/* eslint-disable */
/**
 * Session Service OpenAPI Definition
 * Title
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenRefreshResult
 */
export interface TokenRefreshResult {
    /**
     * 
     * @type {string}
     * @memberof TokenRefreshResult
     */
    pcsSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRefreshResult
     */
    qxSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRefreshResult
     */
    accessToken?: string;
    /**
     * Number of seconds the session will remain active even if there is no new activity
     * @type {number}
     * @memberof TokenRefreshResult
     */
    ttl?: number;
}

export function TokenRefreshResultFromJSON(json: any): TokenRefreshResult {
    return TokenRefreshResultFromJSONTyped(json, false);
}

export function TokenRefreshResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenRefreshResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pcsSessionId': json['pcsSessionId'],
        'qxSessionId': !exists(json, 'qxSessionId') ? undefined : json['qxSessionId'],
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'ttl': !exists(json, 'ttl') ? undefined : json['ttl'],
    };
}

export function TokenRefreshResultToJSON(value?: TokenRefreshResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pcsSessionId': value.pcsSessionId,
        'qxSessionId': value.qxSessionId,
        'accessToken': value.accessToken,
        'ttl': value.ttl,
    };
}

