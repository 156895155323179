/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentMediaType } from './AttachmentMediaType';
import {
    AttachmentMediaTypeFromJSON,
    AttachmentMediaTypeFromJSONTyped,
    AttachmentMediaTypeToJSON,
} from './AttachmentMediaType';

/**
 * 
 * @export
 * @interface AttachFileRequest
 */
export interface AttachFileRequest {
    /**
     * 
     * @type {string}
     * @memberof AttachFileRequest
     */
    elementId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachFileRequest
     */
    recordId?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachFileRequest
     */
    uploadTime?: string;
    /**
     * 
     * @type {number}
     * @memberof AttachFileRequest
     */
    fileSize?: number;
    /**
     * 
     * @type {AttachmentMediaType}
     * @memberof AttachFileRequest
     */
    mediaType?: AttachmentMediaType;
    /**
     * 
     * @type {string}
     * @memberof AttachFileRequest
     */
    fileName?: string;
}

export function AttachFileRequestFromJSON(json: any): AttachFileRequest {
    return AttachFileRequestFromJSONTyped(json, false);
}

export function AttachFileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachFileRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elementId': !exists(json, 'elementId') ? undefined : json['elementId'],
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'uploadTime': !exists(json, 'uploadTime') ? undefined : json['uploadTime'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'mediaType': !exists(json, 'mediaType') ? undefined : AttachmentMediaTypeFromJSON(json['mediaType']),
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function AttachFileRequestToJSON(value?: AttachFileRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elementId': value.elementId,
        'recordId': value.recordId,
        'uploadTime': value.uploadTime,
        'fileSize': value.fileSize,
        'mediaType': AttachmentMediaTypeToJSON(value.mediaType),
        'fileName': value.fileName,
    };
}

