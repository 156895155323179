/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Record } from './Record';
import {
    RecordFromJSON,
    RecordFromJSONTyped,
    RecordToJSON,
} from './Record';
import type { RecordLink } from './RecordLink';
import {
    RecordLinkFromJSON,
    RecordLinkFromJSONTyped,
    RecordLinkToJSON,
} from './RecordLink';

/**
 * 
 * @export
 * @interface RecordLinkResponse
 */
export interface RecordLinkResponse {
    /**
     * 
     * @type {Array<Record>}
     * @memberof RecordLinkResponse
     */
    records?: Array<Record>;
    /**
     * 
     * @type {Array<RecordLink>}
     * @memberof RecordLinkResponse
     */
    recordLinks?: Array<RecordLink>;
}

export function RecordLinkResponseFromJSON(json: any): RecordLinkResponse {
    return RecordLinkResponseFromJSONTyped(json, false);
}

export function RecordLinkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordLinkResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<Record>).map(RecordFromJSON),
        'recordLinks': !exists(json, 'recordLinks') ? undefined : (json['recordLinks'] as Array<RecordLink>).map(RecordLinkFromJSON),
    };
}

export function RecordLinkResponseToJSON(value?: RecordLinkResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'records': value.records === undefined ? undefined : (value.records as Array<Record>).map(RecordToJSON),
        'recordLinks': value.recordLinks === undefined ? undefined : (value.recordLinks as Array<RecordLink>).map(RecordLinkToJSON),
    };
}

