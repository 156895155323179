/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';
import type { TableAllOf } from './TableAllOf';
import {
    TableAllOfFromJSON,
    TableAllOfFromJSONTyped,
    TableAllOfToJSON,
} from './TableAllOf';

/**
 * 
 * @export
 * @interface Table
 */
export interface Table extends FormElement {
    /**
     * 
     * @type {number}
     * @memberof Table
     */
    minRows?: number;
    /**
     * 
     * @type {number}
     * @memberof Table
     */
    maxRows?: number;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    rowLabel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Table
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    hintText?: string;
}

export function TableFromJSON(json: any): Table {
    return TableFromJSONTyped(json, false);
}

export function TableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Table {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'minRows': !exists(json, 'minRows') ? undefined : json['minRows'],
        'maxRows': !exists(json, 'maxRows') ? undefined : json['maxRows'],
        'rowLabel': !exists(json, 'rowLabel') ? undefined : json['rowLabel'],
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
    };
}

export function TableToJSON(value?: Table | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'minRows': value.minRows,
        'maxRows': value.maxRows,
        'rowLabel': value.rowLabel,
        'showHintText': value.showHintText,
        'hintText': value.hintText,
    };
}

