/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationResponseError } from './ValidationResponseError';
import {
    ValidationResponseErrorFromJSON,
    ValidationResponseErrorFromJSONTyped,
    ValidationResponseErrorToJSON,
} from './ValidationResponseError';

/**
 * 
 * @export
 * @interface ValidationResponse
 */
export interface ValidationResponse {
    /**
     * 
     * @type {Array<ValidationResponseError>}
     * @memberof ValidationResponse
     */
    errors?: Array<ValidationResponseError>;
}

export function ValidationResponseFromJSON(json: any): ValidationResponse {
    return ValidationResponseFromJSONTyped(json, false);
}

export function ValidationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] as Array<ValidationResponseError>).map(ValidationResponseErrorFromJSON),
    };
}

export function ValidationResponseToJSON(value?: ValidationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errors': value.errors === undefined ? undefined : (value.errors as Array<ValidationResponseError>).map(ValidationResponseErrorToJSON),
    };
}

