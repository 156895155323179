/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { Rule } from './Rule';
import {
    RuleFromJSON,
    RuleFromJSONTyped,
    RuleToJSON,
} from './Rule';
import type { Section } from './Section';
import {
    SectionFromJSON,
    SectionFromJSONTyped,
    SectionToJSON,
} from './Section';

/**
 * 
 * @export
 * @interface SideEffects
 */
export interface SideEffects {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof SideEffects
     */
    updatedNodeLayout?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<Section>}
     * @memberof SideEffects
     */
    updatedSections?: Array<Section>;
    /**
     * 
     * @type {Array<FormElement>}
     * @memberof SideEffects
     */
    updatedFormElements?: Array<FormElement>;
    /**
     * 
     * @type {Array<Rule>}
     * @memberof SideEffects
     */
    updatedRules?: Array<Rule>;
    /**
     * 
     * @type {Array<Rule>}
     * @memberof SideEffects
     */
    removedRules?: Array<Rule>;
}

export function SideEffectsFromJSON(json: any): SideEffects {
    return SideEffectsFromJSONTyped(json, false);
}

export function SideEffectsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SideEffects {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedNodeLayout': !exists(json, 'updatedNodeLayout') ? undefined : json['updatedNodeLayout'],
        'updatedSections': !exists(json, 'updatedSections') ? undefined : (json['updatedSections'] as Array<Section>).map(SectionFromJSON),
        'updatedFormElements': !exists(json, 'updatedFormElements') ? undefined : (json['updatedFormElements'] as Array<FormElement>).map(FormElementFromJSON),
        'updatedRules': !exists(json, 'updatedRules') ? undefined : (json['updatedRules'] as Array<Rule>).map(RuleFromJSON),
        'removedRules': !exists(json, 'removedRules') ? undefined : (json['removedRules'] as Array<Rule>).map(RuleFromJSON),
    };
}

export function SideEffectsToJSON(value?: SideEffects | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedNodeLayout': value.updatedNodeLayout,
        'updatedSections': value.updatedSections === undefined ? undefined : (value.updatedSections as Array<Section>).map(SectionToJSON),
        'updatedFormElements': value.updatedFormElements === undefined ? undefined : (value.updatedFormElements as Array<FormElement>).map(FormElementToJSON),
        'updatedRules': value.updatedRules === undefined ? undefined : (value.updatedRules as Array<Rule>).map(RuleToJSON),
        'removedRules': value.removedRules === undefined ? undefined : (value.removedRules as Array<Rule>).map(RuleToJSON),
    };
}

