/* tslint:disable */
/* eslint-disable */
/**
 * Task Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';
import type { Task } from './Task';
import {
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
} from './Task';

/**
 * A response to retrieve a list of tasks for a user.
 * @export
 * @interface GetUserTasksResponse
 */
export interface GetUserTasksResponse {
    /**
     * 
     * @type {Pagination}
     * @memberof GetUserTasksResponse
     */
    pagination: Pagination;
    /**
     * A list of tasks for the user.
     * @type {Array<Task>}
     * @memberof GetUserTasksResponse
     */
    tasks: Array<Task>;
}

export function GetUserTasksResponseFromJSON(json: any): GetUserTasksResponse {
    return GetUserTasksResponseFromJSONTyped(json, false);
}

export function GetUserTasksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserTasksResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': PaginationFromJSON(json['pagination']),
        'tasks': (json['tasks'] as Array<Task>).map(TaskFromJSON),
    };
}

export function GetUserTasksResponseToJSON(value?: GetUserTasksResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationToJSON(value.pagination),
        'tasks': (value.tasks as Array<Task>).map(TaskToJSON),
    };
}

