/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PcsListStatus } from './PcsListStatus';
import {
    PcsListStatusFromJSON,
    PcsListStatusFromJSONTyped,
    PcsListStatusToJSON,
} from './PcsListStatus';
import type { PcsListType } from './PcsListType';
import {
    PcsListTypeFromJSON,
    PcsListTypeFromJSONTyped,
    PcsListTypeToJSON,
} from './PcsListType';

/**
 * 
 * @export
 * @interface PcsListDetails
 */
export interface PcsListDetails {
    /**
     * 
     * @type {string}
     * @memberof PcsListDetails
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PcsListDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PcsListDetails
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PcsListDetails
     */
    itemCount?: number;
    /**
     * 
     * @type {PcsListStatus}
     * @memberof PcsListDetails
     */
    status?: PcsListStatus;
    /**
     * 
     * @type {PcsListType}
     * @memberof PcsListDetails
     */
    type?: PcsListType;
}

export function PcsListDetailsFromJSON(json: any): PcsListDetails {
    return PcsListDetailsFromJSONTyped(json, false);
}

export function PcsListDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PcsListDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'itemCount': !exists(json, 'itemCount') ? undefined : json['itemCount'],
        'status': !exists(json, 'status') ? undefined : PcsListStatusFromJSON(json['status']),
        'type': !exists(json, 'type') ? undefined : PcsListTypeFromJSON(json['type']),
    };
}

export function PcsListDetailsToJSON(value?: PcsListDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'itemCount': value.itemCount,
        'status': PcsListStatusToJSON(value.status),
        'type': PcsListTypeToJSON(value.type),
    };
}

