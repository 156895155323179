import { buildUriRegexCollection } from "@mc/common";
import { PreferencesKey } from "./exports";

export const ApiUris = {
    amx: {
        getRecord: "/pcs/amx-app-service/record/v1/{id}",
    },
    auditTrail: {
        /** @deprecated The endpoint to call getAuditableEvents is not longer available */
        getAuditableEvents: "/pcs/audit-trail/events/v1",
        getAuditHistoryEvents: "/pcs/audit-trail/history/v1/domain/{domain}/rootAggregateId/{rootAggregateId}",
        getAuditHistoryEventsByAssociatedAggregateId:
            "/pcs/audit-trail/history/v1/domain/{domain}/associatedAggregateId/{associatedAggregateId}",

        // FIXME: aqem BFF URIs should go into the AQEM shared folder apps\aqem\shared\src\uris.ts
        // eslint-disable-next-line sort-keys
        getAuditHistory: "/api/aqem/audit/v1/:domain/{rootAggregateId}/history",
        getAuditHistoryStats: "/api/aqem/audit/v1/:domain/{rootAggregateId}/stats",
        getS2SToken: "/pcs/audit-trail/v1/oauth2/token",
        postAuditableEvent: "/pcs/audit-trail/events/v1",
    },
    cache: {
        addGetAndDeleteFromCache: "/pcs/cache/v1/{productId}/{tenantId}/{cacheKey}",
        getS2SToken: "/pcs/cache/v1/oauth2/token",
    },
    dataMappings: {
        getDataMappedTargetWorkflows: "/pcs/workflow/mappings/v1/workflows/targets/{sourceWorkflowSeriesId}",
    },
    eSignature: {
        validateESig: "/pcs/qx-signature/v1/user/{qxDbUsername}/validate",
    },
    file: {
        deleteFile: "/pcs/file-service/file/v1",
        getFilesByFolderId: "/pcs/file-service/files/v1/folder/{folderId}",
        getFileScanStatus: "/pcs/file-service/file/v1/scan/status",
        mockPresignedUrl: "https://fakepresignedurl-quarantine.amazonaws.com",
        presignedFileUrl: "/pcs/file-service/presigned-url/v1",
    },
    identity: {
        license: {
            getLicensesForUser: "/pcs/identity/user/v1/licenses/{userId}",
            getProductsForTenant: "/pcs/identity/license/v2/products",
            listProductLicenseCount: "/pcs/identity/licenses/v1",
            putLogbooksLicense: "/pcs/identity/licenses/v1/Logbooks{userId}?licenseType={licenseType}",
            updateUserProductLicense: "/pcs/identity/license/v1/{product}/{userId}",
        },
        metadata: {
            get: "/pcs/identity/metadata/v1/{ids}",
        },
        user: {
            getAvatarUsers: "/pcs/identity/users/v1/avatar",
            getProfile: "/pcs/identity/user/v1/profile/{userId}",
            getQxDbUsernameFromOktaUserId: "/pcs/identity/user/v1/qxDbUsername/{oktaUserId}",
            getTenantUsers: "/pcs/identity/users/v1/tenant",
            getUserEmail: "/pcs/identity/users/v1/email",
            getUserIdFromQxDbUsername: "/api/logbooks/qx-identity/{qxDbUsername}",
            getUsers: "/pcs/identity/users/v1",
            searchUsersAndMetadata: "/pcs/identity/users/v2/search",
            searchUsersAndRoles: "/pcs/identity/users/v1/search",
            updateIsSysadmin: "/pcs/identity/user/v1/{userId}/sysadmin",
        },
        userMetadata: {
            create: "/pcs/identity/metadata/v1",
            criteriaUserMetadataSearch: "/pcs/identity/users/v1/metadata/action/criteria-search",
            findUsersByMetadata: "/pcs/identity/users/v1/metadata/action/search",
            get: "/pcs/identity/metadata/v1/{metadataId}",
            linkMetadataToUsers: "/pcs/identity/users/v1/metadata/action/link",
            listMetadata: "/pcs/identity/metadata/v1/key/{metadataKey}",
            listMetadataForKeyAndUsers: "/pcs/identity/users/v1/metadata/{metadataKey}/{userIds}",
            listUserIdsForMetadataIds: "/pcs/identity/users/v1/metadata/{metadataIds}",
            unlinkMetadataToUsers: "/pcs/identity/users/v1/metadata/action/unlink",
        },
    },
    insights: {
        login: "/pcs/insights/v1/login",
        logout: "/pcs/insights/v1/logout",
        validate: {
            email: "/pcs/insights/v1/validate/email",
        },
    },
    list: {
        v1: {
            checkUniqueName: "/pcs/list-service/pcs-list/v1/action/check-name",
            createPcsList: "/pcs/list-service/pcs-list/v1",
            getAllPcsLists: "/pcs/list-service/pcs-list/v1",
            getPcsList: "/pcs/list-service/pcs-list/v1/{id}",
            getPcsListsByIds: "/pcs/list-service/pcs-list/v1/lists",
            searchPcsLists: "/pcs/list-service/pcs-list/v1/action/search",
            updatePcsList: "/pcs/list-service/pcs-list/v1/{id}",
        },
        v2: {
            checkUniqueName: "/pcs/list-service/pcs-list/v2/action/check-name",
            createLargeList: "/pcs/list-service/pcs-list/v2/large",
            getAllPcsLists: "/pcs/list-service/pcs-list/v2",
            getPcsListItems: "/pcs/list-service/pcs-list/v2/{id}/items",
            getPcsListMetadata: "/pcs/list-service/pcs-list/v2/{id}",
            getPcsListsByIds: "/pcs/list-service/pcs-list/v2/lists",
            searchPcsListItems: "/pcs/list-service/pcs-list/v2/action/search/{id}",
            searchPcsLists: "/pcs/list-service/pcs-list/v2/action/search",
            updateLargeList: "/pcs/list-service/pcs-list/v2/{id}/large-list",
            updateSmallList: "/pcs/list-service/pcs-list/v2/{id}/small-list",
        },
    },
    notification: {
        createNotification: "/pcs/notification/notification/v1",
        emailUnsubscribe: "/pcs/notification/notification/v1/email/action/unsubscribe",
        getActiveReminders: "/pcs/notification/notification/v1/activeReminders",
        getNotification: "/pcs/notification/notification/v1/{notificationId}",
        getNotificationCount: "/pcs/notification/notification/v1/count",
        getUserNotifications: "/pcs/notification/notifications/v1",
        pollUpdates: "/pcs/notification/notification/v1/updates",
        resetActiveReminder: "/pcs/notification/notification/v1/{notificationId}/activeReminder/action/reset",
        setReminder: "/pcs/notification/notification/v1/{notificationId}/reminder",
        updateStatus: "/pcs/notification/notification/v1/{notificationId}/status",
        updateStatusForAllNotifications: "/pcs/notification/notification/v1/status",
    },
    okta: {
        authN: "/api/v1/authn",
        authorize: "oauth2/default/v1/authorize",
    },
    preferences: {
        availableOptionsByKey: "pcs/preferences/settings/{preferencesSettingKey}/options",
        languages: "pcs/preferences/languages",
        naviagion: "/pcs/preferences/navigation/v1",
        settings: "pcs/preferences/settings",
        settingsByKeyOptions: {
            [PreferencesKey.dateFormat]: `pcs/preferences/settings/${PreferencesKey.dateFormat}/options`,
        },
        settingsByKeyV2: "/pcs/preferences/settings/v2",
        settingsByKeyV2Options: {
            [PreferencesKey.uiDateTimeTimeZoneName]: `pcs/preferences/settings/v2/${PreferencesKey.uiDateTimeTimeZoneName}/options`,
        },
        settingsV2: "pcs/preferences/settings/v2",
        user: {
            preferredLanguage: "pcs/preferences/languages/me",
        },
    },
    publishing: {
        settings: "/pcs/publishing/v1/settings",
    },
    qxSignature: {
        validateEsig: "/pcs/qx-signature/v1/user/{qxDbUsername}/validate",
    },
    recordExecution: {
        record: {
            getLocksWithinRecord: "/pcs/record-execution/record/v1/{recordId}/locks",
            getNodeStatusLabels: "/pcs/record-execution/record/v1/{recordId}/node-status-label",
            getParticipantsOnRecord: "/pcs/record-execution/record/v1/{recordId}/participants",
            getRecordCollaborators: "/pcs/record-execution/record/v1/{recordId}/collaborators",
            getRecordCollectionV2: "/pcs/record-execution/record/v2/{parentRecordId}/collection",
            getRecordDetailByRecordId: "/pcs/record-execution/record/v1/{recordId}/detail",
            getRecordDetailByRecordIdV2: "/pcs/record-execution/record/v2/{recordId}/detail",
        },
    },
    search: {
        search: "/pcs/search/v1/search",
        searchV2: "/pcs/search/v2/search",
        searchV3: "/pcs/search/v3/search",
    },
    session: {
        delete: "/pcs/session/v1/pcs/{sessionId}",
    },
    signature: {
        createSignature: "/pcs/signature/signature/v1",
        getSignaturesByTargetId: "/pcs/signature/signature/v1/target/{id}",
        getSignaturesByTargetIds: "/pcs/signature/signature/v1/target",
        invalidateSignatures: "/pcs/signature/signature/v1/invalidate",
    },
    tag: {
        createTag: "/pcs/tag/v1/tag",
        createTagAndLink: "/pcs/tag/v1/link/createTagAndLink",
        deleteTag: "/pcs/tag/v1/tag/{tagId}",
        fuzzySearch: "/pcs/tag/v1/tags/fuzzy-search",
        getLinks: "/pcs/tag/v1/links",
        getLinksByTagId: "/pcs/tag/v1/tag/{tagId}/links",
        getTag: "/pcs/tag/v1/tag/{tagId}",
        getTagsByTargetIdQuery: "/pcs/tag/v1/tags/target/{targetId}",
        searchTagsByQuery: "/pcs/tag/v1/tags/search",
        updateTag: "/pcs/tag/v1/tag/{tagId}",
    },
    tenant: {
        tenantMetadata: "/pcs/tenant/v2/tenantMetadata/getByQxDatabaseName/{qxDatabaseName}",
    },
    validationOnDemand: {
        pcsApi: {
            v1: {
                getValidationOnDemandWorkflowV1ValidationStatusesGet:
                    "/pcs/validation-on-demand/workflow/v1/validation/statuses",
            },
        },
        pcsReport: {
            v1: {
                getValidationPlanIdReportLanguage:
                    "/pcs/validation-on-demand/pcs/validations/v1/{validationPlanId}/report/{language}",
            },
            v2: {
                getValidationPlanIdReportLanguage:
                    "/pcs/validation-on-demand/pcs/validations/v2/{validationPlanId}/report/{language}",
            },
        },
    },
};

export const ApiRegexUris = buildUriRegexCollection(ApiUris);
