/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Esignature } from './Esignature';
import {
    EsignatureFromJSON,
    EsignatureFromJSONTyped,
    EsignatureToJSON,
} from './Esignature';

/**
 * 
 * @export
 * @interface ActionItemDeletionRequestV2
 */
export interface ActionItemDeletionRequestV2 {
    /**
     * 
     * @type {Esignature}
     * @memberof ActionItemDeletionRequestV2
     */
    signature?: Esignature;
}

export function ActionItemDeletionRequestV2FromJSON(json: any): ActionItemDeletionRequestV2 {
    return ActionItemDeletionRequestV2FromJSONTyped(json, false);
}

export function ActionItemDeletionRequestV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionItemDeletionRequestV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signature': !exists(json, 'signature') ? undefined : EsignatureFromJSON(json['signature']),
    };
}

export function ActionItemDeletionRequestV2ToJSON(value?: ActionItemDeletionRequestV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signature': EsignatureToJSON(value.signature),
    };
}

