/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PcsListStatus } from './PcsListStatus';
import {
    PcsListStatusFromJSON,
    PcsListStatusFromJSONTyped,
    PcsListStatusToJSON,
} from './PcsListStatus';

/**
 * 
 * @export
 * @interface UpdatePcsListCommand
 */
export interface UpdatePcsListCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdatePcsListCommand
     */
    name?: string;
    /**
     * 
     * @type {PcsListStatus}
     * @memberof UpdatePcsListCommand
     */
    status?: PcsListStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdatePcsListCommand
     */
    description?: string;
    /**
     * each item within the pcs-list
     * @type {Array<string>}
     * @memberof UpdatePcsListCommand
     */
    items?: Array<string>;
}

export function UpdatePcsListCommandFromJSON(json: any): UpdatePcsListCommand {
    return UpdatePcsListCommandFromJSONTyped(json, false);
}

export function UpdatePcsListCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePcsListCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : PcsListStatusFromJSON(json['status']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<string>),
    };
}

export function UpdatePcsListCommandToJSON(value?: UpdatePcsListCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': PcsListStatusToJSON(value.status),
        'description': value.description,
        'items': value.items === undefined ? undefined : (value.items as Array<string>),
    };
}

