/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApprovalType {
    RecordApproval = 'RECORD_APPROVAL',
    NodeApproval = 'NODE_APPROVAL',
    Witness = 'WITNESS',
    Verify = 'VERIFY'
}

export function ApprovalTypeFromJSON(json: any): ApprovalType {
    return ApprovalTypeFromJSONTyped(json, false);
}

export function ApprovalTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprovalType {
    return json as ApprovalType;
}

export function ApprovalTypeToJSON(value?: ApprovalType | null): any {
    return value as any;
}

