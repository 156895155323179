/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NodeItemPriorityEnum } from './NodeItemPriorityEnum';
import {
    NodeItemPriorityEnumFromJSON,
    NodeItemPriorityEnumFromJSONTyped,
    NodeItemPriorityEnumToJSON,
} from './NodeItemPriorityEnum';

/**
 * 
 * @export
 * @interface CreateActionItemCommandRequest
 */
export interface CreateActionItemCommandRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateActionItemCommandRequest
     */
    nodeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateActionItemCommandRequest
     */
    workflowId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateActionItemCommandRequest
     */
    title: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateActionItemCommandRequest
     */
    assignees: Array<string>;
    /**
     * 
     * @type {NodeItemPriorityEnum}
     * @memberof CreateActionItemCommandRequest
     */
    priority?: NodeItemPriorityEnum;
    /**
     * 
     * @type {Date}
     * @memberof CreateActionItemCommandRequest
     */
    dueDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreateActionItemCommandRequest
     */
    blockStepSubmission?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateActionItemCommandRequest
     */
    instructions: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateActionItemCommandRequest
     */
    attachmentIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateActionItemCommandRequest
     */
    linkIds?: Array<string>;
}

export function CreateActionItemCommandRequestFromJSON(json: any): CreateActionItemCommandRequest {
    return CreateActionItemCommandRequestFromJSONTyped(json, false);
}

export function CreateActionItemCommandRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateActionItemCommandRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeId': json['nodeId'],
        'workflowId': json['workflowId'],
        'title': json['title'],
        'assignees': (json['assignees'] as Array<string>),
        'priority': !exists(json, 'priority') ? undefined : NodeItemPriorityEnumFromJSON(json['priority']),
        'dueDate': new Date(json['dueDate']),
        'blockStepSubmission': !exists(json, 'blockStepSubmission') ? undefined : json['blockStepSubmission'],
        'instructions': json['instructions'],
        'attachmentIds': !exists(json, 'attachmentIds') ? undefined : (json['attachmentIds'] as Array<string>),
        'linkIds': !exists(json, 'linkIds') ? undefined : (json['linkIds'] as Array<string>),
    };
}

export function CreateActionItemCommandRequestToJSON(value?: CreateActionItemCommandRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeId': value.nodeId,
        'workflowId': value.workflowId,
        'title': value.title,
        'assignees': (value.assignees as Array<string>),
        'priority': NodeItemPriorityEnumToJSON(value.priority),
        'dueDate': value.dueDate.toISOString(),
        'blockStepSubmission': value.blockStepSubmission,
        'instructions': value.instructions,
        'attachmentIds': value.attachmentIds === undefined ? undefined : (value.attachmentIds as Array<string>),
        'linkIds': value.linkIds === undefined ? undefined : (value.linkIds as Array<string>),
    };
}

