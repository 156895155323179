/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClaimTaskForUserCommandResponse,
    ClaimTaskForUserCommandResponseFromJSON,
    ClaimTaskForUserCommandResponseToJSON,
    DeleteTasksCommand,
    DeleteTasksCommandFromJSON,
    DeleteTasksCommandToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Esignature,
    EsignatureFromJSON,
    EsignatureToJSON,
    NextNodesResponseV2,
    NextNodesResponseV2FromJSON,
    NextNodesResponseV2ToJSON,
    ParticipantType,
    ParticipantTypeFromJSON,
    ParticipantTypeToJSON,
    RecordDetailView,
    RecordDetailViewFromJSON,
    RecordDetailViewToJSON,
    RecordDetailViewV2,
    RecordDetailViewV2FromJSON,
    RecordDetailViewV2ToJSON,
    RecordDetailViewV3,
    RecordDetailViewV3FromJSON,
    RecordDetailViewV3ToJSON,
    SignTaskForUserCommandResponse,
    SignTaskForUserCommandResponseFromJSON,
    SignTaskForUserCommandResponseToJSON,
    Task,
    TaskFromJSON,
    TaskToJSON,
    TaskCompletionRequest,
    TaskCompletionRequestFromJSON,
    TaskCompletionRequestToJSON,
    TaskElementDataResponse,
    TaskElementDataResponseFromJSON,
    TaskElementDataResponseToJSON,
    TaskListResponse,
    TaskListResponseFromJSON,
    TaskListResponseToJSON,
    TaskStatus,
    TaskStatusFromJSON,
    TaskStatusToJSON,
    ValidationResponse,
    ValidationResponseFromJSON,
    ValidationResponseToJSON,
} from '../models';

export interface ClaimTaskRequest {
    taskId: string;
}

export interface CloseTaskRequest {
    taskId: string;
}

export interface CompleteTaskRequest {
    taskId: string;
    pcsAppid?: string;
    pcsTenantid?: string;
    pcsUserid?: string;
    taskCompletionRequest?: TaskCompletionRequest;
}

export interface DeleteTasksByIdsRequest {
    deleteTasksCommand: DeleteTasksCommand;
}

export interface GetNextNodesV2Request {
    taskId: string;
}

export interface GetRecordDetailRequest {
    taskId: string;
}

export interface GetRecordDetailByTaskIdRequest {
    taskId: string;
}

export interface GetRecordDetailByTaskIdV3Request {
    taskId: string;
}

export interface GetTaskRequest {
    taskId: string;
}

export interface GetTaskFormElementsRequest {
    taskId: string;
}

export interface GetTasksV2Request {
    pageNumber: number;
    pageSize: number;
    participantTypes?: Array<ParticipantType>;
    statuses?: Array<TaskStatus>;
    nodeIds?: Array<string>;
    userIds?: Array<string>;
    dataTypes?: Array<GetTasksV2DataTypesEnum>;
}

export interface GetUserTasksRequest {
    pageNumber: number;
    pageSize: number;
    participantTypes?: Array<ParticipantType>;
}

export interface OpenNodeRequest {
    nodeId: string;
    pcsAppid?: string;
    pcsTenantid?: string;
    pcsUserid?: string;
}

export interface ResumeTasksRequest {
    recordId: string;
}

export interface SignTaskRequest {
    taskId: string;
    esignature: Esignature;
}

export interface ValidateTaskFormElementDataRequest {
    taskId: string;
}

/**
 * 
 */
export class TaskApi extends runtime.BaseAPI {

    /**
     * Claim a task that the user can work on. License required is Full or Basic.
     * Claim a task
     */
    async claimTaskRaw(requestParameters: ClaimTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClaimTaskForUserCommandResponse>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling claimTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}/action/claim`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaimTaskForUserCommandResponseFromJSON(jsonValue));
    }

    /**
     * Claim a task that the user can work on. License required is Full or Basic.
     * Claim a task
     */
    async claimTask(requestParameters: ClaimTaskRequest, initOverrides?: RequestInit): Promise<ClaimTaskForUserCommandResponse> {
        const response = await this.claimTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Since there is no possibility to remove rule participant, user can remove a participant by closing the task. License is Full or Basic
     * For permitted users to close task of a user
     */
    async closeTaskRaw(requestParameters: CloseTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling closeTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Since there is no possibility to remove rule participant, user can remove a participant by closing the task. License is Full or Basic
     * For permitted users to close task of a user
     */
    async closeTask(requestParameters: CloseTaskRequest, initOverrides?: RequestInit): Promise<void> {
        await this.closeTaskRaw(requestParameters, initOverrides);
    }

    /**
     * Given a task id, see if a task is completable and complete / open the next step(s) on the record. License is Full or Basic
     * Allow a user to request task completion for a task. Requestor can add notes.
     */
    async completeTaskRaw(requestParameters: CompleteTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling completeTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}/action/complete`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskCompletionRequestToJSON(requestParameters.taskCompletionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Given a task id, see if a task is completable and complete / open the next step(s) on the record. License is Full or Basic
     * Allow a user to request task completion for a task. Requestor can add notes.
     */
    async completeTask(requestParameters: CompleteTaskRequest, initOverrides?: RequestInit): Promise<void> {
        await this.completeTaskRaw(requestParameters, initOverrides);
    }

    /**
     * Supports deleting tasks by multiple ids
     * Delete tasks by ids
     */
    async deleteTasksByIdsRaw(requestParameters: DeleteTasksByIdsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteTasksCommand === null || requestParameters.deleteTasksCommand === undefined) {
            throw new runtime.RequiredError('deleteTasksCommand','Required parameter requestParameters.deleteTasksCommand was null or undefined when calling deleteTasksByIds.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteTasksCommandToJSON(requestParameters.deleteTasksCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supports deleting tasks by multiple ids
     * Delete tasks by ids
     */
    async deleteTasksByIds(requestParameters: DeleteTasksByIdsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteTasksByIdsRaw(requestParameters, initOverrides);
    }

    /**
     * Gets a list of next nodes. These nodes contain lists of users assigned to them. License required is Full or Basic.
     * Gets a list of next nodes. These nodes contain lists of users assigned to them.
     */
    async getNextNodesV2Raw(requestParameters: GetNextNodesV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NextNodesResponseV2>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getNextNodesV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v2/{taskId}/assignments/next`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NextNodesResponseV2FromJSON(jsonValue));
    }

    /**
     * Gets a list of next nodes. These nodes contain lists of users assigned to them. License required is Full or Basic.
     * Gets a list of next nodes. These nodes contain lists of users assigned to them.
     */
    async getNextNodesV2(requestParameters: GetNextNodesV2Request, initOverrides?: RequestInit): Promise<NextNodesResponseV2> {
        const response = await this.getNextNodesV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a summary of the record from the perspective of a single task - License is Full or Basic
     * Retrieves a summary of the record from the perspective of a single task
     */
    async getRecordDetailRaw(requestParameters: GetRecordDetailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordDetailView>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getRecordDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}/record/detail`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordDetailViewFromJSON(jsonValue));
    }

    /**
     * Retrieves a summary of the record from the perspective of a single task - License is Full or Basic
     * Retrieves a summary of the record from the perspective of a single task
     */
    async getRecordDetail(requestParameters: GetRecordDetailRequest, initOverrides?: RequestInit): Promise<RecordDetailView> {
        const response = await this.getRecordDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a summary of the record from the perspective of a single task - License is Full or Basic
     * Retrieves a summary of the record from the perspective of a single task
     */
    async getRecordDetailByTaskIdRaw(requestParameters: GetRecordDetailByTaskIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordDetailViewV2>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getRecordDetailByTaskId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v2/{taskId}/record/detail`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordDetailViewV2FromJSON(jsonValue));
    }

    /**
     * Retrieves a summary of the record from the perspective of a single task - License is Full or Basic
     * Retrieves a summary of the record from the perspective of a single task
     */
    async getRecordDetailByTaskId(requestParameters: GetRecordDetailByTaskIdRequest, initOverrides?: RequestInit): Promise<RecordDetailViewV2> {
        const response = await this.getRecordDetailByTaskIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a summary of the record from the perspective of a single task - License is Full or Basic
     * Retrieves a summary of the record from the perspective of a single task
     */
    async getRecordDetailByTaskIdV3Raw(requestParameters: GetRecordDetailByTaskIdV3Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordDetailViewV3>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getRecordDetailByTaskIdV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v3/{taskId}/record/detail`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordDetailViewV3FromJSON(jsonValue));
    }

    /**
     * Retrieves a summary of the record from the perspective of a single task - License is Full or Basic
     * Retrieves a summary of the record from the perspective of a single task
     */
    async getRecordDetailByTaskIdV3(requestParameters: GetRecordDetailByTaskIdV3Request, initOverrides?: RequestInit): Promise<RecordDetailViewV3> {
        const response = await this.getRecordDetailByTaskIdV3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a task by task id. License required is Full or Basic.
     * Get a task by task id
     */
    async getTaskRaw(requestParameters: GetTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     * Get a task by task id. License required is Full or Basic.
     * Get a task by task id
     */
    async getTask(requestParameters: GetTaskRequest, initOverrides?: RequestInit): Promise<Task> {
        const response = await this.getTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get data elements on a task. Sections are flattened; includes nested sections. If parentSectionId is null, it is a top level section. License required is Full or Basic.
     * Get data elements on a task
     */
    async getTaskFormElementsRaw(requestParameters: GetTaskFormElementsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TaskElementDataResponse>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getTaskFormElements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}/elements`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskElementDataResponseFromJSON(jsonValue));
    }

    /**
     * Get data elements on a task. Sections are flattened; includes nested sections. If parentSectionId is null, it is a top level section. License required is Full or Basic.
     * Get data elements on a task
     */
    async getTaskFormElements(requestParameters: GetTaskFormElementsRequest, initOverrides?: RequestInit): Promise<TaskElementDataResponse> {
        const response = await this.getTaskFormElementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries for tasks based on filters. License required is Full or Basic.
     * GET pcs/record-execution/task/v2/tasks?pageNumber={pageNumber}&pageSize={pageSize}
     */
    async getTasksV2Raw(requestParameters: GetTasksV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TaskListResponse>> {
        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getTasksV2.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getTasksV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.participantTypes) {
            queryParameters['participantTypes'] = requestParameters.participantTypes;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.nodeIds) {
            queryParameters['nodeIds'] = requestParameters.nodeIds;
        }

        if (requestParameters.userIds) {
            queryParameters['userIds'] = requestParameters.userIds;
        }

        if (requestParameters.dataTypes) {
            queryParameters['dataTypes'] = requestParameters.dataTypes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v2/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskListResponseFromJSON(jsonValue));
    }

    /**
     * Queries for tasks based on filters. License required is Full or Basic.
     * GET pcs/record-execution/task/v2/tasks?pageNumber={pageNumber}&pageSize={pageSize}
     */
    async getTasksV2(requestParameters: GetTasksV2Request, initOverrides?: RequestInit): Promise<TaskListResponse> {
        const response = await this.getTasksV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Queries for all OPEN, PENDING, UNCLAIMED, BLOCKED tasks. License required is Full or Basic.
     * GET pcs/record-execution/task/v1/tasks?pageNumber={pageNumber}&pageSize={pageSize}
     */
    async getUserTasksRaw(requestParameters: GetUserTasksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TaskListResponse>> {
        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getUserTasks.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getUserTasks.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.participantTypes) {
            queryParameters['participantTypes'] = requestParameters.participantTypes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskListResponseFromJSON(jsonValue));
    }

    /**
     * Queries for all OPEN, PENDING, UNCLAIMED, BLOCKED tasks. License required is Full or Basic.
     * GET pcs/record-execution/task/v1/tasks?pageNumber={pageNumber}&pageSize={pageSize}
     */
    async getUserTasks(requestParameters: GetUserTasksRequest, initOverrides?: RequestInit): Promise<TaskListResponse> {
        const response = await this.getUserTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Open a node that the user can work on, from a not-started state. License required is Full or Basic.
     * Open a node from a not-started state
     */
    async openNodeRaw(requestParameters: OpenNodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling openNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/action/open`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Open a node that the user can work on, from a not-started state. License required is Full or Basic.
     * Open a node from a not-started state
     */
    async openNode(requestParameters: OpenNodeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.openNodeRaw(requestParameters, initOverrides);
    }

    /**
     * Resume all closed_prematurely tasks on record. Record must be open state. License required is Full 
     * Resume closed_prematurely tasks
     */
    async resumeTasksRaw(requestParameters: ResumeTasksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling resumeTasks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{recordId}/action/resume`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resume all closed_prematurely tasks on record. Record must be open state. License required is Full 
     * Resume closed_prematurely tasks
     */
    async resumeTasks(requestParameters: ResumeTasksRequest, initOverrides?: RequestInit): Promise<void> {
        await this.resumeTasksRaw(requestParameters, initOverrides);
    }

    /**
     * Sign a task with the users electronic signature. License required is Full or Basic.
     * Sign a task
     */
    async signTaskRaw(requestParameters: SignTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SignTaskForUserCommandResponse>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling signTask.');
        }

        if (requestParameters.esignature === null || requestParameters.esignature === undefined) {
            throw new runtime.RequiredError('esignature','Required parameter requestParameters.esignature was null or undefined when calling signTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}/action/sign`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EsignatureToJSON(requestParameters.esignature),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignTaskForUserCommandResponseFromJSON(jsonValue));
    }

    /**
     * Sign a task with the users electronic signature. License required is Full or Basic.
     * Sign a task
     */
    async signTask(requestParameters: SignTaskRequest, initOverrides?: RequestInit): Promise<SignTaskForUserCommandResponse> {
        const response = await this.signTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get any errors when validating element data on a task. License required is Full or Basic.
     * Get any errors when validating element data on a task
     */
    async validateTaskFormElementDataRaw(requestParameters: ValidateTaskFormElementDataRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ValidationResponse>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling validateTaskFormElementData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}/elements/action/validate`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationResponseFromJSON(jsonValue));
    }

    /**
     * Get any errors when validating element data on a task. License required is Full or Basic.
     * Get any errors when validating element data on a task
     */
    async validateTaskFormElementData(requestParameters: ValidateTaskFormElementDataRequest, initOverrides?: RequestInit): Promise<ValidationResponse> {
        const response = await this.validateTaskFormElementDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetTasksV2DataTypesEnum {
    Validation = 'VALIDATION',
    Production = 'PRODUCTION'
}
