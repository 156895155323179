/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignTaskForUserCommandResponse
 */
export interface SignTaskForUserCommandResponse {
    /**
     * 
     * @type {string}
     * @memberof SignTaskForUserCommandResponse
     */
    esigId?: string;
}

export function SignTaskForUserCommandResponseFromJSON(json: any): SignTaskForUserCommandResponse {
    return SignTaskForUserCommandResponseFromJSONTyped(json, false);
}

export function SignTaskForUserCommandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignTaskForUserCommandResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'esigId': !exists(json, 'esigId') ? undefined : json['esigId'],
    };
}

export function SignTaskForUserCommandResponseToJSON(value?: SignTaskForUserCommandResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'esigId': value.esigId,
    };
}

