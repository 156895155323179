/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NodeStatus {
    Waiting = 'WAITING',
    Active = 'ACTIVE',
    Completed = 'COMPLETED',
    Skipped = 'SKIPPED',
    Na = 'NA'
}

export function NodeStatusFromJSON(json: any): NodeStatus {
    return NodeStatusFromJSONTyped(json, false);
}

export function NodeStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeStatus {
    return json as NodeStatus;
}

export function NodeStatusToJSON(value?: NodeStatus | null): any {
    return value as any;
}

