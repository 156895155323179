import { DateFnsLocale, LanguageCode, localStorageUserPreferencesLanguageKey } from "@mc/api";
import { ApplicationConfiguration } from "@mc/application-settings";
import { i18nFormatterFunctions } from "@mc/common";
import { setDefaultOptions } from "date-fns";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import resources from "virtual:i18next-loader";

async function loadTranslations(languageCode: string) {
    const defaultTranslations = { ...resources };

    if (languageCode === LanguageCode.English) {
        return defaultTranslations;
    }

    try {
        const translationResponse = await fetch(`/i18n/locales/${languageCode}/translation.json`);
        const translation = await translationResponse.json();

        return { ...defaultTranslations, [languageCode]: translation };
    } catch (error) {
        console.error(`Failed to load translations for ${languageCode}:`, error);

        return defaultTranslations;
    }
}

export async function initI18n(applicationConfig: ApplicationConfiguration) {
    const languageCode = localStorage.getItem(localStorageUserPreferencesLanguageKey) ?? LanguageCode.English;
    const translations = await loadTranslations(languageCode);

    setDefaultOptions({ locale: DateFnsLocale[languageCode] });

    await i18n.use(initReactI18next).init({
        debug: !applicationConfig.production,
        fallbackLng: LanguageCode.English,
        interpolation: {
            escapeValue: false, // react already escapes values to prevent xss
        },
        lng: localStorage.getItem(localStorageUserPreferencesLanguageKey) ?? LanguageCode.English,
        resources: translations,
    });

    i18nFormatterFunctions.map((func) => i18n.services.formatter?.add(func.name, func));
}
