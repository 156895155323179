/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SettingKey } from './SettingKey';
import {
    SettingKeyFromJSON,
    SettingKeyFromJSONTyped,
    SettingKeyToJSON,
} from './SettingKey';
import type { SettingLevel } from './SettingLevel';
import {
    SettingLevelFromJSON,
    SettingLevelFromJSONTyped,
    SettingLevelToJSON,
} from './SettingLevel';

/**
 * 
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {SettingKey}
     * @memberof Setting
     */
    key: SettingKey;
    /**
     * 
     * @type {SettingLevel}
     * @memberof Setting
     */
    level: SettingLevel;
    /**
     * 
     * @type {boolean}
     * @memberof Setting
     */
    override?: boolean;
    /**
     * 
     * @type {object}
     * @memberof Setting
     */
    value: object;
}

export function SettingFromJSON(json: any): Setting {
    return SettingFromJSONTyped(json, false);
}

export function SettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Setting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': SettingKeyFromJSON(json['key']),
        'level': SettingLevelFromJSON(json['level']),
        'override': !exists(json, 'override') ? undefined : json['override'],
        'value': json['value'],
    };
}

export function SettingToJSON(value?: Setting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': SettingKeyToJSON(value.key),
        'level': SettingLevelToJSON(value.level),
        'override': value.override,
        'value': value.value,
    };
}

