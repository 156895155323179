/* tslint:disable */
/* eslint-disable */
/**
 * Task Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Task } from './Task';
import {
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
} from './Task';

/**
 * A response to create tasks.
 * @export
 * @interface CreateTaskResponse
 */
export interface CreateTaskResponse {
    /**
     * 
     * @type {Task}
     * @memberof CreateTaskResponse
     */
    task: Task;
}

export function CreateTaskResponseFromJSON(json: any): CreateTaskResponse {
    return CreateTaskResponseFromJSONTyped(json, false);
}

export function CreateTaskResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTaskResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'task': TaskFromJSON(json['task']),
    };
}

export function CreateTaskResponseToJSON(value?: CreateTaskResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'task': TaskToJSON(value.task),
    };
}

