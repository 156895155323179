/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetDataMappedTargetWorkflowsResponseBody,
    GetDataMappedTargetWorkflowsResponseBodyFromJSON,
    GetDataMappedTargetWorkflowsResponseBodyToJSON,
} from '../models';

export interface GetDataMappedTargetWorkflowsRequest {
    sourceWorkflowSeriesId: string;
    mcApplicationId: string;
    pageSize?: number;
    pageNumber?: number;
    returnTotalResults?: boolean;
    pcsAppid?: string;
    pcsTenantid?: string;
    pcsUserid?: string;
}

/**
 * 
 */
export class DataMappingApi extends runtime.BaseAPI {

    /**
     * Gets a paginated list of workflows (technically workflow series) that are data-mapping targets of the given source workflow. A Full license is required.
     * Get workflows that are data-mapping targets of the given source workflow.
     */
    async getDataMappedTargetWorkflowsRaw(requestParameters: GetDataMappedTargetWorkflowsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDataMappedTargetWorkflowsResponseBody>> {
        if (requestParameters.sourceWorkflowSeriesId === null || requestParameters.sourceWorkflowSeriesId === undefined) {
            throw new runtime.RequiredError('sourceWorkflowSeriesId','Required parameter requestParameters.sourceWorkflowSeriesId was null or undefined when calling getDataMappedTargetWorkflows.');
        }

        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling getDataMappedTargetWorkflows.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.returnTotalResults !== undefined) {
            queryParameters['returnTotalResults'] = requestParameters.returnTotalResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["user", "admin"]);
        }

        const response = await this.request({
            path: `/pcs/workflow/mappings/v1/workflows/targets/{sourceWorkflowSeriesId}`.replace(`{${"sourceWorkflowSeriesId"}}`, encodeURIComponent(String(requestParameters.sourceWorkflowSeriesId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDataMappedTargetWorkflowsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Gets a paginated list of workflows (technically workflow series) that are data-mapping targets of the given source workflow. A Full license is required.
     * Get workflows that are data-mapping targets of the given source workflow.
     */
    async getDataMappedTargetWorkflows(requestParameters: GetDataMappedTargetWorkflowsRequest, initOverrides?: RequestInit): Promise<GetDataMappedTargetWorkflowsResponseBody> {
        const response = await this.getDataMappedTargetWorkflowsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
