/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enum to distinguish different types of Tasks.
 * @export
 * @enum {string}
 */
export enum NodeItemTypeEnum {
    Default = 'DEFAULT',
    Task = 'TASK',
    ActionItem = 'ACTION_ITEM',
    NodeApproval = 'NODE_APPROVAL'
}

export function NodeItemTypeEnumFromJSON(json: any): NodeItemTypeEnum {
    return NodeItemTypeEnumFromJSONTyped(json, false);
}

export function NodeItemTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeItemTypeEnum {
    return json as NodeItemTypeEnum;
}

export function NodeItemTypeEnumToJSON(value?: NodeItemTypeEnum | null): any {
    return value as any;
}

