/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';
import type { LongTextAllOf } from './LongTextAllOf';
import {
    LongTextAllOfFromJSON,
    LongTextAllOfFromJSONTyped,
    LongTextAllOfToJSON,
} from './LongTextAllOf';

/**
 * 
 * @export
 * @interface ShortText
 */
export interface ShortText extends FormElement {
    /**
     * 
     * @type {string}
     * @memberof ShortText
     */
    placeholder?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShortText
     */
    useCharacterLimit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShortText
     */
    characterLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShortText
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortText
     */
    hintText?: string;
}

export function ShortTextFromJSON(json: any): ShortText {
    return ShortTextFromJSONTyped(json, false);
}

export function ShortTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShortText {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'useCharacterLimit': !exists(json, 'useCharacterLimit') ? undefined : json['useCharacterLimit'],
        'characterLimit': !exists(json, 'characterLimit') ? undefined : json['characterLimit'],
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
    };
}

export function ShortTextToJSON(value?: ShortText | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'placeholder': value.placeholder,
        'useCharacterLimit': value.useCharacterLimit,
        'characterLimit': value.characterLimit,
        'showHintText': value.showHintText,
        'hintText': value.hintText,
    };
}

