/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PcsListStatus } from './PcsListStatus';
import {
    PcsListStatusFromJSON,
    PcsListStatusFromJSONTyped,
    PcsListStatusToJSON,
} from './PcsListStatus';
import type { PcsListType } from './PcsListType';
import {
    PcsListTypeFromJSON,
    PcsListTypeFromJSONTyped,
    PcsListTypeToJSON,
} from './PcsListType';
import type { UploadStatus } from './UploadStatus';
import {
    UploadStatusFromJSON,
    UploadStatusFromJSONTyped,
    UploadStatusToJSON,
} from './UploadStatus';

/**
 * 
 * @export
 * @interface PcsListDetailsV2
 */
export interface PcsListDetailsV2 {
    /**
     * 
     * @type {string}
     * @memberof PcsListDetailsV2
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PcsListDetailsV2
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PcsListDetailsV2
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PcsListDetailsV2
     */
    itemCount?: number;
    /**
     * 
     * @type {PcsListStatus}
     * @memberof PcsListDetailsV2
     */
    status?: PcsListStatus;
    /**
     * 
     * @type {UploadStatus}
     * @memberof PcsListDetailsV2
     */
    uploadStatus?: UploadStatus;
    /**
     * 
     * @type {PcsListType}
     * @memberof PcsListDetailsV2
     */
    type?: PcsListType;
}

export function PcsListDetailsV2FromJSON(json: any): PcsListDetailsV2 {
    return PcsListDetailsV2FromJSONTyped(json, false);
}

export function PcsListDetailsV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): PcsListDetailsV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'itemCount': !exists(json, 'itemCount') ? undefined : json['itemCount'],
        'status': !exists(json, 'status') ? undefined : PcsListStatusFromJSON(json['status']),
        'uploadStatus': !exists(json, 'uploadStatus') ? undefined : UploadStatusFromJSON(json['uploadStatus']),
        'type': !exists(json, 'type') ? undefined : PcsListTypeFromJSON(json['type']),
    };
}

export function PcsListDetailsV2ToJSON(value?: PcsListDetailsV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'itemCount': value.itemCount,
        'status': PcsListStatusToJSON(value.status),
        'uploadStatus': UploadStatusToJSON(value.uploadStatus),
        'type': PcsListTypeToJSON(value.type),
    };
}

