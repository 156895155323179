/* tslint:disable */
/* eslint-disable */
/**
 * Task Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationId } from './ApplicationId';
import {
    ApplicationIdFromJSON,
    ApplicationIdFromJSONTyped,
    ApplicationIdToJSON,
} from './ApplicationId';
import type { TaskOwner } from './TaskOwner';
import {
    TaskOwnerFromJSON,
    TaskOwnerFromJSONTyped,
    TaskOwnerToJSON,
} from './TaskOwner';

/**
 * A request to create tasks. One task will be created for each user.
 * @export
 * @interface CreateTaskRequest
 */
export interface CreateTaskRequest {
    /**
     * 
     * @type {ApplicationId}
     * @memberof CreateTaskRequest
     */
    appId: ApplicationId;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequest
     */
    aggregateId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequest
     */
    rootAggregateId?: string;
    /**
     * The type of task to create.
     * @type {string}
     * @memberof CreateTaskRequest
     */
    type: string;
    /**
     * The potential owners of a task
     * @type {Array<TaskOwner>}
     * @memberof CreateTaskRequest
     */
    potentialOwners?: Array<TaskOwner>;
    /**
     * Values associated with the task. The expected values you can find here depend on the task type. Note that schemas that end with `TaskValue` are schemas that are used here.
     * @type {object}
     * @memberof CreateTaskRequest
     */
    value: object;
}

export function CreateTaskRequestFromJSON(json: any): CreateTaskRequest {
    return CreateTaskRequestFromJSONTyped(json, false);
}

export function CreateTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTaskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appId': ApplicationIdFromJSON(json['appId']),
        'aggregateId': !exists(json, 'aggregateId') ? undefined : json['aggregateId'],
        'rootAggregateId': !exists(json, 'rootAggregateId') ? undefined : json['rootAggregateId'],
        'type': json['type'],
        'potentialOwners': !exists(json, 'potentialOwners') ? undefined : (json['potentialOwners'] as Array<TaskOwner>).map(TaskOwnerFromJSON),
        'value': json['value'],
    };
}

export function CreateTaskRequestToJSON(value?: CreateTaskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appId': ApplicationIdToJSON(value.appId),
        'aggregateId': value.aggregateId,
        'rootAggregateId': value.rootAggregateId,
        'type': value.type,
        'potentialOwners': value.potentialOwners === undefined ? undefined : (value.potentialOwners as Array<TaskOwner>).map(TaskOwnerToJSON),
        'value': value.value,
    };
}

