/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationErrorCodeKey } from './ValidationErrorCodeKey';
import {
    ValidationErrorCodeKeyFromJSON,
    ValidationErrorCodeKeyFromJSONTyped,
    ValidationErrorCodeKeyToJSON,
} from './ValidationErrorCodeKey';

/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    id?: string;
    /**
     * 
     * @type {Array<ValidationErrorCodeKey>}
     * @memberof ValidationError
     */
    validationErrorCodeKey?: Array<ValidationErrorCodeKey>;
}

export function ValidationErrorFromJSON(json: any): ValidationError {
    return ValidationErrorFromJSONTyped(json, false);
}

export function ValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'validationErrorCodeKey': !exists(json, 'validationErrorCodeKey') ? undefined : (json['validationErrorCodeKey'] as Array<ValidationErrorCodeKey>).map(ValidationErrorCodeKeyFromJSON),
    };
}

export function ValidationErrorToJSON(value?: ValidationError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'validationErrorCodeKey': value.validationErrorCodeKey === undefined ? undefined : (value.validationErrorCodeKey as Array<ValidationErrorCodeKey>).map(ValidationErrorCodeKeyToJSON),
    };
}

