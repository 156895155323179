/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorrectionCommandResponseV2
 */
export interface CorrectionCommandResponseV2 {
    /**
     * 
     * @type {string}
     * @memberof CorrectionCommandResponseV2
     */
    correctionId?: string;
}

export function CorrectionCommandResponseV2FromJSON(json: any): CorrectionCommandResponseV2 {
    return CorrectionCommandResponseV2FromJSONTyped(json, false);
}

export function CorrectionCommandResponseV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrectionCommandResponseV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'correctionId': !exists(json, 'correctionId') ? undefined : json['correctionId'],
    };
}

export function CorrectionCommandResponseV2ToJSON(value?: CorrectionCommandResponseV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'correctionId': value.correctionId,
    };
}

