/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PcsListStatus } from './PcsListStatus';
import {
    PcsListStatusFromJSON,
    PcsListStatusFromJSONTyped,
    PcsListStatusToJSON,
} from './PcsListStatus';
import type { PcsListType } from './PcsListType';
import {
    PcsListTypeFromJSON,
    PcsListTypeFromJSONTyped,
    PcsListTypeToJSON,
} from './PcsListType';

/**
 * 
 * @export
 * @interface PcsList
 */
export interface PcsList {
    /**
     * 
     * @type {string}
     * @memberof PcsList
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PcsList
     */
    name?: string;
    /**
     * 
     * @type {PcsListStatus}
     * @memberof PcsList
     */
    status?: PcsListStatus;
    /**
     * 
     * @type {string}
     * @memberof PcsList
     */
    description?: string;
    /**
     * each item within the pcs-list
     * @type {Array<string>}
     * @memberof PcsList
     */
    items?: Array<string>;
    /**
     * 
     * @type {PcsListType}
     * @memberof PcsList
     */
    type?: PcsListType;
    /**
     * 
     * @type {string}
     * @memberof PcsList
     */
    createdByUserId?: string;
    /**
     * Created date string in ISO 8601 format
     * @type {Date}
     * @memberof PcsList
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PcsList
     */
    lastModifiedUserId?: string;
    /**
     * Last modified date string in ISO 8601 format
     * @type {Date}
     * @memberof PcsList
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PcsList
     */
    originId?: string;
}

export function PcsListFromJSON(json: any): PcsList {
    return PcsListFromJSONTyped(json, false);
}

export function PcsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PcsList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : PcsListStatusFromJSON(json['status']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<string>),
        'type': !exists(json, 'type') ? undefined : PcsListTypeFromJSON(json['type']),
        'createdByUserId': !exists(json, 'createdByUserId') ? undefined : json['createdByUserId'],
        'createdDate': !exists(json, 'createdDate') || json['createdDate'] === '' ? undefined : new Date(json['createdDate']),
        'lastModifiedUserId': !exists(json, 'lastModifiedUserId') ? undefined : json['lastModifiedUserId'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') || json['lastModifiedDate'] === '' ? undefined : new Date(json['lastModifiedDate']),
        'originId': !exists(json, 'originId') ? undefined : json['originId'],
    };
}

export function PcsListToJSON(value?: PcsList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': PcsListStatusToJSON(value.status),
        'description': value.description,
        'items': value.items === undefined ? undefined : (value.items as Array<string>),
        'type': PcsListTypeToJSON(value.type),
        'createdByUserId': value.createdByUserId,
        'createdDate': value.createdDate === undefined ? undefined : value.createdDate.toISOString(),
        'lastModifiedUserId': value.lastModifiedUserId,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : value.lastModifiedDate.toISOString(),
        'originId': value.originId,
    };
}

