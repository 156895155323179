/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClaimNodeRequest
 */
export interface ClaimNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof ClaimNodeRequest
     */
    nodeId: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimNodeRequest
     */
    userId: string;
}

export function ClaimNodeRequestFromJSON(json: any): ClaimNodeRequest {
    return ClaimNodeRequestFromJSONTyped(json, false);
}

export function ClaimNodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimNodeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeId': json['nodeId'],
        'userId': json['userId'],
    };
}

export function ClaimNodeRequestToJSON(value?: ClaimNodeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeId': value.nodeId,
        'userId': value.userId,
    };
}

