/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PcsListStatus {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED'
}

export function PcsListStatusFromJSON(json: any): PcsListStatus {
    return PcsListStatusFromJSONTyped(json, false);
}

export function PcsListStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PcsListStatus {
    return json as PcsListStatus;
}

export function PcsListStatusToJSON(value?: PcsListStatus | null): any {
    return value as any;
}

