/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ActionItemStatusEnum {
    Open = 'OPEN',
    Closed = 'CLOSED',
    ClosedPrematurely = 'CLOSED_PREMATURELY',
    Deleted = 'DELETED'
}

export function ActionItemStatusEnumFromJSON(json: any): ActionItemStatusEnum {
    return ActionItemStatusEnumFromJSONTyped(json, false);
}

export function ActionItemStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionItemStatusEnum {
    return json as ActionItemStatusEnum;
}

export function ActionItemStatusEnumToJSON(value?: ActionItemStatusEnum | null): any {
    return value as any;
}

