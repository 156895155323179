/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionOperator } from './ConditionOperator';
import {
    ConditionOperatorFromJSON,
    ConditionOperatorFromJSONTyped,
    ConditionOperatorToJSON,
} from './ConditionOperator';
import type { LogicalOperator } from './LogicalOperator';
import {
    LogicalOperatorFromJSON,
    LogicalOperatorFromJSONTyped,
    LogicalOperatorToJSON,
} from './LogicalOperator';
import type { RuleOperand } from './RuleOperand';
import {
    RuleOperandFromJSON,
    RuleOperandFromJSONTyped,
    RuleOperandToJSON,
} from './RuleOperand';

/**
 * Rule Condition
 * @export
 * @interface RuleCondition
 */
export interface RuleCondition {
    /**
     * 
     * @type {string}
     * @memberof RuleCondition
     */
    id?: string;
    /**
     * 
     * @type {RuleOperand}
     * @memberof RuleCondition
     */
    leftOperand?: RuleOperand;
    /**
     * 
     * @type {RuleOperand}
     * @memberof RuleCondition
     */
    rightOperand?: RuleOperand;
    /**
     * 
     * @type {ConditionOperator}
     * @memberof RuleCondition
     */
    conditionOperator?: ConditionOperator;
    /**
     * 
     * @type {LogicalOperator}
     * @memberof RuleCondition
     */
    logicalOperator?: LogicalOperator;
    /**
     * 
     * @type {number}
     * @memberof RuleCondition
     */
    order?: number;
}

export function RuleConditionFromJSON(json: any): RuleCondition {
    return RuleConditionFromJSONTyped(json, false);
}

export function RuleConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'leftOperand': !exists(json, 'leftOperand') ? undefined : RuleOperandFromJSON(json['leftOperand']),
        'rightOperand': !exists(json, 'rightOperand') ? undefined : RuleOperandFromJSON(json['rightOperand']),
        'conditionOperator': !exists(json, 'conditionOperator') ? undefined : ConditionOperatorFromJSON(json['conditionOperator']),
        'logicalOperator': !exists(json, 'logicalOperator') ? undefined : LogicalOperatorFromJSON(json['logicalOperator']),
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function RuleConditionToJSON(value?: RuleCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'leftOperand': RuleOperandToJSON(value.leftOperand),
        'rightOperand': RuleOperandToJSON(value.rightOperand),
        'conditionOperator': ConditionOperatorToJSON(value.conditionOperator),
        'logicalOperator': LogicalOperatorToJSON(value.logicalOperator),
        'order': value.order,
    };
}

