/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionItemStatusEnum } from './ActionItemStatusEnum';
import {
    ActionItemStatusEnumFromJSON,
    ActionItemStatusEnumFromJSONTyped,
    ActionItemStatusEnumToJSON,
} from './ActionItemStatusEnum';

/**
 * 
 * @export
 * @interface ActionItem
 */
export interface ActionItem {
    /**
     * 
     * @type {string}
     * @memberof ActionItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ActionItem
     */
    initiatorUserId: string;
    /**
     * ID for the the node where this action item was launched from
     * @type {string}
     * @memberof ActionItem
     */
    parentNodeId: string;
    /**
     * Id for the action item record
     * @type {string}
     * @memberof ActionItem
     */
    recordId: string;
    /**
     * 
     * @type {ActionItemStatusEnum}
     * @memberof ActionItem
     */
    status?: ActionItemStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ActionItem
     */
    blockStepSubmission: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActionItem
     */
    instructions: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActionItem
     */
    attachmentIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActionItem
     */
    linkIds: Array<string>;
    /**
     * ID for the the record where this action item was launched from
     * @type {string}
     * @memberof ActionItem
     */
    parentRecordId: string;
}

export function ActionItemFromJSON(json: any): ActionItem {
    return ActionItemFromJSONTyped(json, false);
}

export function ActionItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'initiatorUserId': json['initiatorUserId'],
        'parentNodeId': json['parentNodeId'],
        'recordId': json['recordId'],
        'status': !exists(json, 'status') ? undefined : ActionItemStatusEnumFromJSON(json['status']),
        'blockStepSubmission': json['blockStepSubmission'],
        'instructions': json['instructions'],
        'attachmentIds': (json['attachmentIds'] as Array<string>),
        'linkIds': (json['linkIds'] as Array<string>),
        'parentRecordId': json['parentRecordId'],
    };
}

export function ActionItemToJSON(value?: ActionItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'initiatorUserId': value.initiatorUserId,
        'parentNodeId': value.parentNodeId,
        'recordId': value.recordId,
        'status': ActionItemStatusEnumToJSON(value.status),
        'blockStepSubmission': value.blockStepSubmission,
        'instructions': value.instructions,
        'attachmentIds': (value.attachmentIds as Array<string>),
        'linkIds': (value.linkIds as Array<string>),
        'parentRecordId': value.parentRecordId,
    };
}

