/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ValidationWarningKey {
    WorkflowLongestPathExceedsWorkflowDuration = 'workflow-longest-path-exceeds-workflow-duration',
    WorkflowPathExistsWithoutRulesOnConditionalSplit = 'workflow-path-exists-without-rules-on-conditional-split',
    WorkflowTagNotFound = 'workflow-tag-not-found',
    ApprovalApproverIsNodeAssignee = 'approval-approver-is-node-assignee'
}

export function ValidationWarningKeyFromJSON(json: any): ValidationWarningKey {
    return ValidationWarningKeyFromJSONTyped(json, false);
}

export function ValidationWarningKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationWarningKey {
    return json as ValidationWarningKey;
}

export function ValidationWarningKeyToJSON(value?: ValidationWarningKey | null): any {
    return value as any;
}

