/* tslint:disable */
/* eslint-disable */
/**
 * Validation on Demand OpenAPI Definition
 * Validation on Demand negotiated endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PcsValidationRequest
 */
export interface PcsValidationRequest {
    /**
     * 
     * @type {string}
     * @memberof PcsValidationRequest
     */
    recordName?: string;
    /**
     * 
     * @type {string}
     * @memberof PcsValidationRequest
     */
    workflowId?: string;
    /**
     * 
     * @type {string}
     * @memberof PcsValidationRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof PcsValidationRequest
     */
    uiURL?: string;
}

export function PcsValidationRequestFromJSON(json: any): PcsValidationRequest {
    return PcsValidationRequestFromJSONTyped(json, false);
}

export function PcsValidationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PcsValidationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordName': !exists(json, 'recordName') ? undefined : json['recordName'],
        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'uiURL': !exists(json, 'uiURL') ? undefined : json['uiURL'],
    };
}

export function PcsValidationRequestToJSON(value?: PcsValidationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordName': value.recordName,
        'workflowId': value.workflowId,
        'userId': value.userId,
        'uiURL': value.uiURL,
    };
}

