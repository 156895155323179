/* tslint:disable */
/* eslint-disable */
/**
 * Validation on Demand OpenAPI Definition
 * Validation on Demand negotiated endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogbooksValidationRequest
 */
export interface LogbooksValidationRequest {
    /**
     * 
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    instructions: string;
    /**
     * 
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    templateId: string;
    /**
     * 
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    owner?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LogbooksValidationRequest
     */
    assignees?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LogbooksValidationRequest
     */
    reviewer?: string;
    /**
     * 
     * @type {number}
     * @memberof LogbooksValidationRequest
     */
    numberOfRows?: number;
}

export function LogbooksValidationRequestFromJSON(json: any): LogbooksValidationRequest {
    return LogbooksValidationRequestFromJSONTyped(json, false);
}

export function LogbooksValidationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogbooksValidationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'instructions': json['instructions'],
        'templateId': json['templateId'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'assignees': !exists(json, 'assignees') ? undefined : (json['assignees'] as Array<string>),
        'reviewer': !exists(json, 'reviewer') ? undefined : json['reviewer'],
        'numberOfRows': !exists(json, 'numberOfRows') ? undefined : json['numberOfRows'],
    };
}

export function LogbooksValidationRequestToJSON(value?: LogbooksValidationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'instructions': value.instructions,
        'templateId': value.templateId,
        'owner': value.owner,
        'assignees': value.assignees === undefined ? undefined : (value.assignees as Array<string>),
        'reviewer': value.reviewer,
        'numberOfRows': value.numberOfRows,
    };
}

