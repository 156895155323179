/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CheckItemsResponse,
    CheckItemsResponseFromJSON,
    CheckItemsResponseToJSON,
    CheckNameResponse,
    CheckNameResponseFromJSON,
    CheckNameResponseToJSON,
    CheckPcsListItemsCommand,
    CheckPcsListItemsCommandFromJSON,
    CheckPcsListItemsCommandToJSON,
    CreateLargeListCommand,
    CreateLargeListCommandFromJSON,
    CreateLargeListCommandToJSON,
    CreatePcsListCommandV2,
    CreatePcsListCommandV2FromJSON,
    CreatePcsListCommandV2ToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MultiplePcsListResponseV2,
    MultiplePcsListResponseV2FromJSON,
    MultiplePcsListResponseV2ToJSON,
    PcsListItems,
    PcsListItemsFromJSON,
    PcsListItemsToJSON,
    PcsListQueryResponse,
    PcsListQueryResponseFromJSON,
    PcsListQueryResponseToJSON,
    PcsListQueryResponseV2,
    PcsListQueryResponseV2FromJSON,
    PcsListQueryResponseV2ToJSON,
    PcsListStatus,
    PcsListStatusFromJSON,
    PcsListStatusToJSON,
    PcsListType,
    PcsListTypeFromJSON,
    PcsListTypeToJSON,
    PcsListV2,
    PcsListV2FromJSON,
    PcsListV2ToJSON,
    PresignedUrlResponse,
    PresignedUrlResponseFromJSON,
    PresignedUrlResponseToJSON,
    UpdateLargePcsListCommandV2,
    UpdateLargePcsListCommandV2FromJSON,
    UpdateLargePcsListCommandV2ToJSON,
    UpdateSmallPcsListCommandV2,
    UpdateSmallPcsListCommandV2FromJSON,
    UpdateSmallPcsListCommandV2ToJSON,
    UploadStatus,
    UploadStatusFromJSON,
    UploadStatusToJSON,
} from '../models';

export interface CheckPcsListItemsV2Request {
    id: string;
    checkPcsListItemsCommand: CheckPcsListItemsCommand;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface CheckUniqueNameV2Request {
    name: string;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface CreateLargeListRequest {
    createLargeListCommand: CreateLargeListCommand;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface CreatePcsListV2Request {
    createPcsListCommandV2: CreatePcsListCommandV2;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface GetAllPcsListsV2Request {
    pageNumber: number;
    pageSize: number;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
    pcsListName?: string;
    pcsListStatus?: PcsListStatus;
    uploadStatus?: UploadStatus;
    pcsListType?: PcsListType;
}

export interface GetPcsListItemsRequest {
    id: string;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
    pageNumber?: number;
    pageSize?: number;
}

export interface GetPcsListMetadataRequest {
    id: string;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface GetPcsListsDetailsByIdsV2Request {
    ids: Array<string>;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface PresignedFileUrlRequest {
    fileKey: string;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface SearchPcsListItemsV2Request {
    id: string;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
    itemName?: string;
    pageNumber?: number;
    pageSize?: number;
}

export interface SearchPcsListsV2Request {
    mcApplicationId?: string;
    name?: string;
    pageNumber?: number;
    pageSize?: number;
    sortProperty?: SearchPcsListsV2SortPropertyEnum;
    sortDirection?: SearchPcsListsV2SortDirectionEnum;
}

export interface UpdateEntirePcsListForLessThan100ItemsRequest {
    id: string;
    updateSmallPcsListCommandV2: UpdateSmallPcsListCommandV2;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface UpdateEntirePcsListForMoreThan100ItemsRequest {
    id: string;
    updateLargePcsListCommandV2: UpdateLargePcsListCommandV2;
    mcApplicationId?: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

/**
 * 
 */
export class ListV2Api extends runtime.BaseAPI {

    /**
     * Checks if pcs-list contains items.
     */
    async checkPcsListItemsV2Raw(requestParameters: CheckPcsListItemsV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckItemsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling checkPcsListItemsV2.');
        }

        if (requestParameters.checkPcsListItemsCommand === null || requestParameters.checkPcsListItemsCommand === undefined) {
            throw new runtime.RequiredError('checkPcsListItemsCommand','Required parameter requestParameters.checkPcsListItemsCommand was null or undefined when calling checkPcsListItemsV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/{id}/action/check-items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckPcsListItemsCommandToJSON(requestParameters.checkPcsListItemsCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckItemsResponseFromJSON(jsonValue));
    }

    /**
     * Checks if pcs-list contains items.
     */
    async checkPcsListItemsV2(requestParameters: CheckPcsListItemsV2Request, initOverrides?: RequestInit): Promise<CheckItemsResponse> {
        const response = await this.checkPcsListItemsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks if the pcs-list name is unique across tenant
     * Check if pcs-list name is unique
     */
    async checkUniqueNameV2Raw(requestParameters: CheckUniqueNameV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckNameResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling checkUniqueNameV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/action/check-name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckNameResponseFromJSON(jsonValue));
    }

    /**
     * Checks if the pcs-list name is unique across tenant
     * Check if pcs-list name is unique
     */
    async checkUniqueNameV2(requestParameters: CheckUniqueNameV2Request, initOverrides?: RequestInit): Promise<CheckNameResponse> {
        const response = await this.checkUniqueNameV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create List over 100 items
     */
    async createLargeListRaw(requestParameters: CreateLargeListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListV2>> {
        if (requestParameters.createLargeListCommand === null || requestParameters.createLargeListCommand === undefined) {
            throw new runtime.RequiredError('createLargeListCommand','Required parameter requestParameters.createLargeListCommand was null or undefined when calling createLargeList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/large`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLargeListCommandToJSON(requestParameters.createLargeListCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListV2FromJSON(jsonValue));
    }

    /**
     * Create List over 100 items
     */
    async createLargeList(requestParameters: CreateLargeListRequest, initOverrides?: RequestInit): Promise<PcsListV2> {
        const response = await this.createLargeListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a pcs-list 100 items or less
     */
    async createPcsListV2Raw(requestParameters: CreatePcsListV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListV2>> {
        if (requestParameters.createPcsListCommandV2 === null || requestParameters.createPcsListCommandV2 === undefined) {
            throw new runtime.RequiredError('createPcsListCommandV2','Required parameter requestParameters.createPcsListCommandV2 was null or undefined when calling createPcsListV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePcsListCommandV2ToJSON(requestParameters.createPcsListCommandV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListV2FromJSON(jsonValue));
    }

    /**
     * Create a pcs-list 100 items or less
     */
    async createPcsListV2(requestParameters: CreatePcsListV2Request, initOverrides?: RequestInit): Promise<PcsListV2> {
        const response = await this.createPcsListV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all pcs-list details for an authenticated user or service by tenancy. Required license Full or Basic
     * Get all pcs-list details for an authenticated user or service by tenancy
     */
    async getAllPcsListsV2Raw(requestParameters: GetAllPcsListsV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListQueryResponseV2>> {
        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getAllPcsListsV2.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getAllPcsListsV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pcsListName !== undefined) {
            queryParameters['pcsListName'] = requestParameters.pcsListName;
        }

        if (requestParameters.pcsListStatus !== undefined) {
            queryParameters['pcsListStatus'] = requestParameters.pcsListStatus;
        }

        if (requestParameters.uploadStatus !== undefined) {
            queryParameters['uploadStatus'] = requestParameters.uploadStatus;
        }

        if (requestParameters.pcsListType !== undefined) {
            queryParameters['pcsListType'] = requestParameters.pcsListType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListQueryResponseV2FromJSON(jsonValue));
    }

    /**
     * Get all pcs-list details for an authenticated user or service by tenancy. Required license Full or Basic
     * Get all pcs-list details for an authenticated user or service by tenancy
     */
    async getAllPcsListsV2(requestParameters: GetAllPcsListsV2Request, initOverrides?: RequestInit): Promise<PcsListQueryResponseV2> {
        const response = await this.getAllPcsListsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list items for the specified pcs-list id
     */
    async getPcsListItemsRaw(requestParameters: GetPcsListItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListItems>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPcsListItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListItemsFromJSON(jsonValue));
    }

    /**
     * Get list items for the specified pcs-list id
     */
    async getPcsListItems(requestParameters: GetPcsListItemsRequest, initOverrides?: RequestInit): Promise<PcsListItems> {
        const response = await this.getPcsListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list metadata for the specified pcs-list id
     */
    async getPcsListMetadataRaw(requestParameters: GetPcsListMetadataRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListV2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPcsListMetadata.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListV2FromJSON(jsonValue));
    }

    /**
     * Get a list metadata for the specified pcs-list id
     */
    async getPcsListMetadata(requestParameters: GetPcsListMetadataRequest, initOverrides?: RequestInit): Promise<PcsListV2> {
        const response = await this.getPcsListMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get pcs-list details for the specified pcs-list ids.
     */
    async getPcsListsDetailsByIdsV2Raw(requestParameters: GetPcsListsDetailsByIdsV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MultiplePcsListResponseV2>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getPcsListsDetailsByIdsV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultiplePcsListResponseV2FromJSON(jsonValue));
    }

    /**
     * Get pcs-list details for the specified pcs-list ids.
     */
    async getPcsListsDetailsByIdsV2(requestParameters: GetPcsListsDetailsByIdsV2Request, initOverrides?: RequestInit): Promise<MultiplePcsListResponseV2> {
        const response = await this.getPcsListsDetailsByIdsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a presigned url with file key. License required is \'Full\', or \'Basic\'.
     */
    async presignedFileUrlRaw(requestParameters: PresignedFileUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PresignedUrlResponse>> {
        if (requestParameters.fileKey === null || requestParameters.fileKey === undefined) {
            throw new runtime.RequiredError('fileKey','Required parameter requestParameters.fileKey was null or undefined when calling presignedFileUrl.');
        }

        const queryParameters: any = {};

        if (requestParameters.fileKey !== undefined) {
            queryParameters['fileKey'] = requestParameters.fileKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/action/presigned-url`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PresignedUrlResponseFromJSON(jsonValue));
    }

    /**
     * Generates a presigned url with file key. License required is \'Full\', or \'Basic\'.
     */
    async presignedFileUrl(requestParameters: PresignedFileUrlRequest, initOverrides?: RequestInit): Promise<PresignedUrlResponse> {
        const response = await this.presignedFileUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search a pcs-list by the given item name.
     */
    async searchPcsListItemsV2Raw(requestParameters: SearchPcsListItemsV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListItems>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling searchPcsListItemsV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.itemName !== undefined) {
            queryParameters['itemName'] = requestParameters.itemName;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/action/search/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListItemsFromJSON(jsonValue));
    }

    /**
     * Search a pcs-list by the given item name.
     */
    async searchPcsListItemsV2(requestParameters: SearchPcsListItemsV2Request, initOverrides?: RequestInit): Promise<PcsListItems> {
        const response = await this.searchPcsListItemsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search pcs-lists for the specified filters.
     */
    async searchPcsListsV2Raw(requestParameters: SearchPcsListsV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListQueryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortProperty !== undefined) {
            queryParameters['sortProperty'] = requestParameters.sortProperty;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/action/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListQueryResponseFromJSON(jsonValue));
    }

    /**
     * Search pcs-lists for the specified filters.
     */
    async searchPcsListsV2(requestParameters: SearchPcsListsV2Request = {}, initOverrides?: RequestInit): Promise<PcsListQueryResponse> {
        const response = await this.searchPcsListsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a PcsList under 100 items
     */
    async updateEntirePcsListForLessThan100ItemsRaw(requestParameters: UpdateEntirePcsListForLessThan100ItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListV2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEntirePcsListForLessThan100Items.');
        }

        if (requestParameters.updateSmallPcsListCommandV2 === null || requestParameters.updateSmallPcsListCommandV2 === undefined) {
            throw new runtime.RequiredError('updateSmallPcsListCommandV2','Required parameter requestParameters.updateSmallPcsListCommandV2 was null or undefined when calling updateEntirePcsListForLessThan100Items.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/{id}/small-list`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSmallPcsListCommandV2ToJSON(requestParameters.updateSmallPcsListCommandV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListV2FromJSON(jsonValue));
    }

    /**
     * Update a PcsList under 100 items
     */
    async updateEntirePcsListForLessThan100Items(requestParameters: UpdateEntirePcsListForLessThan100ItemsRequest, initOverrides?: RequestInit): Promise<PcsListV2> {
        const response = await this.updateEntirePcsListForLessThan100ItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a PcsList over 100 items
     */
    async updateEntirePcsListForMoreThan100ItemsRaw(requestParameters: UpdateEntirePcsListForMoreThan100ItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PcsListV2>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateEntirePcsListForMoreThan100Items.');
        }

        if (requestParameters.updateLargePcsListCommandV2 === null || requestParameters.updateLargePcsListCommandV2 === undefined) {
            throw new runtime.RequiredError('updateLargePcsListCommandV2','Required parameter requestParameters.updateLargePcsListCommandV2 was null or undefined when calling updateEntirePcsListForMoreThan100Items.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/list-service/pcs-list/v2/{id}/large-list`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLargePcsListCommandV2ToJSON(requestParameters.updateLargePcsListCommandV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PcsListV2FromJSON(jsonValue));
    }

    /**
     * Update a PcsList over 100 items
     */
    async updateEntirePcsListForMoreThan100Items(requestParameters: UpdateEntirePcsListForMoreThan100ItemsRequest, initOverrides?: RequestInit): Promise<PcsListV2> {
        const response = await this.updateEntirePcsListForMoreThan100ItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SearchPcsListsV2SortPropertyEnum {
    Name = 'name',
    Status = 'status',
    Type = 'type',
    CreatedTimestamp = 'created_timestamp',
    LastModifiedTimestamp = 'last_modified_timestamp'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchPcsListsV2SortDirectionEnum {
    Asc = 'asc',
    Desc = 'desc'
}
