/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationErrorCode } from './ValidationErrorCode';
import {
    ValidationErrorCodeFromJSON,
    ValidationErrorCodeFromJSONTyped,
    ValidationErrorCodeToJSON,
} from './ValidationErrorCode';
import type { ValidationErrorKey } from './ValidationErrorKey';
import {
    ValidationErrorKeyFromJSON,
    ValidationErrorKeyFromJSONTyped,
    ValidationErrorKeyToJSON,
} from './ValidationErrorKey';

/**
 * 
 * @export
 * @interface ValidationErrorCodeKey
 */
export interface ValidationErrorCodeKey {
    /**
     * 
     * @type {ValidationErrorCode}
     * @memberof ValidationErrorCodeKey
     */
    code?: ValidationErrorCode;
    /**
     * 
     * @type {ValidationErrorKey}
     * @memberof ValidationErrorCodeKey
     */
    key?: ValidationErrorKey;
}

export function ValidationErrorCodeKeyFromJSON(json: any): ValidationErrorCodeKey {
    return ValidationErrorCodeKeyFromJSONTyped(json, false);
}

export function ValidationErrorCodeKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationErrorCodeKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : ValidationErrorCodeFromJSON(json['code']),
        'key': !exists(json, 'key') ? undefined : ValidationErrorKeyFromJSON(json['key']),
    };
}

export function ValidationErrorCodeKeyToJSON(value?: ValidationErrorCodeKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': ValidationErrorCodeToJSON(value.code),
        'key': ValidationErrorKeyToJSON(value.key),
    };
}

