/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';

/**
 * 
 * @export
 * @interface WorkflowState
 */
export interface WorkflowState {
    /**
     * 
     * @type {{ [key: string]: Node; }}
     * @memberof WorkflowState
     */
    lookup?: { [key: string]: Node; };
}

export function WorkflowStateFromJSON(json: any): WorkflowState {
    return WorkflowStateFromJSONTyped(json, false);
}

export function WorkflowStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lookup': !exists(json, 'lookup') ? undefined : mapValues(json['lookup'], NodeFromJSON),
    };
}

export function WorkflowStateToJSON(value?: WorkflowState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lookup': value.lookup === undefined ? undefined : mapValues(value.lookup, NodeToJSON),
    };
}

