/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SeriesRecord } from './SeriesRecord';
import {
    SeriesRecordFromJSON,
    SeriesRecordFromJSONTyped,
    SeriesRecordToJSON,
} from './SeriesRecord';

/**
 * 
 * @export
 * @interface SeriesRecordsResponse
 */
export interface SeriesRecordsResponse {
    /**
     * 
     * @type {string}
     * @memberof SeriesRecordsResponse
     */
    seriesId?: string;
    /**
     * 
     * @type {Array<SeriesRecord>}
     * @memberof SeriesRecordsResponse
     */
    seriesRecords?: Array<SeriesRecord>;
}

export function SeriesRecordsResponseFromJSON(json: any): SeriesRecordsResponse {
    return SeriesRecordsResponseFromJSONTyped(json, false);
}

export function SeriesRecordsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesRecordsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seriesId': !exists(json, 'seriesId') ? undefined : json['seriesId'],
        'seriesRecords': !exists(json, 'seriesRecords') ? undefined : (json['seriesRecords'] as Array<SeriesRecord>).map(SeriesRecordFromJSON),
    };
}

export function SeriesRecordsResponseToJSON(value?: SeriesRecordsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seriesId': value.seriesId,
        'seriesRecords': value.seriesRecords === undefined ? undefined : (value.seriesRecords as Array<SeriesRecord>).map(SeriesRecordToJSON),
    };
}

