/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FormElementLinkType {
    Record = 'RECORD',
    CqxDocs = 'CQX_DOCS'
}

export function FormElementLinkTypeFromJSON(json: any): FormElementLinkType {
    return FormElementLinkTypeFromJSONTyped(json, false);
}

export function FormElementLinkTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormElementLinkType {
    return json as FormElementLinkType;
}

export function FormElementLinkTypeToJSON(value?: FormElementLinkType | null): any {
    return value as any;
}

