/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkCQxDocumentRequest
 */
export interface LinkCQxDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkCQxDocumentRequest
     */
    toDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCQxDocumentRequest
     */
    fromElementId?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCQxDocumentRequest
     */
    fromRecordId?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCQxDocumentRequest
     */
    taskId?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCQxDocumentRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCQxDocumentRequest
     */
    revision?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCQxDocumentRequest
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCQxDocumentRequest
     */
    staticLink?: string;
}

export function LinkCQxDocumentRequestFromJSON(json: any): LinkCQxDocumentRequest {
    return LinkCQxDocumentRequestFromJSONTyped(json, false);
}

export function LinkCQxDocumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkCQxDocumentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'toDocumentId': !exists(json, 'toDocumentId') ? undefined : json['toDocumentId'],
        'fromElementId': !exists(json, 'fromElementId') ? undefined : json['fromElementId'],
        'fromRecordId': !exists(json, 'fromRecordId') ? undefined : json['fromRecordId'],
        'taskId': !exists(json, 'taskId') ? undefined : json['taskId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'revision': !exists(json, 'revision') ? undefined : json['revision'],
        'documentNumber': !exists(json, 'documentNumber') ? undefined : json['documentNumber'],
        'staticLink': !exists(json, 'staticLink') ? undefined : json['staticLink'],
    };
}

export function LinkCQxDocumentRequestToJSON(value?: LinkCQxDocumentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'toDocumentId': value.toDocumentId,
        'fromElementId': value.fromElementId,
        'fromRecordId': value.fromRecordId,
        'taskId': value.taskId,
        'title': value.title,
        'revision': value.revision,
        'documentNumber': value.documentNumber,
        'staticLink': value.staticLink,
    };
}

