/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PDFOptions
 */
export interface PDFOptions {
    /**
     * 
     * @type {boolean}
     * @memberof PDFOptions
     */
    includeListOfAttachments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PDFOptions
     */
    includeSignatureManifest?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PDFOptions
     */
    readySelector?: string;
    /**
     * 
     * @type {string}
     * @memberof PDFOptions
     */
    errorSelector?: string;
}

export function PDFOptionsFromJSON(json: any): PDFOptions {
    return PDFOptionsFromJSONTyped(json, false);
}

export function PDFOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PDFOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includeListOfAttachments': !exists(json, 'includeListOfAttachments') ? undefined : json['includeListOfAttachments'],
        'includeSignatureManifest': !exists(json, 'includeSignatureManifest') ? undefined : json['includeSignatureManifest'],
        'readySelector': !exists(json, 'readySelector') ? undefined : json['readySelector'],
        'errorSelector': !exists(json, 'errorSelector') ? undefined : json['errorSelector'],
    };
}

export function PDFOptionsToJSON(value?: PDFOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'includeListOfAttachments': value.includeListOfAttachments,
        'includeSignatureManifest': value.includeSignatureManifest,
        'readySelector': value.readySelector,
        'errorSelector': value.errorSelector,
    };
}

