/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ParticipantType,
    ParticipantTypeFromJSON,
    ParticipantTypeToJSON,
    RecordPreviewsResponse,
    RecordPreviewsResponseFromJSON,
    RecordPreviewsResponseToJSON,
} from '../models';

export interface GetCurrentUserRecordsRequest {
    pageNumber: number;
    pageSize: number;
    participantTypes?: Array<ParticipantType>;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Get records current User is a participant. The records can be filtered by desired ParticipantType and the result set is paginated via `pageNumber`/`pageSize` query params. The endpoint implies \"all types\", if no `participantType` filter provided. License required is Full or Basic. Currently only supports record owners.
     * GET pcs/record-execution/user/v1/records?pageNumber={pageNumber}&pageSize={pageSize}&participantTypes={participantTypes}
     */
    async getCurrentUserRecordsRaw(requestParameters: GetCurrentUserRecordsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordPreviewsResponse>> {
        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getCurrentUserRecords.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCurrentUserRecords.');
        }

        const queryParameters: any = {};

        if (requestParameters.participantTypes) {
            queryParameters['participantTypes'] = requestParameters.participantTypes;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/user/v1/records`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordPreviewsResponseFromJSON(jsonValue));
    }

    /**
     * Get records current User is a participant. The records can be filtered by desired ParticipantType and the result set is paginated via `pageNumber`/`pageSize` query params. The endpoint implies \"all types\", if no `participantType` filter provided. License required is Full or Basic. Currently only supports record owners.
     * GET pcs/record-execution/user/v1/records?pageNumber={pageNumber}&pageSize={pageSize}&participantTypes={participantTypes}
     */
    async getCurrentUserRecords(requestParameters: GetCurrentUserRecordsRequest, initOverrides?: RequestInit): Promise<RecordPreviewsResponse> {
        const response = await this.getCurrentUserRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
