/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersOnNodeResponse
 */
export interface UsersOnNodeResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof UsersOnNodeResponse
     */
    userIds?: Array<string>;
}

export function UsersOnNodeResponseFromJSON(json: any): UsersOnNodeResponse {
    return UsersOnNodeResponseFromJSONTyped(json, false);
}

export function UsersOnNodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersOnNodeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
    };
}

export function UsersOnNodeResponseToJSON(value?: UsersOnNodeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
    };
}

