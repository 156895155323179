import { LanguagesV2 } from "../../../generated/tenant";

export const LanguageNativeName = {
    [LanguagesV2.Chinese]: "中文 (简体)",
    [LanguagesV2.ChineseTraditional]: "中文 (繁體)",
    [LanguagesV2.English]: "English",
    [LanguagesV2.French]: "Français",
    [LanguagesV2.German]: "Deutsch",
    [LanguagesV2.Italian]: "Italiano",
    [LanguagesV2.Japanese]: "日本語",
    [LanguagesV2.Korean]: "한국어",
    [LanguagesV2.Polish]: "Polski",
    [LanguagesV2.Portuguese]: "Português",
    [LanguagesV2.Russian]: "Русский",
    [LanguagesV2.Spanish]: "Español",
    [LanguagesV2.Taiwanese]: "中国传统的）",
    [LanguagesV2.Vietnamese]: "Tiếng Việt",
} as Record<LanguagesV2, string>;

export type LanguageNativeNameKey = keyof typeof LanguageNativeName;
