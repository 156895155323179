/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PcsListDetails } from './PcsListDetails';
import {
    PcsListDetailsFromJSON,
    PcsListDetailsFromJSONTyped,
    PcsListDetailsToJSON,
} from './PcsListDetails';

/**
 * 
 * @export
 * @interface MultiplePcsListResponse
 */
export interface MultiplePcsListResponse {
    /**
     * 
     * @type {Array<PcsListDetails>}
     * @memberof MultiplePcsListResponse
     */
    pcsListDetailsList?: Array<PcsListDetails>;
}

export function MultiplePcsListResponseFromJSON(json: any): MultiplePcsListResponse {
    return MultiplePcsListResponseFromJSONTyped(json, false);
}

export function MultiplePcsListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiplePcsListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pcsListDetailsList': !exists(json, 'pcsListDetailsList') ? undefined : (json['pcsListDetailsList'] as Array<PcsListDetails>).map(PcsListDetailsFromJSON),
    };
}

export function MultiplePcsListResponseToJSON(value?: MultiplePcsListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pcsListDetailsList': value.pcsListDetailsList === undefined ? undefined : (value.pcsListDetailsList as Array<PcsListDetails>).map(PcsListDetailsToJSON),
    };
}

