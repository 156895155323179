/* tslint:disable */
/* eslint-disable */
/**
 * Validation on Demand OpenAPI Definition
 * Validation on Demand negotiated endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetValidationPlanIdByRecordIdResponse
 */
export interface GetValidationPlanIdByRecordIdResponse {
    /**
     * 
     * @type {string}
     * @memberof GetValidationPlanIdByRecordIdResponse
     */
    validationPlanId?: string;
}

export function GetValidationPlanIdByRecordIdResponseFromJSON(json: any): GetValidationPlanIdByRecordIdResponse {
    return GetValidationPlanIdByRecordIdResponseFromJSONTyped(json, false);
}

export function GetValidationPlanIdByRecordIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetValidationPlanIdByRecordIdResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validationPlanId': !exists(json, 'validationPlanId') ? undefined : json['validationPlanId'],
    };
}

export function GetValidationPlanIdByRecordIdResponseToJSON(value?: GetValidationPlanIdByRecordIdResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'validationPlanId': value.validationPlanId,
    };
}

