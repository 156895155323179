/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReopenChangeReason {
    AttachDocuments = 'ATTACH_DOCUMENTS',
    AddLinks = 'ADD_LINKS',
    FormRequiresCorrection = 'FORM_REQUIRES_CORRECTION',
    ResumeEvent = 'RESUME_EVENT',
    Other = 'OTHER'
}

export function ReopenChangeReasonFromJSON(json: any): ReopenChangeReason {
    return ReopenChangeReasonFromJSONTyped(json, false);
}

export function ReopenChangeReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReopenChangeReason {
    return json as ReopenChangeReason;
}

export function ReopenChangeReasonToJSON(value?: ReopenChangeReason | null): any {
    return value as any;
}

