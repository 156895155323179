/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PcsListDetailsV2 } from './PcsListDetailsV2';
import {
    PcsListDetailsV2FromJSON,
    PcsListDetailsV2FromJSONTyped,
    PcsListDetailsV2ToJSON,
} from './PcsListDetailsV2';

/**
 * 
 * @export
 * @interface MultiplePcsListResponseV2
 */
export interface MultiplePcsListResponseV2 {
    /**
     * 
     * @type {Array<PcsListDetailsV2>}
     * @memberof MultiplePcsListResponseV2
     */
    pcsListDetailsList?: Array<PcsListDetailsV2>;
}

export function MultiplePcsListResponseV2FromJSON(json: any): MultiplePcsListResponseV2 {
    return MultiplePcsListResponseV2FromJSONTyped(json, false);
}

export function MultiplePcsListResponseV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiplePcsListResponseV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pcsListDetailsList': !exists(json, 'pcsListDetailsList') ? undefined : (json['pcsListDetailsList'] as Array<PcsListDetailsV2>).map(PcsListDetailsV2FromJSON),
    };
}

export function MultiplePcsListResponseV2ToJSON(value?: MultiplePcsListResponseV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pcsListDetailsList': value.pcsListDetailsList === undefined ? undefined : (value.pcsListDetailsList as Array<PcsListDetailsV2>).map(PcsListDetailsV2ToJSON),
    };
}

