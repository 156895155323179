/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DueDateExtensionTargetTypeEnum {
    Node = 'NODE',
    ActionItem = 'ACTION_ITEM'
}

export function DueDateExtensionTargetTypeEnumFromJSON(json: any): DueDateExtensionTargetTypeEnum {
    return DueDateExtensionTargetTypeEnumFromJSONTyped(json, false);
}

export function DueDateExtensionTargetTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DueDateExtensionTargetTypeEnum {
    return json as DueDateExtensionTargetTypeEnum;
}

export function DueDateExtensionTargetTypeEnumToJSON(value?: DueDateExtensionTargetTypeEnum | null): any {
    return value as any;
}

