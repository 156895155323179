/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowType
 */
export interface WorkflowType {
    /**
     * 
     * @type {string}
     * @memberof WorkflowType
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowType
     */
    numberingPrefix?: string;
}

export function WorkflowTypeFromJSON(json: any): WorkflowType {
    return WorkflowTypeFromJSONTyped(json, false);
}

export function WorkflowTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'numberingPrefix': !exists(json, 'numberingPrefix') ? undefined : json['numberingPrefix'],
    };
}

export function WorkflowTypeToJSON(value?: WorkflowType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'numberingPrefix': value.numberingPrefix,
    };
}

