/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipantType } from './ParticipantType';
import {
    ParticipantTypeFromJSON,
    ParticipantTypeFromJSONTyped,
    ParticipantTypeToJSON,
} from './ParticipantType';

/**
 * 
 * @export
 * @interface UpdateParticipantRequest
 */
export interface UpdateParticipantRequest {
    /**
     * 
     * @type {ParticipantType}
     * @memberof UpdateParticipantRequest
     */
    participantType?: ParticipantType;
}

export function UpdateParticipantRequestFromJSON(json: any): UpdateParticipantRequest {
    return UpdateParticipantRequestFromJSONTyped(json, false);
}

export function UpdateParticipantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateParticipantRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'participantType': !exists(json, 'participantType') ? undefined : ParticipantTypeFromJSON(json['participantType']),
    };
}

export function UpdateParticipantRequestToJSON(value?: UpdateParticipantRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participantType': ParticipantTypeToJSON(value.participantType),
    };
}

