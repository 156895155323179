/* tslint:disable */
/* eslint-disable */
/**
 * Task Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The ID of an application.
 * @export
 * @enum {string}
 */
export enum ApplicationId {
    Adocs = 'adocs',
    Aqem = 'aqem',
    Amx = 'amx',
    Logbooks = 'logbooks'
}

export function ApplicationIdFromJSON(json: any): ApplicationId {
    return ApplicationIdFromJSONTyped(json, false);
}

export function ApplicationIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationId {
    return json as ApplicationId;
}

export function ApplicationIdToJSON(value?: ApplicationId | null): any {
    return value as any;
}

