/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UploadStatus {
    Staged = 'STAGED',
    Processing = 'PROCESSING',
    Completed = 'COMPLETED',
    Failed = 'FAILED'
}

export function UploadStatusFromJSON(json: any): UploadStatus {
    return UploadStatusFromJSONTyped(json, false);
}

export function UploadStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadStatus {
    return json as UploadStatus;
}

export function UploadStatusToJSON(value?: UploadStatus | null): any {
    return value as any;
}

