/* tslint:disable */
/* eslint-disable */
/**
 * Session Service OpenAPI Definition
 * Title
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthResult
 */
export interface AuthResult {
    /**
     * 
     * @type {string}
     * @memberof AuthResult
     */
    mcToken: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResult
     */
    pcsSessionId: string;
}

export function AuthResultFromJSON(json: any): AuthResult {
    return AuthResultFromJSONTyped(json, false);
}

export function AuthResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mcToken': json['mcToken'],
        'pcsSessionId': json['pcsSessionId'],
    };
}

export function AuthResultToJSON(value?: AuthResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mcToken': value.mcToken,
        'pcsSessionId': value.pcsSessionId,
    };
}

