/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentMediaType } from './AttachmentMediaType';
import {
    AttachmentMediaTypeFromJSON,
    AttachmentMediaTypeFromJSONTyped,
    AttachmentMediaTypeToJSON,
} from './AttachmentMediaType';

/**
 * 
 * @export
 * @interface CreateValidationReportRequest
 */
export interface CreateValidationReportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateValidationReportRequest
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateValidationReportRequest
     */
    fileSize?: number;
    /**
     * 
     * @type {AttachmentMediaType}
     * @memberof CreateValidationReportRequest
     */
    mediaType?: AttachmentMediaType;
}

export function CreateValidationReportRequestFromJSON(json: any): CreateValidationReportRequest {
    return CreateValidationReportRequestFromJSONTyped(json, false);
}

export function CreateValidationReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateValidationReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'mediaType': !exists(json, 'mediaType') ? undefined : AttachmentMediaTypeFromJSON(json['mediaType']),
    };
}

export function CreateValidationReportRequestToJSON(value?: CreateValidationReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': value.fileName,
        'fileSize': value.fileSize,
        'mediaType': AttachmentMediaTypeToJSON(value.mediaType),
    };
}

