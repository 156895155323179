/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateWorkflowVodRequest
 */
export interface ValidateWorkflowVodRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidateWorkflowVodRequest
     */
    recordName?: string;
}

export function ValidateWorkflowVodRequestFromJSON(json: any): ValidateWorkflowVodRequest {
    return ValidateWorkflowVodRequestFromJSONTyped(json, false);
}

export function ValidateWorkflowVodRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateWorkflowVodRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordName': !exists(json, 'recordName') ? undefined : json['recordName'],
    };
}

export function ValidateWorkflowVodRequestToJSON(value?: ValidateWorkflowVodRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordName': value.recordName,
    };
}

