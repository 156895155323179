/* tslint:disable */
/* eslint-disable */
/**
 * Session Service OpenAPI Definition
 * Title
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VodAuthStart
 */
export interface VodAuthStart {
    /**
     * The Qx tenant ID
     * @type {string}
     * @memberof VodAuthStart
     */
    qxTenantId?: string;
    /**
     * Duration of idle session timeout in seconds
     * @type {number}
     * @memberof VodAuthStart
     */
    ttl?: number;
    /**
     * The groups the user belongs to
     * @type {Array<string>}
     * @memberof VodAuthStart
     */
    groups?: Array<string>;
    /**
     * The user's ID
     * @type {string}
     * @memberof VodAuthStart
     */
    uid?: string;
    /**
     * The user's username
     * @type {string}
     * @memberof VodAuthStart
     */
    username?: string;
    /**
     * The ID of the app making the request
     * @type {string}
     * @memberof VodAuthStart
     */
    appId?: string;
    /**
     * The ID of the PCS session
     * @type {string}
     * @memberof VodAuthStart
     */
    pcsSessionId?: string;
    /**
     * The original user's ID used to call the impersonation endpoint
     * @type {string}
     * @memberof VodAuthStart
     */
    originalUserId?: string;
    /**
     * The user's ID, for compatibility with logbooks
     * @type {string}
     * @memberof VodAuthStart
     */
    userId?: string;
}

export function VodAuthStartFromJSON(json: any): VodAuthStart {
    return VodAuthStartFromJSONTyped(json, false);
}

export function VodAuthStartFromJSONTyped(json: any, ignoreDiscriminator: boolean): VodAuthStart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'qxTenantId': !exists(json, 'qxTenantId') ? undefined : json['qxTenantId'],
        'ttl': !exists(json, 'ttl') ? undefined : json['ttl'],
        'groups': !exists(json, 'groups') ? undefined : (json['groups'] as Array<string>),
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'appId': !exists(json, 'appId') ? undefined : json['appId'],
        'pcsSessionId': !exists(json, 'pcsSessionId') ? undefined : json['pcsSessionId'],
        'originalUserId': !exists(json, 'originalUserId') ? undefined : json['originalUserId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function VodAuthStartToJSON(value?: VodAuthStart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'qxTenantId': value.qxTenantId,
        'ttl': value.ttl,
        'groups': value.groups === undefined ? undefined : (value.groups as Array<string>),
        'uid': value.uid,
        'username': value.username,
        'appId': value.appId,
        'pcsSessionId': value.pcsSessionId,
        'originalUserId': value.originalUserId,
        'userId': value.userId,
    };
}

