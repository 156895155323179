/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApprovalType } from './ApprovalType';
import {
    ApprovalTypeFromJSON,
    ApprovalTypeFromJSONTyped,
    ApprovalTypeToJSON,
} from './ApprovalType';

/**
 * A settings object that can be used in a Node or Record context to specify what approvals (if any) are required. The record-execution service will receive these settings from record-configuration when creating a Record. The settings can be later updated if let's say another Approver is added to the Node/Record after the Record creation.
 * @export
 * @interface ApprovalSettings
 */
export interface ApprovalSettings {
    /**
     * 
     * @type {ApprovalType}
     * @memberof ApprovalSettings
     */
    approvalType?: ApprovalType;
    /**
     * 
     * @type {string}
     * @memberof ApprovalSettings
     */
    name?: string;
    /**
     * Instructions to be shown to the approvers.
     * @type {string}
     * @memberof ApprovalSettings
     */
    description?: string;
    /**
     * User IDs of the individual users from whom we should obtain approvals.
     * @type {Array<string>}
     * @memberof ApprovalSettings
     */
    userIds?: Array<string>;
    /**
     * Roles from which we should obtain approvals.
     * @type {Array<string>}
     * @memberof ApprovalSettings
     */
    roleIds?: Array<string>;
    /**
     * Is a rationale/explanation of the approval required.
     * @type {boolean}
     * @memberof ApprovalSettings
     */
    requireRationale?: boolean;
    /**
     * Is approval required.
     * @type {boolean}
     * @memberof ApprovalSettings
     */
    required: boolean;
}

export function ApprovalSettingsFromJSON(json: any): ApprovalSettings {
    return ApprovalSettingsFromJSONTyped(json, false);
}

export function ApprovalSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprovalSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approvalType': !exists(json, 'approvalType') ? undefined : ApprovalTypeFromJSON(json['approvalType']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'roleIds': !exists(json, 'roleIds') ? undefined : (json['roleIds'] as Array<string>),
        'requireRationale': !exists(json, 'requireRationale') ? undefined : json['requireRationale'],
        'required': json['required'],
    };
}

export function ApprovalSettingsToJSON(value?: ApprovalSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approvalType': ApprovalTypeToJSON(value.approvalType),
        'name': value.name,
        'description': value.description,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'roleIds': value.roleIds === undefined ? undefined : (value.roleIds as Array<string>),
        'requireRationale': value.requireRationale,
        'required': value.required,
    };
}

