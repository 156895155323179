import { applicationConfig } from "../application-configuration";
import { setupDataDogRUM } from "./data-dog-rum";
import { setupPendo } from "./pendo";
import { areThirdPartyScriptsDisabled } from "./third-party-scripts";

declare global {
    interface Window {
        dataLayer: Record<string, unknown>[];
    }
}

// If the config values have "replace-with-real-" in them, we know they haven't
// been replaced with real values (usually during integration test runs).
const hasFakeConfigValues = new RegExp("replace-with-real-");

export const setUpScriptProvider = () => {
    const isDisabled = areThirdPartyScriptsDisabled();
    if (applicationConfig && !isDisabled) {
        if (
            applicationConfig.datadog?.applicationId &&
            !hasFakeConfigValues.test(applicationConfig.datadog.applicationId)
        ) {
            setupDataDogRUM(applicationConfig);
        }
        if (applicationConfig?.pendo?.apiKey) {
            setupPendo(applicationConfig.pendo.apiKey);
        }
    }
};
