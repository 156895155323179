/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancelChangeReason } from './CancelChangeReason';
import {
    CancelChangeReasonFromJSON,
    CancelChangeReasonFromJSONTyped,
    CancelChangeReasonToJSON,
} from './CancelChangeReason';
import type { Esignature } from './Esignature';
import {
    EsignatureFromJSON,
    EsignatureFromJSONTyped,
    EsignatureToJSON,
} from './Esignature';

/**
 * When the reason is OTHER, comments are required.
 * @export
 * @interface DirectCancelRecordRequest
 */
export interface DirectCancelRecordRequest {
    /**
     * 
     * @type {CancelChangeReason}
     * @memberof DirectCancelRecordRequest
     */
    reason: CancelChangeReason;
    /**
     * 
     * @type {string}
     * @memberof DirectCancelRecordRequest
     */
    comments?: string;
    /**
     * 
     * @type {Esignature}
     * @memberof DirectCancelRecordRequest
     */
    signature: Esignature;
}

export function DirectCancelRecordRequestFromJSON(json: any): DirectCancelRecordRequest {
    return DirectCancelRecordRequestFromJSONTyped(json, false);
}

export function DirectCancelRecordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectCancelRecordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': CancelChangeReasonFromJSON(json['reason']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'signature': EsignatureFromJSON(json['signature']),
    };
}

export function DirectCancelRecordRequestToJSON(value?: DirectCancelRecordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': CancelChangeReasonToJSON(value.reason),
        'comments': value.comments,
        'signature': EsignatureToJSON(value.signature),
    };
}

