/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordCollaboratorsResponse
 */
export interface RecordCollaboratorsResponse {
    /**
     * 
     * @type {string}
     * @memberof RecordCollaboratorsResponse
     */
    recordOwnerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordCollaboratorsResponse
     */
    collaboratorUserIds?: Array<string>;
}

export function RecordCollaboratorsResponseFromJSON(json: any): RecordCollaboratorsResponse {
    return RecordCollaboratorsResponseFromJSONTyped(json, false);
}

export function RecordCollaboratorsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordCollaboratorsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordOwnerId': !exists(json, 'recordOwnerId') ? undefined : json['recordOwnerId'],
        'collaboratorUserIds': !exists(json, 'collaboratorUserIds') ? undefined : (json['collaboratorUserIds'] as Array<string>),
    };
}

export function RecordCollaboratorsResponseToJSON(value?: RecordCollaboratorsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordOwnerId': value.recordOwnerId,
        'collaboratorUserIds': value.collaboratorUserIds === undefined ? undefined : (value.collaboratorUserIds as Array<string>),
    };
}

