/* tslint:disable */
/* eslint-disable */
/**
 * Session Service OpenAPI Definition
 * Title
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionDetail
 */
export interface SessionDetail {
    /**
     * Qx JSession Id
     * @type {string}
     * @memberof SessionDetail
     */
    sessionId: string;
    /**
     * milliseconds since epoch
     * @type {number}
     * @memberof SessionDetail
     */
    lastActivity: number;
}

export function SessionDetailFromJSON(json: any): SessionDetail {
    return SessionDetailFromJSONTyped(json, false);
}

export function SessionDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'lastActivity': json['lastActivity'],
    };
}

export function SessionDetailToJSON(value?: SessionDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'lastActivity': value.lastActivity,
    };
}

