import { nonce } from "..";
import { hasFeature } from "../feature-flags";
import { applicationSettingsStore } from "../store";
type WindowWithPendo = Window &
    typeof globalThis & {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pendo?: any;
    };

export function setupPendo(apiKey: string) {
    const isPendoEnabled = hasFeature("commonPendoEnabled");
    if (isPendoEnabled) {
        (function (window: WindowWithPendo, document: Document) {
            // Load pendo
            const pendo = (window.pendo = window.pendo || {});
            pendo._q = pendo._q || [];

            const operations = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
            for (let w = 0, x = operations.length; w < x; ++w) {
                (function (m) {
                    pendo[m] =
                        pendo[m] ||
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        function (...rest: any[]) {
                            pendo._q[m === operations[0] ? "unshift" : "push"]([m].concat([].slice.call(rest, 0)));
                        };
                })(operations[w]);
            }

            const scriptTag = document.createElement("script");
            scriptTag.async = !0;
            scriptTag.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
            scriptTag.nonce = nonce;

            const firstScriptTag = document.getElementsByTagName("script")[0] as HTMLScriptElement;
            (firstScriptTag.parentNode as ParentNode).insertBefore(scriptTag, firstScriptTag);

            // Listen for changes to application settings. These will include authentication changes
            applicationSettingsStore.subscribe((store) => {
                if (store.accessToken) {
                    let count = 0;
                    const tenantId = store.claims?.tenantId;
                    const tenantName = store.claims?.tenantName;
                    const userId = store.claims?.uid;
                    // Check to see if pendo is loaded. Wait up to 5 seconds
                    const loadPendoInterval = setInterval(function () {
                        function isPendoLoaded() {
                            return (
                                !!(window as WindowWithPendo).pendo &&
                                !(window as WindowWithPendo).pendo.mcIsInitialized
                            );
                        }
                        function canIntervalBeCleared() {
                            return count++ >= 5 || isPendoLoaded();
                        }
                        // Initialize pendo with information about the user.
                        if (isPendoLoaded()) {
                            (window as WindowWithPendo).pendo.initialize({
                                account: {
                                    greenfield: true,
                                    id: tenantName || tenantId,
                                    name: tenantId,
                                },
                                visitor: {
                                    id: userId,
                                },
                            });
                            (window as WindowWithPendo).pendo.mcIsInitialized = true;
                        }
                        if (canIntervalBeCleared()) {
                            window.clearInterval(loadPendoInterval);
                        }
                    }, 1000);
                }
            });
        })(window, document);
    }
}
