/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnattachFileRequest
 */
export interface UnattachFileRequest {
    /**
     * 
     * @type {string}
     * @memberof UnattachFileRequest
     */
    attachmentId?: string;
}

export function UnattachFileRequestFromJSON(json: any): UnattachFileRequest {
    return UnattachFileRequestFromJSONTyped(json, false);
}

export function UnattachFileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnattachFileRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachmentId': !exists(json, 'attachmentId') ? undefined : json['attachmentId'],
    };
}

export function UnattachFileRequestToJSON(value?: UnattachFileRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachmentId': value.attachmentId,
    };
}

