/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecutionPlanElement
 */
export interface ExecutionPlanElement {
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanElement
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanElement
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanElement
     */
    elementConfigurationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanElement
     */
    sectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanElement
     */
    executionPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanElement
     */
    parentElementId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanElement
     */
    placeholder?: string;
    /**
     * 
     * @type {number}
     * @memberof ExecutionPlanElement
     */
    displayOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanElement
     */
    elementType?: ExecutionPlanElementElementTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanElement
     */
    required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanElement
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanElement
     */
    disabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanElement
     */
    allowNotApplicable?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecutionPlanElement
     */
    ruleIds?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ExecutionPlanElement
     */
    settings?: { [key: string]: object; };
}

/**
* @export
* @enum {string}
*/
export enum ExecutionPlanElementElementTypeEnum {
    ShortText = 'SHORT_TEXT',
    LongText = 'LONG_TEXT',
    Number = 'NUMBER',
    DateTime = 'DATE_TIME',
    DateTimeCapture = 'DATE_TIME_CAPTURE',
    Dropdown = 'DROPDOWN',
    SingleChoice = 'SINGLE_CHOICE',
    MultipleChoice = 'MULTIPLE_CHOICE',
    Attachment = 'ATTACHMENT',
    Advanced = 'ADVANCED',
    RecordLink = 'RECORD_LINK',
    Toggle = 'TOGGLE',
    Table = 'TABLE',
    Link = 'LINK'
}

export function ExecutionPlanElementFromJSON(json: any): ExecutionPlanElement {
    return ExecutionPlanElementFromJSONTyped(json, false);
}

export function ExecutionPlanElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionPlanElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'elementConfigurationId': !exists(json, 'elementConfigurationId') ? undefined : json['elementConfigurationId'],
        'sectionId': !exists(json, 'sectionId') ? undefined : json['sectionId'],
        'executionPlanId': !exists(json, 'executionPlanId') ? undefined : json['executionPlanId'],
        'parentElementId': !exists(json, 'parentElementId') ? undefined : json['parentElementId'],
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'displayOrder': !exists(json, 'displayOrder') ? undefined : json['displayOrder'],
        'elementType': !exists(json, 'elementType') ? undefined : json['elementType'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'allowNotApplicable': !exists(json, 'allowNotApplicable') ? undefined : json['allowNotApplicable'],
        'ruleIds': !exists(json, 'ruleIds') ? undefined : (json['ruleIds'] as Array<string>),
        'settings': !exists(json, 'settings') ? undefined : json['settings'],
    };
}

export function ExecutionPlanElementToJSON(value?: ExecutionPlanElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'elementConfigurationId': value.elementConfigurationId,
        'sectionId': value.sectionId,
        'executionPlanId': value.executionPlanId,
        'parentElementId': value.parentElementId,
        'placeholder': value.placeholder,
        'displayOrder': value.displayOrder,
        'elementType': value.elementType,
        'required': value.required,
        'hidden': value.hidden,
        'disabled': value.disabled,
        'allowNotApplicable': value.allowNotApplicable,
        'ruleIds': value.ruleIds === undefined ? undefined : (value.ruleIds as Array<string>),
        'settings': value.settings,
    };
}

