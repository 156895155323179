/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StatusLabelEnum {
    AwaitingCorrection = 'AWAITING_CORRECTION',
    AwaitingActionItem = 'AWAITING_ACTION_ITEM',
    AwaitingAiAndCorrection = 'AWAITING_AI_AND_CORRECTION',
    NotStarted = 'NOT_STARTED',
    InProgress = 'IN_PROGRESS',
    ApprovalNotStarted = 'APPROVAL_NOT_STARTED',
    ApprovalInProgress = 'APPROVAL_IN_PROGRESS',
    Upcoming = 'UPCOMING',
    Incomplete = 'INCOMPLETE',
    Skipped = 'SKIPPED',
    Complete = 'COMPLETE'
}

export function StatusLabelEnumFromJSON(json: any): StatusLabelEnum {
    return StatusLabelEnumFromJSONTyped(json, false);
}

export function StatusLabelEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusLabelEnum {
    return json as StatusLabelEnum;
}

export function StatusLabelEnumToJSON(value?: StatusLabelEnum | null): any {
    return value as any;
}

