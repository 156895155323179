/* tslint:disable */
/* eslint-disable */
/**
 * Validation on Demand OpenAPI Definition
 * Validation on Demand negotiated endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PCSValidationPlanStatusResponse
 */
export interface PCSValidationPlanStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof PCSValidationPlanStatusResponse
     */
    validationPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof PCSValidationPlanStatusResponse
     */
    planStatus?: PCSValidationPlanStatusResponsePlanStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PCSValidationPlanStatusResponse
     */
    reportName?: string;
    /**
     * 
     * @type {string}
     * @memberof PCSValidationPlanStatusResponse
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PCSValidationPlanStatusResponse
     */
    initiatedUserId?: string;
}

/**
* @export
* @enum {string}
*/
export enum PCSValidationPlanStatusResponsePlanStatusEnum {
    Executing = 'EXECUTING',
    Passed = 'PASSED',
    Failed = 'FAILED'
}

export function PCSValidationPlanStatusResponseFromJSON(json: any): PCSValidationPlanStatusResponse {
    return PCSValidationPlanStatusResponseFromJSONTyped(json, false);
}

export function PCSValidationPlanStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PCSValidationPlanStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validationPlanId': !exists(json, 'validationPlanId') ? undefined : json['validationPlanId'],
        'planStatus': !exists(json, 'planStatus') ? undefined : json['planStatus'],
        'reportName': !exists(json, 'reportName') ? undefined : json['reportName'],
        'createdDate': !exists(json, 'createdDate') ? undefined : json['createdDate'],
        'initiatedUserId': !exists(json, 'initiatedUserId') ? undefined : json['initiatedUserId'],
    };
}

export function PCSValidationPlanStatusResponseToJSON(value?: PCSValidationPlanStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'validationPlanId': value.validationPlanId,
        'planStatus': value.planStatus,
        'reportName': value.reportName,
        'createdDate': value.createdDate,
        'initiatedUserId': value.initiatedUserId,
    };
}

