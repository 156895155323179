/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkflowType } from './WorkflowType';
import {
    WorkflowTypeFromJSON,
    WorkflowTypeFromJSONTyped,
    WorkflowTypeToJSON,
} from './WorkflowType';

/**
 * 
 * @export
 * @interface GetWorkflowTypesResponse
 */
export interface GetWorkflowTypesResponse {
    /**
     * 
     * @type {Array<WorkflowType>}
     * @memberof GetWorkflowTypesResponse
     */
    workflowTypes: Array<WorkflowType>;
}

export function GetWorkflowTypesResponseFromJSON(json: any): GetWorkflowTypesResponse {
    return GetWorkflowTypesResponseFromJSONTyped(json, false);
}

export function GetWorkflowTypesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWorkflowTypesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflowTypes': (json['workflowTypes'] as Array<WorkflowType>).map(WorkflowTypeFromJSON),
    };
}

export function GetWorkflowTypesResponseToJSON(value?: GetWorkflowTypesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflowTypes': (value.workflowTypes as Array<WorkflowType>).map(WorkflowTypeToJSON),
    };
}

