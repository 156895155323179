/* tslint:disable */
/* eslint-disable */
/**
 * Task Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationId } from './ApplicationId';
import {
    ApplicationIdFromJSON,
    ApplicationIdFromJSONTyped,
    ApplicationIdToJSON,
} from './ApplicationId';
import type { TaskOwner } from './TaskOwner';
import {
    TaskOwnerFromJSON,
    TaskOwnerFromJSONTyped,
    TaskOwnerToJSON,
} from './TaskOwner';
import type { TaskStatus } from './TaskStatus';
import {
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
} from './TaskStatus';

/**
 * A task that is assigned to a user.
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * The ID of the aggregate that this task is associated to.
     * @type {string}
     * @memberof Task
     */
    aggregateId?: string;
    /**
     * 
     * @type {ApplicationId}
     * @memberof Task
     */
    appId?: ApplicationId;
    /**
     * The unique ID of the task.
     * @type {string}
     * @memberof Task
     */
    id?: string;
    /**
     * The date/time the task was launched.
     * @type {Date}
     * @memberof Task
     */
    launchDate?: Date;
    /**
     * The users or groups that own this task.
     * @type {Array<TaskOwner>}
     * @memberof Task
     */
    owners?: Array<TaskOwner>;
    /**
     * The users or groups that are capable of owning the task.
     * @type {Array<TaskOwner>}
     * @memberof Task
     */
    potentialOwners?: Array<TaskOwner>;
    /**
     * The ID of the root aggregate that this task is associated to.
     * @type {string}
     * @memberof Task
     */
    rootAggregateId?: string;
    /**
     * The version of schema, a date value that can be cross-referenced to the OpenAPI schema at the given date.
     * @type {string}
     * @memberof Task
     */
    schemaVersion?: TaskSchemaVersionEnum;
    /**
     * 
     * @type {TaskStatus}
     * @memberof Task
     */
    status?: TaskStatus;
    /**
     * The type of task.
     * @type {string}
     * @memberof Task
     */
    type?: string;
    /**
     * Values associated with the task. The expected values you can find here depend on the task type. Note that schemas that end with TaskValue are schemas that are used here.
     * @type {object}
     * @memberof Task
     */
    value?: object;
}

/**
* @export
* @enum {string}
*/
export enum TaskSchemaVersionEnum {
    _20231212 = '2023-12-12'
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggregateId': !exists(json, 'aggregateId') ? undefined : json['aggregateId'],
        'appId': !exists(json, 'appId') ? undefined : ApplicationIdFromJSON(json['appId']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'launchDate': !exists(json, 'launchDate') || json['launchDate'] === '' ? undefined : new Date(json['launchDate']),
        'owners': !exists(json, 'owners') ? undefined : (json['owners'] as Array<TaskOwner>).map(TaskOwnerFromJSON),
        'potentialOwners': !exists(json, 'potentialOwners') ? undefined : (json['potentialOwners'] as Array<TaskOwner>).map(TaskOwnerFromJSON),
        'rootAggregateId': !exists(json, 'rootAggregateId') ? undefined : json['rootAggregateId'],
        'schemaVersion': !exists(json, 'schemaVersion') ? undefined : json['schemaVersion'],
        'status': !exists(json, 'status') ? undefined : TaskStatusFromJSON(json['status']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function TaskToJSON(value?: Task | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggregateId': value.aggregateId,
        'appId': ApplicationIdToJSON(value.appId),
        'id': value.id,
        'launchDate': value.launchDate === undefined ? undefined : value.launchDate.toISOString(),
        'owners': value.owners === undefined ? undefined : (value.owners as Array<TaskOwner>).map(TaskOwnerToJSON),
        'potentialOwners': value.potentialOwners === undefined ? undefined : (value.potentialOwners as Array<TaskOwner>).map(TaskOwnerToJSON),
        'rootAggregateId': value.rootAggregateId,
        'schemaVersion': value.schemaVersion,
        'status': TaskStatusToJSON(value.status),
        'type': value.type,
        'value': value.value,
    };
}

