/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DateTimeAllOf } from './DateTimeAllOf';
import {
    DateTimeAllOfFromJSON,
    DateTimeAllOfFromJSONTyped,
    DateTimeAllOfToJSON,
} from './DateTimeAllOf';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';

/**
 * 
 * @export
 * @interface DateTime
 */
export interface DateTime extends FormElement {
    /**
     * 
     * @type {boolean}
     * @memberof DateTime
     */
    isDate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DateTime
     */
    isTime?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DateTime
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DateTime
     */
    hintText?: string;
}

export function DateTimeFromJSON(json: any): DateTime {
    return DateTimeFromJSONTyped(json, false);
}

export function DateTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'isDate': !exists(json, 'isDate') ? undefined : json['isDate'],
        'isTime': !exists(json, 'isTime') ? undefined : json['isTime'],
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
    };
}

export function DateTimeToJSON(value?: DateTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'isDate': value.isDate,
        'isTime': value.isTime,
        'showHintText': value.showHintText,
        'hintText': value.hintText,
    };
}

