/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents a lock on an object held by a user. Initially we use for locking form Elements, but later this can be extended to Nodes and entire Records.
 * @export
 * @interface Lock
 */
export interface Lock {
    /**
     * ID of the user holding the lock
     * @type {string}
     * @memberof Lock
     */
    userId?: string;
    /**
     * ID of the form Element that is being locked
     * @type {string}
     * @memberof Lock
     */
    elementId?: string;
}

export function LockFromJSON(json: any): Lock {
    return LockFromJSONTyped(json, false);
}

export function LockFromJSONTyped(json: any, ignoreDiscriminator: boolean): Lock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'elementId': !exists(json, 'elementId') ? undefined : json['elementId'],
    };
}

export function LockToJSON(value?: Lock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'elementId': value.elementId,
    };
}

