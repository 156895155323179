/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResult } from './PaginationResult';
import {
    PaginationResultFromJSON,
    PaginationResultFromJSONTyped,
    PaginationResultToJSON,
} from './PaginationResult';
import type { Record } from './Record';
import {
    RecordFromJSON,
    RecordFromJSONTyped,
    RecordToJSON,
} from './Record';

/**
 * 
 * @export
 * @interface RecordListResponse
 */
export interface RecordListResponse {
    /**
     * 
     * @type {PaginationResult}
     * @memberof RecordListResponse
     */
    pagination?: PaginationResult;
    /**
     * 
     * @type {Array<Record>}
     * @memberof RecordListResponse
     */
    records?: Array<Record>;
}

export function RecordListResponseFromJSON(json: any): RecordListResponse {
    return RecordListResponseFromJSONTyped(json, false);
}

export function RecordListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationResultFromJSON(json['pagination']),
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<Record>).map(RecordFromJSON),
    };
}

export function RecordListResponseToJSON(value?: RecordListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationResultToJSON(value.pagination),
        'records': value.records === undefined ? undefined : (value.records as Array<Record>).map(RecordToJSON),
    };
}

