/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRecordRequestV2
 */
export interface CreateRecordRequestV2 {
    /**
     * 
     * @type {string}
     * @memberof CreateRecordRequestV2
     */
    workflowId: string;
    /**
     * If this new record request is being launched from a step on an event or launched from an Action Item, this value represents the ID of the initiating event. For an Action Item, this value is also the ID of the event from which the Action Item originated.
     * @type {string}
     * @memberof CreateRecordRequestV2
     */
    parentRecordId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecordRequestV2
     */
    recordTitle: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecordRequestV2
     */
    childWorkflowId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecordRequestV2
     */
    recordDescription?: string;
    /**
     * the specific sites that are affected by the event (this field or useActingUsersSite is required)
     * @type {Array<string>}
     * @memberof CreateRecordRequestV2
     */
    affectedSites?: Array<string>;
    /**
     * the acting user's site is the affected site (this field or affectedSites is required)
     * @type {boolean}
     * @memberof CreateRecordRequestV2
     */
    useActingUsersSite?: boolean;
}

export function CreateRecordRequestV2FromJSON(json: any): CreateRecordRequestV2 {
    return CreateRecordRequestV2FromJSONTyped(json, false);
}

export function CreateRecordRequestV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRecordRequestV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflowId': json['workflowId'],
        'parentRecordId': !exists(json, 'parentRecordId') ? undefined : json['parentRecordId'],
        'recordTitle': json['recordTitle'],
        'childWorkflowId': !exists(json, 'childWorkflowId') ? undefined : json['childWorkflowId'],
        'recordDescription': !exists(json, 'recordDescription') ? undefined : json['recordDescription'],
        'affectedSites': !exists(json, 'affectedSites') ? undefined : (json['affectedSites'] as Array<string>),
        'useActingUsersSite': !exists(json, 'useActingUsersSite') ? undefined : json['useActingUsersSite'],
    };
}

export function CreateRecordRequestV2ToJSON(value?: CreateRecordRequestV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflowId': value.workflowId,
        'parentRecordId': value.parentRecordId,
        'recordTitle': value.recordTitle,
        'childWorkflowId': value.childWorkflowId,
        'recordDescription': value.recordDescription,
        'affectedSites': value.affectedSites === undefined ? undefined : (value.affectedSites as Array<string>),
        'useActingUsersSite': value.useActingUsersSite,
    };
}

