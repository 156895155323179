/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckItemsResponse
 */
export interface CheckItemsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckItemsResponse
     */
    items?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CheckItemsResponse
     */
    inList?: boolean;
}

export function CheckItemsResponseFromJSON(json: any): CheckItemsResponse {
    return CheckItemsResponseFromJSONTyped(json, false);
}

export function CheckItemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckItemsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<string>),
        'inList': !exists(json, 'inList') ? undefined : json['inList'],
    };
}

export function CheckItemsResponseToJSON(value?: CheckItemsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items as Array<string>),
        'inList': value.inList,
    };
}

