/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Edge } from './Edge';
import {
    EdgeFromJSON,
    EdgeFromJSONTyped,
    EdgeToJSON,
} from './Edge';
import type { SideEffects } from './SideEffects';
import {
    SideEffectsFromJSON,
    SideEffectsFromJSONTyped,
    SideEffectsToJSON,
} from './SideEffects';

/**
 * 
 * @export
 * @interface RemoveNodeResponse
 */
export interface RemoveNodeResponse {
    /**
     * 
     * @type {Array<Edge>}
     * @memberof RemoveNodeResponse
     */
    addedEdges?: Array<Edge>;
    /**
     * 
     * @type {Array<Edge>}
     * @memberof RemoveNodeResponse
     */
    removedEdges?: Array<Edge>;
    /**
     * 
     * @type {SideEffects}
     * @memberof RemoveNodeResponse
     */
    sideEffects?: SideEffects;
}

export function RemoveNodeResponseFromJSON(json: any): RemoveNodeResponse {
    return RemoveNodeResponseFromJSONTyped(json, false);
}

export function RemoveNodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveNodeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addedEdges': !exists(json, 'addedEdges') ? undefined : (json['addedEdges'] as Array<Edge>).map(EdgeFromJSON),
        'removedEdges': !exists(json, 'removedEdges') ? undefined : (json['removedEdges'] as Array<Edge>).map(EdgeFromJSON),
        'sideEffects': !exists(json, 'sideEffects') ? undefined : SideEffectsFromJSON(json['sideEffects']),
    };
}

export function RemoveNodeResponseToJSON(value?: RemoveNodeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addedEdges': value.addedEdges === undefined ? undefined : (value.addedEdges as Array<Edge>).map(EdgeToJSON),
        'removedEdges': value.removedEdges === undefined ? undefined : (value.removedEdges as Array<Edge>).map(EdgeToJSON),
        'sideEffects': SideEffectsToJSON(value.sideEffects),
    };
}

