/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Workflow } from './Workflow';
import {
    WorkflowFromJSON,
    WorkflowFromJSONTyped,
    WorkflowToJSON,
} from './Workflow';

/**
 * 
 * @export
 * @interface MultipleWorkflowResponse
 */
export interface MultipleWorkflowResponse {
    /**
     * 
     * @type {Array<Workflow>}
     * @memberof MultipleWorkflowResponse
     */
    workflows?: Array<Workflow>;
}

export function MultipleWorkflowResponseFromJSON(json: any): MultipleWorkflowResponse {
    return MultipleWorkflowResponseFromJSONTyped(json, false);
}

export function MultipleWorkflowResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipleWorkflowResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflows': !exists(json, 'workflows') ? undefined : (json['workflows'] as Array<Workflow>).map(WorkflowFromJSON),
    };
}

export function MultipleWorkflowResponseToJSON(value?: MultipleWorkflowResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflows': value.workflows === undefined ? undefined : (value.workflows as Array<Workflow>).map(WorkflowToJSON),
    };
}

