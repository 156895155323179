/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MovePathResponse
 */
export interface MovePathResponse {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof MovePathResponse
     */
    updatedNodeLayout?: { [key: string]: Array<string>; };
}

export function MovePathResponseFromJSON(json: any): MovePathResponse {
    return MovePathResponseFromJSONTyped(json, false);
}

export function MovePathResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovePathResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedNodeLayout': !exists(json, 'updatedNodeLayout') ? undefined : json['updatedNodeLayout'],
    };
}

export function MovePathResponseToJSON(value?: MovePathResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedNodeLayout': value.updatedNodeLayout,
    };
}

