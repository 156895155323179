import { LanguagesV2 } from "../../../generated/tenant";

export const LanguageCode = {
    [LanguagesV2.Chinese]: "zh-Hans",
    [LanguagesV2.ChineseTraditional]: "zh-Hant",
    [LanguagesV2.English]: "en",
    [LanguagesV2.French]: "fr",
    [LanguagesV2.German]: "de",
    [LanguagesV2.Italian]: "it",
    [LanguagesV2.Japanese]: "ja",
    [LanguagesV2.Korean]: "ko",
    [LanguagesV2.Polish]: "pl",
    [LanguagesV2.Portuguese]: "pt",
    [LanguagesV2.Russian]: "ru",
    [LanguagesV2.Spanish]: "es",
    [LanguagesV2.Taiwanese]: "zh-Hans",
    [LanguagesV2.Vietnamese]: "vi",
} as Record<LanguagesV2, string>;

export type LanguageCodeKey = keyof typeof LanguageCode;
