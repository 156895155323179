// deepcode ignore HardcodedNonCryptoSecret: this isn't a hard-coded secret. It is the local storage key for our JWT token.
const LOCAL_STORAGE_KEY = "@mc/auth";

/**
 * Retrieves a value from the "@mc/auth" local storage object, given a key.
 */
export const getAuthLocalStorageValue = (key: string): string | null => {
    const authValues = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!authValues) {
        return null;
    }

    const authObject = JSON.parse(authValues);
    return authObject?.[key] ?? null;
};

/**
 * Sets a value on the "@mc/auth" local storage object. This method extends the existing value with the new key value
 * @param newKeyValue The value to add to the existing value.
 */
export function updateAuthLocalStorage(newKeyValue: Record<string, boolean | string | null | undefined>) {
    const local = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    const existing = local ? JSON.parse(local) : {};

    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ ...existing, ...newKeyValue }));
}

export function removeAuthLocalStorageValue() {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}
