/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TaskStatus {
    Unclaimed = 'UNCLAIMED',
    NotStarted = 'NOT_STARTED',
    Open = 'OPEN',
    Complete = 'COMPLETE',
    Closed = 'CLOSED',
    ClosedPrematurely = 'CLOSED_PREMATURELY',
    Blocked = 'BLOCKED',
    Pending = 'PENDING'
}

export function TaskStatusFromJSON(json: any): TaskStatus {
    return TaskStatusFromJSONTyped(json, false);
}

export function TaskStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStatus {
    return json as TaskStatus;
}

export function TaskStatusToJSON(value?: TaskStatus | null): any {
    return value as any;
}

