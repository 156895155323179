/* tslint:disable */
/* eslint-disable */
/**
 * Tenant Management Service OpenAPI Definition
 * Tenant Management application api doc
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LanguagesV2 {
    Chinese = 'Chinese',
    ChineseTraditional = 'Chinese_Traditional',
    English = 'English',
    French = 'French',
    German = 'German',
    Italian = 'Italian',
    Japanese = 'Japanese',
    Korean = 'Korean',
    Polish = 'Polish',
    Portuguese = 'Portuguese',
    Russian = 'Russian',
    Spanish = 'Spanish',
    Taiwanese = 'Taiwanese',
    Vietnamese = 'Vietnamese'
}

export function LanguagesV2FromJSON(json: any): LanguagesV2 {
    return LanguagesV2FromJSONTyped(json, false);
}

export function LanguagesV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguagesV2 {
    return json as LanguagesV2;
}

export function LanguagesV2ToJSON(value?: LanguagesV2 | null): any {
    return value as any;
}

