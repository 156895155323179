/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRecordRequest
 */
export interface CreateRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRecordRequest
     */
    workflowId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecordRequest
     */
    recordTitle: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecordRequest
     */
    childWorkflowId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecordRequest
     */
    recordDescription?: string;
}

export function CreateRecordRequestFromJSON(json: any): CreateRecordRequest {
    return CreateRecordRequestFromJSONTyped(json, false);
}

export function CreateRecordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRecordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflowId': json['workflowId'],
        'recordTitle': json['recordTitle'],
        'childWorkflowId': !exists(json, 'childWorkflowId') ? undefined : json['childWorkflowId'],
        'recordDescription': !exists(json, 'recordDescription') ? undefined : json['recordDescription'],
    };
}

export function CreateRecordRequestToJSON(value?: CreateRecordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflowId': value.workflowId,
        'recordTitle': value.recordTitle,
        'childWorkflowId': value.childWorkflowId,
        'recordDescription': value.recordDescription,
    };
}

