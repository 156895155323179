/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddNodeRequestBody,
    AddNodeRequestBodyFromJSON,
    AddNodeRequestBodyToJSON,
    AddNodeResponseBody,
    AddNodeResponseBodyFromJSON,
    AddNodeResponseBodyToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MoveNodeRequest,
    MoveNodeRequestFromJSON,
    MoveNodeRequestToJSON,
    MoveNodeResponse,
    MoveNodeResponseFromJSON,
    MoveNodeResponseToJSON,
    MovePathRequest,
    MovePathRequestFromJSON,
    MovePathRequestToJSON,
    MovePathResponse,
    MovePathResponseFromJSON,
    MovePathResponseToJSON,
    RemoveNodeResponse,
    RemoveNodeResponseFromJSON,
    RemoveNodeResponseToJSON,
    UpdateNodeRequestBody,
    UpdateNodeRequestBodyFromJSON,
    UpdateNodeRequestBodyToJSON,
    UpdateNodeResponseBody,
    UpdateNodeResponseBodyFromJSON,
    UpdateNodeResponseBodyToJSON,
} from '../models';

export interface AddNodeRequest {
    workflowId: string;
    addNodeRequestBody: AddNodeRequestBody;
}

export interface MoveNodeOperationRequest {
    nodeId: string;
    moveNodeRequest: MoveNodeRequest;
}

export interface MovePathOperationRequest {
    movePathRequest: MovePathRequest;
}

export interface RemoveNodeRequest {
    nodeId: string;
}

export interface RemoveNodesRequest {
    nodeIds: Array<string>;
}

export interface UpdateNodeRequest {
    nodeId: string;
    updateNodeRequestBody: UpdateNodeRequestBody;
}

/**
 * 
 */
export class NodeApi extends runtime.BaseAPI {

    /**
     * Adds a Node to the given Workflow. Takes as parameters a \'From\' Node ID and a \'To\' Node ID.  If both From and To are provided, this will be an insert action.  If the new node X is inserted between Nodes A and B, the response will be a new node with added edges (A,X) & (X,B) with edge (A,B) being removed if it exists. Only providing the To value is only valid for prepending to the first node of the workflow.  Only providing the From value will append the Node as a leaf Node in the tree. Providing no value for From or To is only valid if there are no other existing nodes in the Workflow. 
     * Add a Node to the Workflow
     */
    async addNodeRaw(requestParameters: AddNodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AddNodeResponseBody>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling addNode.');
        }

        if (requestParameters.addNodeRequestBody === null || requestParameters.addNodeRequestBody === undefined) {
            throw new runtime.RequiredError('addNodeRequestBody','Required parameter requestParameters.addNodeRequestBody was null or undefined when calling addNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/node`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddNodeRequestBodyToJSON(requestParameters.addNodeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddNodeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Adds a Node to the given Workflow. Takes as parameters a \'From\' Node ID and a \'To\' Node ID.  If both From and To are provided, this will be an insert action.  If the new node X is inserted between Nodes A and B, the response will be a new node with added edges (A,X) & (X,B) with edge (A,B) being removed if it exists. Only providing the To value is only valid for prepending to the first node of the workflow.  Only providing the From value will append the Node as a leaf Node in the tree. Providing no value for From or To is only valid if there are no other existing nodes in the Workflow. 
     * Add a Node to the Workflow
     */
    async addNode(requestParameters: AddNodeRequest, initOverrides?: RequestInit): Promise<AddNodeResponseBody> {
        const response = await this.addNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Moves the given Node.  Moving a node with multiple edges going into or out of the node is not supported. 
     * Move a Node
     */
    async moveNodeRaw(requestParameters: MoveNodeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MoveNodeResponse>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling moveNode.');
        }

        if (requestParameters.moveNodeRequest === null || requestParameters.moveNodeRequest === undefined) {
            throw new runtime.RequiredError('moveNodeRequest','Required parameter requestParameters.moveNodeRequest was null or undefined when calling moveNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/node/{nodeId}/v1/move`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MoveNodeRequestToJSON(requestParameters.moveNodeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MoveNodeResponseFromJSON(jsonValue));
    }

    /**
     * Moves the given Node.  Moving a node with multiple edges going into or out of the node is not supported. 
     * Move a Node
     */
    async moveNode(requestParameters: MoveNodeOperationRequest, initOverrides?: RequestInit): Promise<MoveNodeResponse> {
        const response = await this.moveNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Moves an entire path within a branch including any sub-branches to the specified index(0-based). 
     * Move an entire path within a branch to the specified index(0-based).
     */
    async movePathRaw(requestParameters: MovePathOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MovePathResponse>> {
        if (requestParameters.movePathRequest === null || requestParameters.movePathRequest === undefined) {
            throw new runtime.RequiredError('movePathRequest','Required parameter requestParameters.movePathRequest was null or undefined when calling movePath.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/node/v1/action/movePath`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MovePathRequestToJSON(requestParameters.movePathRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MovePathResponseFromJSON(jsonValue));
    }

    /**
     * Moves an entire path within a branch including any sub-branches to the specified index(0-based). 
     * Move an entire path within a branch to the specified index(0-based).
     */
    async movePath(requestParameters: MovePathOperationRequest, initOverrides?: RequestInit): Promise<MovePathResponse> {
        const response = await this.movePathRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes the given Node.  Response will not include removed node, sections, or form elements 
     * Remove a Node
     */
    async removeNodeRaw(requestParameters: RemoveNodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RemoveNodeResponse>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling removeNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/node/v1/{nodeId}`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RemoveNodeResponseFromJSON(jsonValue));
    }

    /**
     * Removes the given Node.  Response will not include removed node, sections, or form elements 
     * Remove a Node
     */
    async removeNode(requestParameters: RemoveNodeRequest, initOverrides?: RequestInit): Promise<RemoveNodeResponse> {
        const response = await this.removeNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a list of nodes from a workflow. Returns the list of edges added and removed. Primarily used for removing splits and joins. 
     * Removes a list of nodes.
     */
    async removeNodesRaw(requestParameters: RemoveNodesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RemoveNodeResponse>> {
        if (requestParameters.nodeIds === null || requestParameters.nodeIds === undefined) {
            throw new runtime.RequiredError('nodeIds','Required parameter requestParameters.nodeIds was null or undefined when calling removeNodes.');
        }

        const queryParameters: any = {};

        if (requestParameters.nodeIds) {
            queryParameters['nodeIds'] = requestParameters.nodeIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/nodes/v1`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RemoveNodeResponseFromJSON(jsonValue));
    }

    /**
     * Removes a list of nodes from a workflow. Returns the list of edges added and removed. Primarily used for removing splits and joins. 
     * Removes a list of nodes.
     */
    async removeNodes(requestParameters: RemoveNodesRequest, initOverrides?: RequestInit): Promise<RemoveNodeResponse> {
        const response = await this.removeNodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the given Node. Only properties provided in the request will be updated. 
     * Update a Node
     */
    async updateNodeRaw(requestParameters: UpdateNodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateNodeResponseBody>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling updateNode.');
        }

        if (requestParameters.updateNodeRequestBody === null || requestParameters.updateNodeRequestBody === undefined) {
            throw new runtime.RequiredError('updateNodeRequestBody','Required parameter requestParameters.updateNodeRequestBody was null or undefined when calling updateNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/node/v1/{nodeId}`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNodeRequestBodyToJSON(requestParameters.updateNodeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateNodeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates the given Node. Only properties provided in the request will be updated. 
     * Update a Node
     */
    async updateNode(requestParameters: UpdateNodeRequest, initOverrides?: RequestInit): Promise<UpdateNodeResponseBody> {
        const response = await this.updateNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
