/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NavigationItem
 */
export interface NavigationItem {
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    platform?: NavigationItemPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    link?: string;
    /**
     * 
     * @type {Array<Array<object>>}
     * @memberof NavigationItem
     */
    metadata?: Array<Array<object>>;
}

/**
* @export
* @enum {string}
*/
export enum NavigationItemPlatformEnum {
    Adapt = 'adapt',
    Qx = 'qx',
    QxCustomHub = 'qx-custom-hub'
}

export function NavigationItemFromJSON(json: any): NavigationItem {
    return NavigationItemFromJSONTyped(json, false);
}

export function NavigationItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavigationItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'metadata': !exists(json, 'metadata') ? undefined : (json['metadata'] as Array<Array<object>>).map((items) => items),
    };
}

export function NavigationItemToJSON(value?: NavigationItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'platform': value.platform,
        'link': value.link,
        'metadata': value.metadata === undefined ? undefined : (value.metadata as Array<Array<object>>).map((items) => items),
    };
}

