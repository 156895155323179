/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    NavigationSection,
    NavigationSectionFromJSON,
    NavigationSectionToJSON,
} from '../models';

export interface GetNavigationItemsRequest {
    mcApplicationId: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

/**
 * 
 */
export class NavigationApi extends runtime.BaseAPI {

    /**
     * Gets navigation structure for user making the call.
     */
    async getNavigationItemsRaw(requestParameters: GetNavigationItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NavigationSection>>> {
        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling getNavigationItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/navigation/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NavigationSectionFromJSON));
    }

    /**
     * Gets navigation structure for user making the call.
     */
    async getNavigationItems(requestParameters: GetNavigationItemsRequest, initOverrides?: RequestInit): Promise<Array<NavigationSection>> {
        const response = await this.getNavigationItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
