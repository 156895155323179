/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkflowStatus {
    Draft = 'DRAFT',
    Published = 'PUBLISHED',
    Archived = 'ARCHIVED',
    Validating = 'VALIDATING'
}

export function WorkflowStatusFromJSON(json: any): WorkflowStatus {
    return WorkflowStatusFromJSONTyped(json, false);
}

export function WorkflowStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowStatus {
    return json as WorkflowStatus;
}

export function WorkflowStatusToJSON(value?: WorkflowStatus | null): any {
    return value as any;
}

