/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleType } from './RuleType';
import {
    RuleTypeFromJSON,
    RuleTypeFromJSONTyped,
    RuleTypeToJSON,
} from './RuleType';

import {
     FormOptionNodeRuleFromJSONTyped,
     FormOptionSectionRuleFromJSONTyped
} from './';

/**
 * Rule
 * @export
 * @interface Rule
 */
export interface Rule {
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    workflowId?: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    nodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Rule
     */
    sourceSectionId?: string;
    /**
     * 
     * @type {RuleType}
     * @memberof Rule
     */
    ruleType: RuleType;
}

export function RuleFromJSON(json: any): Rule {
    return RuleFromJSONTyped(json, false);
}

export function RuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Rule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['ruleType'] === 'FORM_OPTION_NODE') {
            return FormOptionNodeRuleFromJSONTyped(json, true);
        }
        if (json['ruleType'] === 'FORM_OPTION_SECTION') {
            return FormOptionSectionRuleFromJSONTyped(json, true);
        }
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'sourceSectionId': !exists(json, 'sourceSectionId') ? undefined : json['sourceSectionId'],
        'ruleType': RuleTypeFromJSON(json['ruleType']),
    };
}

export function RuleToJSON(value?: Rule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'workflowId': value.workflowId,
        'nodeId': value.nodeId,
        'sourceSectionId': value.sourceSectionId,
        'ruleType': RuleTypeToJSON(value.ruleType),
    };
}

