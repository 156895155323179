/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalLinkAllOf } from './ExternalLinkAllOf';
import {
    ExternalLinkAllOfFromJSON,
    ExternalLinkAllOfFromJSONTyped,
    ExternalLinkAllOfToJSON,
} from './ExternalLinkAllOf';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';

/**
 * 
 * @export
 * @interface ExternalLink
 */
export interface ExternalLink extends FormElement {
    /**
     * 
     * @type {boolean}
     * @memberof ExternalLink
     */
    showHintText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExternalLink
     */
    hintText?: string;
}

export function ExternalLinkFromJSON(json: any): ExternalLink {
    return ExternalLinkFromJSONTyped(json, false);
}

export function ExternalLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'showHintText': !exists(json, 'showHintText') ? undefined : json['showHintText'],
        'hintText': !exists(json, 'hintText') ? undefined : json['hintText'],
    };
}

export function ExternalLinkToJSON(value?: ExternalLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'showHintText': value.showHintText,
        'hintText': value.hintText,
    };
}

