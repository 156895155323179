/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Section
 */
export interface Section {
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    nodeId: string;
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    displayOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Section
     */
    hideHeader?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Section
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Section
     */
    visible?: boolean;
}

export function SectionFromJSON(json: any): Section {
    return SectionFromJSONTyped(json, false);
}

export function SectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Section {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nodeId': json['nodeId'],
        'displayOrder': !exists(json, 'displayOrder') ? undefined : json['displayOrder'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'hideHeader': !exists(json, 'hideHeader') ? undefined : json['hideHeader'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'visible': !exists(json, 'visible') ? undefined : json['visible'],
    };
}

export function SectionToJSON(value?: Section | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nodeId': value.nodeId,
        'displayOrder': value.displayOrder,
        'name': value.name,
        'description': value.description,
        'hideHeader': value.hideHeader,
        'enabled': value.enabled,
        'visible': value.visible,
    };
}

