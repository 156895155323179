/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CQxDocumentLink
 */
export interface CQxDocumentLink {
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    fromRecordId?: string;
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    fromNodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    fromElementId?: string;
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    revision?: string;
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    userId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CQxDocumentLink
     */
    timeStamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof CQxDocumentLink
     */
    staticLink?: string;
}

export function CQxDocumentLinkFromJSON(json: any): CQxDocumentLink {
    return CQxDocumentLinkFromJSONTyped(json, false);
}

export function CQxDocumentLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): CQxDocumentLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fromRecordId': !exists(json, 'fromRecordId') ? undefined : json['fromRecordId'],
        'fromNodeId': !exists(json, 'fromNodeId') ? undefined : json['fromNodeId'],
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'fromElementId': !exists(json, 'fromElementId') ? undefined : json['fromElementId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'revision': !exists(json, 'revision') ? undefined : json['revision'],
        'documentNumber': !exists(json, 'documentNumber') ? undefined : json['documentNumber'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'timeStamp': !exists(json, 'timeStamp') || json['timeStamp'] === '' ? undefined : new Date(json['timeStamp']),
        'staticLink': !exists(json, 'staticLink') ? undefined : json['staticLink'],
    };
}

export function CQxDocumentLinkToJSON(value?: CQxDocumentLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fromRecordId': value.fromRecordId,
        'fromNodeId': value.fromNodeId,
        'documentId': value.documentId,
        'fromElementId': value.fromElementId,
        'title': value.title,
        'revision': value.revision,
        'documentNumber': value.documentNumber,
        'userId': value.userId,
        'timeStamp': value.timeStamp === undefined ? undefined : value.timeStamp.toISOString(),
        'staticLink': value.staticLink,
    };
}

