/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditRecordRequest
 */
export interface EditRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof EditRecordRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EditRecordRequest
     */
    description?: string;
}

export function EditRecordRequestFromJSON(json: any): EditRecordRequest {
    return EditRecordRequestFromJSONTyped(json, false);
}

export function EditRecordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditRecordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function EditRecordRequestToJSON(value?: EditRecordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
    };
}

