/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecutionPlanSection
 */
export interface ExecutionPlanSection {
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanSection
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanSection
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanSection
     */
    nodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanSection
     */
    executionPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanSection
     */
    sectionConfigurationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanSection
     */
    parentSectionId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanSection
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanSection
     */
    hideHeader?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExecutionPlanSection
     */
    disabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExecutionPlanSection
     */
    displayOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof ExecutionPlanSection
     */
    description?: string;
}

export function ExecutionPlanSectionFromJSON(json: any): ExecutionPlanSection {
    return ExecutionPlanSectionFromJSONTyped(json, false);
}

export function ExecutionPlanSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionPlanSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'executionPlanId': !exists(json, 'executionPlanId') ? undefined : json['executionPlanId'],
        'sectionConfigurationId': !exists(json, 'sectionConfigurationId') ? undefined : json['sectionConfigurationId'],
        'parentSectionId': !exists(json, 'parentSectionId') ? undefined : json['parentSectionId'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'hideHeader': !exists(json, 'hideHeader') ? undefined : json['hideHeader'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'displayOrder': !exists(json, 'displayOrder') ? undefined : json['displayOrder'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ExecutionPlanSectionToJSON(value?: ExecutionPlanSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'nodeId': value.nodeId,
        'executionPlanId': value.executionPlanId,
        'sectionConfigurationId': value.sectionConfigurationId,
        'parentSectionId': value.parentSectionId,
        'hidden': value.hidden,
        'hideHeader': value.hideHeader,
        'disabled': value.disabled,
        'displayOrder': value.displayOrder,
        'description': value.description,
    };
}

