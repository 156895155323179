/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateValidationReportRequest,
    CreateValidationReportRequestFromJSON,
    CreateValidationReportRequestToJSON,
    CreateValidationReportResponse,
    CreateValidationReportResponseFromJSON,
    CreateValidationReportResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface AddValidationReportRequest {
    workflowId: string;
    createValidationReportRequest: CreateValidationReportRequest;
}

export interface RemoveValidationReportRequest {
    validationReportId: string;
}

/**
 * 
 */
export class ValidationReportV1Api extends runtime.BaseAPI {

    /**
     * Add a Validation Report to the given Workflow. 
     * Add a Validation Report to the Workflow
     */
    async addValidationReportRaw(requestParameters: AddValidationReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateValidationReportResponse>> {
        if (requestParameters.workflowId === null || requestParameters.workflowId === undefined) {
            throw new runtime.RequiredError('workflowId','Required parameter requestParameters.workflowId was null or undefined when calling addValidationReport.');
        }

        if (requestParameters.createValidationReportRequest === null || requestParameters.createValidationReportRequest === undefined) {
            throw new runtime.RequiredError('createValidationReportRequest','Required parameter requestParameters.createValidationReportRequest was null or undefined when calling addValidationReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/workflow/v1/{workflowId}/validationReport`.replace(`{${"workflowId"}}`, encodeURIComponent(String(requestParameters.workflowId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateValidationReportRequestToJSON(requestParameters.createValidationReportRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateValidationReportResponseFromJSON(jsonValue));
    }

    /**
     * Add a Validation Report to the given Workflow. 
     * Add a Validation Report to the Workflow
     */
    async addValidationReport(requestParameters: AddValidationReportRequest, initOverrides?: RequestInit): Promise<CreateValidationReportResponse> {
        const response = await this.addValidationReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes the given Validation Report. 
     * Remove a Validation Report
     */
    async removeValidationReportRaw(requestParameters: RemoveValidationReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.validationReportId === null || requestParameters.validationReportId === undefined) {
            throw new runtime.RequiredError('validationReportId','Required parameter requestParameters.validationReportId was null or undefined when calling removeValidationReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/workflow/validationReport/v1/{validationReportId}`.replace(`{${"validationReportId"}}`, encodeURIComponent(String(requestParameters.validationReportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes the given Validation Report. 
     * Remove a Validation Report
     */
    async removeValidationReport(requestParameters: RemoveValidationReportRequest, initOverrides?: RequestInit): Promise<void> {
        await this.removeValidationReportRaw(requestParameters, initOverrides);
    }

}
