/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResult } from './PaginationResult';
import {
    PaginationResultFromJSON,
    PaginationResultFromJSONTyped,
    PaginationResultToJSON,
} from './PaginationResult';
import type { Workflow } from './Workflow';
import {
    WorkflowFromJSON,
    WorkflowFromJSONTyped,
    WorkflowToJSON,
} from './Workflow';

/**
 * 
 * @export
 * @interface GetWorkflowsResponse
 */
export interface GetWorkflowsResponse {
    /**
     * 
     * @type {PaginationResult}
     * @memberof GetWorkflowsResponse
     */
    pagination?: PaginationResult;
    /**
     * 
     * @type {Array<Workflow>}
     * @memberof GetWorkflowsResponse
     */
    workflows?: Array<Workflow>;
}

export function GetWorkflowsResponseFromJSON(json: any): GetWorkflowsResponse {
    return GetWorkflowsResponseFromJSONTyped(json, false);
}

export function GetWorkflowsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWorkflowsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationResultFromJSON(json['pagination']),
        'workflows': !exists(json, 'workflows') ? undefined : (json['workflows'] as Array<Workflow>).map(WorkflowFromJSON),
    };
}

export function GetWorkflowsResponseToJSON(value?: GetWorkflowsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationResultToJSON(value.pagination),
        'workflows': value.workflows === undefined ? undefined : (value.workflows as Array<Workflow>).map(WorkflowToJSON),
    };
}

