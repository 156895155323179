export const DATE_FORMAT_DATE = "d MMM, yyyy";
export const TIME_FORMAT_24HOUR_TZ = "HH:mm zzz";
export const TIME_FORMAT_12HOUR_TZ = "hh:mm aa zzz";
export const TIME_FORMAT_24HOUR_GMT = "HH:mm O";
export const TIME_FORMAT_12HOUR_GMT = "hh:mm aa O";
export const TIME_ZONE_NAME_DEFAULT = "UTC";

export const UtcOffsetTimeZone = {
    "+01:00": "Europe/Paris",
    "+02:00": "Europe/Berlin",
    "+03:00": "Europe/Moscow",
    "+03:30": "Asia/Tehran",
    "+04:00": "Asia/Dubai",
    "+04:30": "Asia/Kabul",
    "+05:00": "Asia/Karachi",
    "+05:30": "Asia/Kolkata",
    "+05:45": "Asia/Kathmandu",
    "+06:00": "Asia/Dhaka",
    "+06:30": "Asia/Yangon",
    "+07:00": "Asia/Bangkok",
    "+08:00": "Asia/Singapore",
    "+08:45": "Australia/Eucla",
    "+09:00": "Asia/Tokyo",
    "+09:30": "Australia/Adelaide",
    "+10:00": "Australia/Brisbane",
    "+10:30": "Australia/Lord_Howe",
    "+11:00": "Pacific/Efate",
    "+11:30": "Pacific/Norfolk",
    "+12:00": "Pacific/Auckland",
    "+12:45": "Pacific/Chatham",
    "+13:00": "Pacific/Tongatapu",
    "+14:00": "Pacific/Kiritimati",
    "-01:00": "Atlantic/Azores",
    "-02:00": "Atlantic/South_Georgia",
    "-03:00": "America/Argentina/Buenos_Aires",
    "-03:30": "Canada/Newfoundland",
    "-04:00": "America/Santiago",
    "-05:00": "America/Lima",
    "-06:00": "America/Mexico_City",
    "-07:00": "America/Phoenix",
    "-08:00": "America/Los_Angeles",
    "-09:00": "America/Anchorage",
    "-10:00": "Pacific/Honolulu",
    "-11:00": "Pacific/Midway",
    "-12:00": "Pacific/Kwajalein",
    "00:00": "GMT",
};

export type UtcOffsetTimeZoneKey = keyof typeof UtcOffsetTimeZone;

export interface DateTime {
    dateFormat: string;
    timeFormat: string;
    timeZoneAuto: boolean;
    timeZoneName: string;
    utcOffset: UtcOffsetTimeZoneKey;
}
