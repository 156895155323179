import { apiConfig } from "@mc/application-settings";
import {
    DataMappingApi,
    NodeV1Api,
    RecordConfigConfiguration,
    ValidationReportV1Api,
    WorkflowV1Api,
    WorkflowV2Api,
} from ".";

export const configuration = new RecordConfigConfiguration(apiConfig);

export const workflowApi = {
    v1: new WorkflowV1Api(configuration),
    v2: new WorkflowV2Api(configuration),
};
export const nodeApi = { v1: new NodeV1Api(configuration) };
export const validationReportApi = { v1: new ValidationReportV1Api(configuration) };
export const dataMappingsApi = { v1: new DataMappingApi(configuration) };
