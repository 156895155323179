/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetLocksResponse,
    GetLocksResponseFromJSON,
    GetLocksResponseToJSON,
} from '../models';

export interface GetLocksWithinRecordRequest {
    recordId: string;
}

/**
 * 
 */
export class LockApi extends runtime.BaseAPI {

    /**
     * Returns currently active locks that are being held on Elements or Nodes that belong to current Record.
     * Get all active locks within given Record
     */
    async getLocksWithinRecordRaw(requestParameters: GetLocksWithinRecordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetLocksResponse>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling getLocksWithinRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/locks`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLocksResponseFromJSON(jsonValue));
    }

    /**
     * Returns currently active locks that are being held on Elements or Nodes that belong to current Record.
     * Get all active locks within given Record
     */
    async getLocksWithinRecord(requestParameters: GetLocksWithinRecordRequest, initOverrides?: RequestInit): Promise<GetLocksResponse> {
        const response = await this.getLocksWithinRecordRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
