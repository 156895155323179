import { QueryClient } from "@tanstack/react-query";

const maxRetryCount = 4;
const baseDelayMilliseconds = 100;
const maxDelayMilliseconds = 20000;
interface ErrorResponse {
    response: Response;
}

function isErrorResponse(error: ErrorResponse | unknown): error is ErrorResponse {
    return (error as ErrorResponse).response instanceof Response;
}

function isResponse(error: Response | unknown): error is Response {
    return error instanceof Response;
}

function shouldRetry(status: number) {
    if (status === 429 || status === 408 || status >= 500) {
        return true;
    }
    return false;
}

const lastRefetch: Record<string, { isFetching: boolean; promise: unknown; timestamp: number }> = {};

export const queryClient = new Proxy<QueryClient>(
    new QueryClient({
        defaultOptions: {
            queries: {
                behavior: {
                    onFetch: (context) => {
                        const queryKeyString = JSON.stringify(context.queryKey);
                        const throttleTime = 100;
                        const now = new Date().getTime();
                        const timeSinceLastRefetch = now - (lastRefetch[queryKeyString]?.timestamp ?? 0);
                        const isFetching = lastRefetch[queryKeyString]?.isFetching;

                        if (!isFetching || timeSinceLastRefetch > throttleTime) {
                            let resolver: (value: unknown) => void = () => null;
                            let rejecter: (reason: unknown) => void = () => null;
                            const promise = new Promise(
                                (resolve, reject) => ((resolver = resolve), (rejecter = reject)),
                            );
                            const fetchStatus = { isFetching: true, promise, timestamp: now };
                            lastRefetch[queryKeyString] = fetchStatus;
                            const fetchFn = context.fetchFn;
                            context.fetchFn = async (...params) => {
                                Promise.resolve(fetchFn(...params))
                                    .then((result) => {
                                        fetchStatus.isFetching = false;
                                        resolver(result);
                                    })
                                    .catch((reason) => {
                                        fetchStatus.isFetching = false;
                                        rejecter(reason);
                                    });
                                return promise;
                            };
                        } else {
                            context.fetchFn = () => Promise.resolve(lastRefetch[queryKeyString].promise);
                        }
                    },
                },
                onError: (error) => {
                    if (isResponse(error) && !error.bodyUsed) {
                        error?.json?.()?.then((errorResponse) => {
                            const baseErrorPath = "/homepage/error";
                            const isAlreadyOnErrorPage = window.location.href.includes(baseErrorPath);
                            if (error.status === 403 && !isAlreadyOnErrorPage) {
                                const path = errorResponse.errorCode
                                    ? `${baseErrorPath}?code=${errorResponse.errorCode}`
                                    : baseErrorPath;
                                window.location.href = path;
                            }
                        });
                    }
                },
                retry: (failureCount, error) => {
                    if (isResponse(error)) {
                        return shouldRetry(error.status);
                    } else if (isErrorResponse(error)) {
                        return shouldRetry(error.response.status);
                    }
                    return failureCount <= maxRetryCount;
                },
                retryDelay: (attemptIndex: number) => {
                    const delayMilliseconds = Math.min(baseDelayMilliseconds * 2 ** attemptIndex, maxDelayMilliseconds);
                    return delayMilliseconds * (1 + Math.random()); // Add some jitter to the retry time
                },
                staleTime: 20000,
            },
        },
    }),
    {
        get: (target, p) => {
            switch (p) {
                case "clear":
                    return () => {
                        Object.keys(lastRefetch).forEach((key) => {
                            delete lastRefetch[key];
                        });
                        return target.clear();
                    };
                default:
                    return target[p as keyof typeof target];
            }
        },
    },
);
