/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DirectCorrection
 */
export interface DirectCorrection {
    /**
     * 
     * @type {string}
     * @memberof DirectCorrection
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectCorrection
     */
    elementId?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectCorrection
     */
    recordId?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectCorrection
     */
    nodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectCorrection
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectCorrection
     */
    userId?: string;
    /**
     * 
     * @type {Date}
     * @memberof DirectCorrection
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof DirectCorrection
     */
    comments?: string;
    /**
     * This is a JSON node.
     * @type {object}
     * @memberof DirectCorrection
     */
    oldData?: object;
    /**
     * This is a JSON node.
     * @type {object}
     * @memberof DirectCorrection
     */
    newData?: object;
    /**
     * Is the Old value NA
     * @type {boolean}
     * @memberof DirectCorrection
     */
    isOldNA?: boolean;
    /**
     * Is the New value NA
     * @type {boolean}
     * @memberof DirectCorrection
     */
    isNewNA?: boolean;
}

export function DirectCorrectionFromJSON(json: any): DirectCorrection {
    return DirectCorrectionFromJSONTyped(json, false);
}

export function DirectCorrectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectCorrection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'elementId': !exists(json, 'elementId') ? undefined : json['elementId'],
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'timestamp': !exists(json, 'timestamp') || json['timestamp'] === '' ? undefined : new Date(json['timestamp']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'oldData': !exists(json, 'oldData') ? undefined : json['oldData'],
        'newData': !exists(json, 'newData') ? undefined : json['newData'],
        'isOldNA': !exists(json, 'isOldNA') ? undefined : json['isOldNA'],
        'isNewNA': !exists(json, 'isNewNA') ? undefined : json['isNewNA'],
    };
}

export function DirectCorrectionToJSON(value?: DirectCorrection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'elementId': value.elementId,
        'recordId': value.recordId,
        'nodeId': value.nodeId,
        'tenantId': value.tenantId,
        'userId': value.userId,
        'timestamp': value.timestamp === undefined ? undefined : value.timestamp.toISOString(),
        'comments': value.comments,
        'oldData': value.oldData,
        'newData': value.newData,
        'isOldNA': value.isOldNA,
        'isNewNA': value.isNewNA,
    };
}

