/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionItem } from './ActionItem';
import {
    ActionItemFromJSON,
    ActionItemFromJSONTyped,
    ActionItemToJSON,
} from './ActionItem';

/**
 * 
 * @export
 * @interface CreateActionItemCommandResponse
 */
export interface CreateActionItemCommandResponse {
    /**
     * 
     * @type {ActionItem}
     * @memberof CreateActionItemCommandResponse
     */
    actionItem?: ActionItem;
}

export function CreateActionItemCommandResponseFromJSON(json: any): CreateActionItemCommandResponse {
    return CreateActionItemCommandResponseFromJSONTyped(json, false);
}

export function CreateActionItemCommandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateActionItemCommandResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionItem': !exists(json, 'actionItem') ? undefined : ActionItemFromJSON(json['actionItem']),
    };
}

export function CreateActionItemCommandResponseToJSON(value?: CreateActionItemCommandResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionItem': ActionItemToJSON(value.actionItem),
    };
}

