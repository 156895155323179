/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParticipatingActor } from './ParticipatingActor';
import {
    ParticipatingActorFromJSON,
    ParticipatingActorFromJSONTyped,
    ParticipatingActorToJSON,
} from './ParticipatingActor';

/**
 * 
 * @export
 * @interface DeleteParticipantsCommand
 */
export interface DeleteParticipantsCommand {
    /**
     * 
     * @type {Array<ParticipatingActor>}
     * @memberof DeleteParticipantsCommand
     */
    participatingActors?: Array<ParticipatingActor>;
}

export function DeleteParticipantsCommandFromJSON(json: any): DeleteParticipantsCommand {
    return DeleteParticipantsCommandFromJSONTyped(json, false);
}

export function DeleteParticipantsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteParticipantsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'participatingActors': !exists(json, 'participatingActors') ? undefined : (json['participatingActors'] as Array<ParticipatingActor>).map(ParticipatingActorFromJSON),
    };
}

export function DeleteParticipantsCommandToJSON(value?: DeleteParticipantsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participatingActors': value.participatingActors === undefined ? undefined : (value.participatingActors as Array<ParticipatingActor>).map(ParticipatingActorToJSON),
    };
}

