/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Node } from './Node';
import {
    NodeFromJSON,
    NodeFromJSONTyped,
    NodeToJSON,
} from './Node';

/**
 * 
 * @export
 * @interface EligibleNodesResponseV2
 */
export interface EligibleNodesResponseV2 {
    /**
     * 
     * @type {Array<Node>}
     * @memberof EligibleNodesResponseV2
     */
    eligibleNodes?: Array<Node>;
}

export function EligibleNodesResponseV2FromJSON(json: any): EligibleNodesResponseV2 {
    return EligibleNodesResponseV2FromJSONTyped(json, false);
}

export function EligibleNodesResponseV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): EligibleNodesResponseV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eligibleNodes': !exists(json, 'eligibleNodes') ? undefined : (json['eligibleNodes'] as Array<Node>).map(NodeFromJSON),
    };
}

export function EligibleNodesResponseV2ToJSON(value?: EligibleNodesResponseV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eligibleNodes': value.eligibleNodes === undefined ? undefined : (value.eligibleNodes as Array<Node>).map(NodeToJSON),
    };
}

