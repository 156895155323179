/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentMediaType } from './AttachmentMediaType';
import {
    AttachmentMediaTypeFromJSON,
    AttachmentMediaTypeFromJSONTyped,
    AttachmentMediaTypeToJSON,
} from './AttachmentMediaType';

/**
 * Validation Report
 * @export
 * @interface ValidationReport
 */
export interface ValidationReport {
    /**
     * ID of the validation report
     * @type {string}
     * @memberof ValidationReport
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReport
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationReport
     */
    fileSize?: number;
    /**
     * 
     * @type {AttachmentMediaType}
     * @memberof ValidationReport
     */
    mediaType?: AttachmentMediaType;
    /**
     * 
     * @type {Date}
     * @memberof ValidationReport
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ValidationReport
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ValidationReport
     */
    lastModifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ValidationReport
     */
    lastModifiedBy?: string;
}

export function ValidationReportFromJSON(json: any): ValidationReport {
    return ValidationReportFromJSONTyped(json, false);
}

export function ValidationReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'mediaType': !exists(json, 'mediaType') ? undefined : AttachmentMediaTypeFromJSON(json['mediaType']),
        'createdDate': !exists(json, 'createdDate') || json['createdDate'] === '' ? undefined : new Date(json['createdDate']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') || json['lastModifiedDate'] === '' ? undefined : new Date(json['lastModifiedDate']),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
    };
}

export function ValidationReportToJSON(value?: ValidationReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileName': value.fileName,
        'fileSize': value.fileSize,
        'mediaType': AttachmentMediaTypeToJSON(value.mediaType),
        'createdDate': value.createdDate === undefined ? undefined : value.createdDate.toISOString(),
        'createdBy': value.createdBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : value.lastModifiedDate.toISOString(),
        'lastModifiedBy': value.lastModifiedBy,
    };
}

