/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordLink } from './RecordLink';
import {
    RecordLinkFromJSON,
    RecordLinkFromJSONTyped,
    RecordLinkToJSON,
} from './RecordLink';
import type { RecordV2 } from './RecordV2';
import {
    RecordV2FromJSON,
    RecordV2FromJSONTyped,
    RecordV2ToJSON,
} from './RecordV2';

/**
 * 
 * @export
 * @interface RecordLinkResponseV2
 */
export interface RecordLinkResponseV2 {
    /**
     * 
     * @type {Array<RecordV2>}
     * @memberof RecordLinkResponseV2
     */
    records?: Array<RecordV2>;
    /**
     * 
     * @type {Array<RecordLink>}
     * @memberof RecordLinkResponseV2
     */
    recordLinks?: Array<RecordLink>;
}

export function RecordLinkResponseV2FromJSON(json: any): RecordLinkResponseV2 {
    return RecordLinkResponseV2FromJSONTyped(json, false);
}

export function RecordLinkResponseV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordLinkResponseV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'records': !exists(json, 'records') ? undefined : (json['records'] as Array<RecordV2>).map(RecordV2FromJSON),
        'recordLinks': !exists(json, 'recordLinks') ? undefined : (json['recordLinks'] as Array<RecordLink>).map(RecordLinkFromJSON),
    };
}

export function RecordLinkResponseV2ToJSON(value?: RecordLinkResponseV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'records': value.records === undefined ? undefined : (value.records as Array<RecordV2>).map(RecordV2ToJSON),
        'recordLinks': value.recordLinks === undefined ? undefined : (value.recordLinks as Array<RecordLink>).map(RecordLinkToJSON),
    };
}

