/* tslint:disable */
/* eslint-disable */
/**
 * Session Service OpenAPI Definition
 * Title
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthStartV2
 */
export interface AuthStartV2 {
    /**
     * 
     * @type {string}
     * @memberof AuthStartV2
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof AuthStartV2
     */
    qxTenantId: string;
    /**
     * Duration of idle session timeout in seconds
     * @type {number}
     * @memberof AuthStartV2
     */
    ttl: number;
    /**
     * 
     * @type {string}
     * @memberof AuthStartV2
     */
    qxSessionId: string;
}

export function AuthStartV2FromJSON(json: any): AuthStartV2 {
    return AuthStartV2FromJSONTyped(json, false);
}

export function AuthStartV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthStartV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'qxTenantId': json['qxTenantId'],
        'ttl': json['ttl'],
        'qxSessionId': json['qxSessionId'],
    };
}

export function AuthStartV2ToJSON(value?: AuthStartV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'qxTenantId': value.qxTenantId,
        'ttl': value.ttl,
        'qxSessionId': value.qxSessionId,
    };
}

