/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloseChangeReason } from './CloseChangeReason';
import {
    CloseChangeReasonFromJSON,
    CloseChangeReasonFromJSONTyped,
    CloseChangeReasonToJSON,
} from './CloseChangeReason';

/**
 * When the reason is OTHER, comments are required.
 * @export
 * @interface CloseRecordRequestV2
 */
export interface CloseRecordRequestV2 {
    /**
     * 
     * @type {CloseChangeReason}
     * @memberof CloseRecordRequestV2
     */
    reason: CloseChangeReason;
    /**
     * 
     * @type {string}
     * @memberof CloseRecordRequestV2
     */
    comments?: string;
}

export function CloseRecordRequestV2FromJSON(json: any): CloseRecordRequestV2 {
    return CloseRecordRequestV2FromJSONTyped(json, false);
}

export function CloseRecordRequestV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): CloseRecordRequestV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': CloseChangeReasonFromJSON(json['reason']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function CloseRecordRequestV2ToJSON(value?: CloseRecordRequestV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': CloseChangeReasonToJSON(value.reason),
        'comments': value.comments,
    };
}

