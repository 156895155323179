/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorrectionCommandResponse
 */
export interface CorrectionCommandResponse {
    /**
     * 
     * @type {string}
     * @memberof CorrectionCommandResponse
     */
    requestForCorrectionId?: string;
}

export function CorrectionCommandResponseFromJSON(json: any): CorrectionCommandResponse {
    return CorrectionCommandResponseFromJSONTyped(json, false);
}

export function CorrectionCommandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrectionCommandResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestForCorrectionId': !exists(json, 'requestForCorrectionId') ? undefined : json['requestForCorrectionId'],
    };
}

export function CorrectionCommandResponseToJSON(value?: CorrectionCommandResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestForCorrectionId': value.requestForCorrectionId,
    };
}

