/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeriesRecord
 */
export interface SeriesRecord {
    /**
     * 
     * @type {string}
     * @memberof SeriesRecord
     */
    recordId?: string;
    /**
     * 
     * @type {number}
     * @memberof SeriesRecord
     */
    revisionNumber?: number;
}

export function SeriesRecordFromJSON(json: any): SeriesRecord {
    return SeriesRecordFromJSONTyped(json, false);
}

export function SeriesRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'revisionNumber': !exists(json, 'revisionNumber') ? undefined : json['revisionNumber'],
    };
}

export function SeriesRecordToJSON(value?: SeriesRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordId': value.recordId,
        'revisionNumber': value.revisionNumber,
    };
}

