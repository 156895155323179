/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UpdatePreferenceRequest,
    UpdatePreferenceRequestFromJSON,
    UpdatePreferenceRequestToJSON,
    UpdateTenantPreferenceRequest,
    UpdateTenantPreferenceRequestFromJSON,
    UpdateTenantPreferenceRequestToJSON,
} from '../models';

export interface UpdateMyLanguagePreferenceRequest {
    updatePreferenceRequest: UpdatePreferenceRequest;
}

export interface UpdateTenantLanguagePreferenceRequest {
    updateTenantPreferenceRequest: UpdateTenantPreferenceRequest;
}

/**
 * 
 */
export class LanguagePreferencesApi extends runtime.BaseAPI {

    /**
     * Returns a list of languages available for the user which is sending the request.
     * As a user I want to be able to get available languages for my account
     */
    async fetchAvailableLanguagesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/languages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns a list of languages available for the user which is sending the request.
     * As a user I want to be able to get available languages for my account
     */
    async fetchAvailableLanguages(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.fetchAvailableLanguagesRaw(initOverrides);
        return await response.value();
    }

    /**
     * To fetch my preferred language
     * As a user I want to be able get my language preferences
     */
    async fetchPreferredLanguageByUserIdRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/languages/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * To fetch my preferred language
     * As a user I want to be able get my language preferences
     */
    async fetchPreferredLanguageByUserId(initOverrides?: RequestInit): Promise<string> {
        const response = await this.fetchPreferredLanguageByUserIdRaw(initOverrides);
        return await response.value();
    }

    /**
     * This api allows the user to choose his language preference, if the prefernce is not forced by the tenant.
     * As a user I want to be able update my language preferences
     */
    async updateMyLanguagePreferenceRaw(requestParameters: UpdateMyLanguagePreferenceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.updatePreferenceRequest === null || requestParameters.updatePreferenceRequest === undefined) {
            throw new runtime.RequiredError('updatePreferenceRequest','Required parameter requestParameters.updatePreferenceRequest was null or undefined when calling updateMyLanguagePreference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/languages/me`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePreferenceRequestToJSON(requestParameters.updatePreferenceRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * This api allows the user to choose his language preference, if the prefernce is not forced by the tenant.
     * As a user I want to be able update my language preferences
     */
    async updateMyLanguagePreference(requestParameters: UpdateMyLanguagePreferenceRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateMyLanguagePreferenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This api allows the admin to choose the language preference.
     * As an admin I want to be able update language preference for the tenant
     */
    async updateTenantLanguagePreferenceRaw(requestParameters: UpdateTenantLanguagePreferenceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.updateTenantPreferenceRequest === null || requestParameters.updateTenantPreferenceRequest === undefined) {
            throw new runtime.RequiredError('updateTenantPreferenceRequest','Required parameter requestParameters.updateTenantPreferenceRequest was null or undefined when calling updateTenantLanguagePreference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/languages/tenant`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTenantPreferenceRequestToJSON(requestParameters.updateTenantPreferenceRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * This api allows the admin to choose the language preference.
     * As an admin I want to be able update language preference for the tenant
     */
    async updateTenantLanguagePreference(requestParameters: UpdateTenantLanguagePreferenceRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateTenantLanguagePreferenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
