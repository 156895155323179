import { de, enUS, es, fr, it, ja, ko, pl, pt, ru, vi, zhHK, zhTW } from "date-fns/locale";
import { LanguageCode } from "./LanguageCode";

export const DateFnsLocale = {
    [LanguageCode.Chinese]: zhHK,
    [LanguageCode.Chinese_Traditional]: zhHK,
    [LanguageCode.English]: enUS,
    [LanguageCode.French]: fr,
    [LanguageCode.German]: de,
    [LanguageCode.Italian]: it,
    [LanguageCode.Japanese]: ja,
    [LanguageCode.Korean]: ko,
    [LanguageCode.Polish]: pl,
    [LanguageCode.Portuguese]: pt,
    [LanguageCode.Russian]: ru,
    [LanguageCode.Spanish]: es,
    [LanguageCode.Taiwanese]: zhTW,
    [LanguageCode.Vietnamese]: vi,
} as Record<string, Locale>;
