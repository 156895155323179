/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActionItemDeletionRequest
 */
export interface ActionItemDeletionRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionItemDeletionRequest
     */
    message?: string;
}

export function ActionItemDeletionRequestFromJSON(json: any): ActionItemDeletionRequest {
    return ActionItemDeletionRequestFromJSONTyped(json, false);
}

export function ActionItemDeletionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionItemDeletionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ActionItemDeletionRequestToJSON(value?: ActionItemDeletionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
    };
}

