/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';

/**
 * 
 * @export
 * @interface DateTimeCapture
 */
export interface DateTimeCapture extends FormElement {
}

export function DateTimeCaptureFromJSON(json: any): DateTimeCapture {
    return DateTimeCaptureFromJSONTyped(json, false);
}

export function DateTimeCaptureFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateTimeCapture {
    return json;
}

export function DateTimeCaptureToJSON(value?: DateTimeCapture | null): any {
    return value;
}

