/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResult } from './PaginationResult';
import {
    PaginationResultFromJSON,
    PaginationResultFromJSONTyped,
    PaginationResultToJSON,
} from './PaginationResult';
import type { RecordPreview } from './RecordPreview';
import {
    RecordPreviewFromJSON,
    RecordPreviewFromJSONTyped,
    RecordPreviewToJSON,
} from './RecordPreview';

/**
 * 
 * @export
 * @interface RecordPreviewsResponse
 */
export interface RecordPreviewsResponse {
    /**
     * 
     * @type {PaginationResult}
     * @memberof RecordPreviewsResponse
     */
    pagination?: PaginationResult;
    /**
     * 
     * @type {Array<RecordPreview>}
     * @memberof RecordPreviewsResponse
     */
    recordPreviews?: Array<RecordPreview>;
}

export function RecordPreviewsResponseFromJSON(json: any): RecordPreviewsResponse {
    return RecordPreviewsResponseFromJSONTyped(json, false);
}

export function RecordPreviewsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordPreviewsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationResultFromJSON(json['pagination']),
        'recordPreviews': !exists(json, 'recordPreviews') ? undefined : (json['recordPreviews'] as Array<RecordPreview>).map(RecordPreviewFromJSON),
    };
}

export function RecordPreviewsResponseToJSON(value?: RecordPreviewsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationResultToJSON(value.pagination),
        'recordPreviews': value.recordPreviews === undefined ? undefined : (value.recordPreviews as Array<RecordPreview>).map(RecordPreviewToJSON),
    };
}

