/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CloseChangeReason {
    ChangeCanceled = 'CHANGE_CANCELED',
    CorrectionOnHold = 'CORRECTION_ON_HOLD',
    EventEscalated = 'EVENT_ESCALATED',
    Other = 'OTHER'
}

export function CloseChangeReasonFromJSON(json: any): CloseChangeReason {
    return CloseChangeReasonFromJSONTyped(json, false);
}

export function CloseChangeReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloseChangeReason {
    return json as CloseChangeReason;
}

export function CloseChangeReasonToJSON(value?: CloseChangeReason | null): any {
    return value as any;
}

