/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteTasksCommand
 */
export interface DeleteTasksCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteTasksCommand
     */
    taskIds?: Array<string>;
}

export function DeleteTasksCommandFromJSON(json: any): DeleteTasksCommand {
    return DeleteTasksCommandFromJSONTyped(json, false);
}

export function DeleteTasksCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteTasksCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskIds': !exists(json, 'taskIds') ? undefined : (json['taskIds'] as Array<string>),
    };
}

export function DeleteTasksCommandToJSON(value?: DeleteTasksCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskIds': value.taskIds === undefined ? undefined : (value.taskIds as Array<string>),
    };
}

