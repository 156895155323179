/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';
import type { ToggleAllOf } from './ToggleAllOf';
import {
    ToggleAllOfFromJSON,
    ToggleAllOfFromJSONTyped,
    ToggleAllOfToJSON,
} from './ToggleAllOf';
import type { ToggleCaptureMode } from './ToggleCaptureMode';
import {
    ToggleCaptureModeFromJSON,
    ToggleCaptureModeFromJSONTyped,
    ToggleCaptureModeToJSON,
} from './ToggleCaptureMode';

/**
 * 
 * @export
 * @interface Toggle
 */
export interface Toggle extends FormElement {
    /**
     * 
     * @type {ToggleCaptureMode}
     * @memberof Toggle
     */
    toggleCaptureMode?: ToggleCaptureMode;
}

export function ToggleFromJSON(json: any): Toggle {
    return ToggleFromJSONTyped(json, false);
}

export function ToggleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Toggle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FormElementFromJSONTyped(json, ignoreDiscriminator),
        'toggleCaptureMode': !exists(json, 'toggleCaptureMode') ? undefined : ToggleCaptureModeFromJSON(json['toggleCaptureMode']),
    };
}

export function ToggleToJSON(value?: Toggle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FormElementToJSON(value),
        'toggleCaptureMode': ToggleCaptureModeToJSON(value.toggleCaptureMode),
    };
}

