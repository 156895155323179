/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Attachment } from './Attachment';
import {
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './Attachment';
import type { Correction } from './Correction';
import {
    CorrectionFromJSON,
    CorrectionFromJSONTyped,
    CorrectionToJSON,
} from './Correction';
import type { DirectCorrection } from './DirectCorrection';
import {
    DirectCorrectionFromJSON,
    DirectCorrectionFromJSONTyped,
    DirectCorrectionToJSON,
} from './DirectCorrection';
import type { ElementData } from './ElementData';
import {
    ElementDataFromJSON,
    ElementDataFromJSONTyped,
    ElementDataToJSON,
} from './ElementData';
import type { ExecutionPlanElement } from './ExecutionPlanElement';
import {
    ExecutionPlanElementFromJSON,
    ExecutionPlanElementFromJSONTyped,
    ExecutionPlanElementToJSON,
} from './ExecutionPlanElement';
import type { ExecutionPlanNode } from './ExecutionPlanNode';
import {
    ExecutionPlanNodeFromJSON,
    ExecutionPlanNodeFromJSONTyped,
    ExecutionPlanNodeToJSON,
} from './ExecutionPlanNode';
import type { ExecutionPlanSection } from './ExecutionPlanSection';
import {
    ExecutionPlanSectionFromJSON,
    ExecutionPlanSectionFromJSONTyped,
    ExecutionPlanSectionToJSON,
} from './ExecutionPlanSection';
import type { ExecutionPlanWorkflow } from './ExecutionPlanWorkflow';
import {
    ExecutionPlanWorkflowFromJSON,
    ExecutionPlanWorkflowFromJSONTyped,
    ExecutionPlanWorkflowToJSON,
} from './ExecutionPlanWorkflow';
import type { Record } from './Record';
import {
    RecordFromJSON,
    RecordFromJSONTyped,
    RecordToJSON,
} from './Record';
import type { Signature } from './Signature';
import {
    SignatureFromJSON,
    SignatureFromJSONTyped,
    SignatureToJSON,
} from './Signature';
import type { Task } from './Task';
import {
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
} from './Task';

/**
 * 
 * @export
 * @interface RecordDetailView
 */
export interface RecordDetailView {
    /**
     * 
     * @type {Array<ExecutionPlanSection>}
     * @memberof RecordDetailView
     */
    sections?: Array<ExecutionPlanSection>;
    /**
     * 
     * @type {Array<ExecutionPlanElement>}
     * @memberof RecordDetailView
     */
    elements?: Array<ExecutionPlanElement>;
    /**
     * 
     * @type {Array<ElementData>}
     * @memberof RecordDetailView
     */
    elementDatas?: Array<ElementData>;
    /**
     * 
     * @type {Array<ExecutionPlanNode>}
     * @memberof RecordDetailView
     */
    nodes?: Array<ExecutionPlanNode>;
    /**
     * 
     * @type {Array<Correction>}
     * @memberof RecordDetailView
     */
    corrections?: Array<Correction>;
    /**
     * 
     * @type {Array<DirectCorrection>}
     * @memberof RecordDetailView
     */
    directCorrections?: Array<DirectCorrection>;
    /**
     * 
     * @type {ExecutionPlanWorkflow}
     * @memberof RecordDetailView
     */
    executionPlanWorkflow?: ExecutionPlanWorkflow;
    /**
     * 
     * @type {Array<Task>}
     * @memberof RecordDetailView
     */
    tasks?: Array<Task>;
    /**
     * 
     * @type {Record}
     * @memberof RecordDetailView
     */
    record?: Record;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof RecordDetailView
     */
    attachments?: Array<Attachment>;
    /**
     * 
     * @type {Array<Signature>}
     * @memberof RecordDetailView
     */
    signatures?: Array<Signature>;
}

export function RecordDetailViewFromJSON(json: any): RecordDetailView {
    return RecordDetailViewFromJSONTyped(json, false);
}

export function RecordDetailViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordDetailView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': !exists(json, 'sections') ? undefined : (json['sections'] as Array<ExecutionPlanSection>).map(ExecutionPlanSectionFromJSON),
        'elements': !exists(json, 'elements') ? undefined : (json['elements'] as Array<ExecutionPlanElement>).map(ExecutionPlanElementFromJSON),
        'elementDatas': !exists(json, 'elementDatas') ? undefined : (json['elementDatas'] as Array<ElementData>).map(ElementDataFromJSON),
        'nodes': !exists(json, 'nodes') ? undefined : (json['nodes'] as Array<ExecutionPlanNode>).map(ExecutionPlanNodeFromJSON),
        'corrections': !exists(json, 'corrections') ? undefined : (json['corrections'] as Array<Correction>).map(CorrectionFromJSON),
        'directCorrections': !exists(json, 'directCorrections') ? undefined : (json['directCorrections'] as Array<DirectCorrection>).map(DirectCorrectionFromJSON),
        'executionPlanWorkflow': !exists(json, 'executionPlanWorkflow') ? undefined : ExecutionPlanWorkflowFromJSON(json['executionPlanWorkflow']),
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] as Array<Task>).map(TaskFromJSON),
        'record': !exists(json, 'record') ? undefined : RecordFromJSON(json['record']),
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] as Array<Attachment>).map(AttachmentFromJSON),
        'signatures': !exists(json, 'signatures') ? undefined : (json['signatures'] as Array<Signature>).map(SignatureFromJSON),
    };
}

export function RecordDetailViewToJSON(value?: RecordDetailView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': value.sections === undefined ? undefined : (value.sections as Array<ExecutionPlanSection>).map(ExecutionPlanSectionToJSON),
        'elements': value.elements === undefined ? undefined : (value.elements as Array<ExecutionPlanElement>).map(ExecutionPlanElementToJSON),
        'elementDatas': value.elementDatas === undefined ? undefined : (value.elementDatas as Array<ElementData>).map(ElementDataToJSON),
        'nodes': value.nodes === undefined ? undefined : (value.nodes as Array<ExecutionPlanNode>).map(ExecutionPlanNodeToJSON),
        'corrections': value.corrections === undefined ? undefined : (value.corrections as Array<Correction>).map(CorrectionToJSON),
        'directCorrections': value.directCorrections === undefined ? undefined : (value.directCorrections as Array<DirectCorrection>).map(DirectCorrectionToJSON),
        'executionPlanWorkflow': ExecutionPlanWorkflowToJSON(value.executionPlanWorkflow),
        'tasks': value.tasks === undefined ? undefined : (value.tasks as Array<Task>).map(TaskToJSON),
        'record': RecordToJSON(value.record),
        'attachments': value.attachments === undefined ? undefined : (value.attachments as Array<Attachment>).map(AttachmentToJSON),
        'signatures': value.signatures === undefined ? undefined : (value.signatures as Array<Signature>).map(SignatureToJSON),
    };
}

