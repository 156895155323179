/* tslint:disable */
/* eslint-disable */
/**
 * Task Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of the task:
 * - `active`    - The task is active
 * - `cancelled` - The task has been cancelled, work not complete
 * - `completed` - The task has been completed
 * - `unclaimed` - The task has not been claimed. Not all tasks require being claimed before they're 'active'
 * @export
 * @enum {string}
 */
export enum TaskStatus {
    Active = 'active',
    Cancelled = 'cancelled',
    Completed = 'completed',
    Unclaimed = 'unclaimed'
}

export function TaskStatusFromJSON(json: any): TaskStatus {
    return TaskStatusFromJSONTyped(json, false);
}

export function TaskStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStatus {
    return json as TaskStatus;
}

export function TaskStatusToJSON(value?: TaskStatus | null): any {
    return value as any;
}

