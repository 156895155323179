/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SettingLevel {
    System = 'SYSTEM',
    Tenant = 'TENANT',
    User = 'USER'
}

export function SettingLevelFromJSON(json: any): SettingLevel {
    return SettingLevelFromJSONTyped(json, false);
}

export function SettingLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingLevel {
    return json as SettingLevel;
}

export function SettingLevelToJSON(value?: SettingLevel | null): any {
    return value as any;
}

