/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApprovalType } from './ApprovalType';
import {
    ApprovalTypeFromJSON,
    ApprovalTypeFromJSONTyped,
    ApprovalTypeToJSON,
} from './ApprovalType';

/**
 * 
 * @export
 * @interface Approval
 */
export interface Approval {
    /**
     * 
     * @type {string}
     * @memberof Approval
     */
    id?: string;
    /**
     * 
     * @type {ApprovalType}
     * @memberof Approval
     */
    type?: ApprovalType;
    /**
     * 
     * @type {string}
     * @memberof Approval
     */
    nodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Approval
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Approval
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Approval
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Approval
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Approval
     */
    requireRationale?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Approval
     */
    required?: boolean;
}

export function ApprovalFromJSON(json: any): Approval {
    return ApprovalFromJSONTyped(json, false);
}

export function ApprovalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Approval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ApprovalTypeFromJSON(json['type']),
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'groupIds': !exists(json, 'groupIds') ? undefined : (json['groupIds'] as Array<string>),
        'requireRationale': !exists(json, 'requireRationale') ? undefined : json['requireRationale'],
        'required': !exists(json, 'required') ? undefined : json['required'],
    };
}

export function ApprovalToJSON(value?: Approval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ApprovalTypeToJSON(value.type),
        'nodeId': value.nodeId,
        'name': value.name,
        'description': value.description,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'groupIds': value.groupIds === undefined ? undefined : (value.groupIds as Array<string>),
        'requireRationale': value.requireRationale,
        'required': value.required,
    };
}

