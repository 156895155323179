/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CancelChangeReason {
    CreatedInError = 'CREATED_IN_ERROR',
    Duplicate = 'DUPLICATE',
    IncorrectEventType = 'INCORRECT_EVENT_TYPE',
    Other = 'OTHER'
}

export function CancelChangeReasonFromJSON(json: any): CancelChangeReason {
    return CancelChangeReasonFromJSONTyped(json, false);
}

export function CancelChangeReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelChangeReason {
    return json as CancelChangeReason;
}

export function CancelChangeReasonToJSON(value?: CancelChangeReason | null): any {
    return value as any;
}

