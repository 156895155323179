/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeStatusResponse
 */
export interface ChangeStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof ChangeStatusResponse
     */
    recordId?: string;
}

export function ChangeStatusResponseFromJSON(json: any): ChangeStatusResponse {
    return ChangeStatusResponseFromJSONTyped(json, false);
}

export function ChangeStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
    };
}

export function ChangeStatusResponseToJSON(value?: ChangeStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordId': value.recordId,
    };
}

