/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionItem } from './ActionItem';
import {
    ActionItemFromJSON,
    ActionItemFromJSONTyped,
    ActionItemToJSON,
} from './ActionItem';

/**
 * 
 * @export
 * @interface ActionItemsOnNodeResponse
 */
export interface ActionItemsOnNodeResponse {
    /**
     * 
     * @type {Array<ActionItem>}
     * @memberof ActionItemsOnNodeResponse
     */
    actionItems?: Array<ActionItem>;
}

export function ActionItemsOnNodeResponseFromJSON(json: any): ActionItemsOnNodeResponse {
    return ActionItemsOnNodeResponseFromJSONTyped(json, false);
}

export function ActionItemsOnNodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionItemsOnNodeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionItems': !exists(json, 'actionItems') ? undefined : (json['actionItems'] as Array<ActionItem>).map(ActionItemFromJSON),
    };
}

export function ActionItemsOnNodeResponseToJSON(value?: ActionItemsOnNodeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionItems': value.actionItems === undefined ? undefined : (value.actionItems as Array<ActionItem>).map(ActionItemToJSON),
    };
}

