import { ConfigurationLink, ConfigurationRecordLink } from ".";
import {
    DateTime as DateTimeElement,
    Dropdown as DropdownElement,
    FormElement,
    FormElementType,
    LongText as LongTextElement,
    MultipleSelect as MultipleSelectElement,
    Number as NumberElement,
    ShortText as ShortTextElement,
    SingleSelect as SingleSelectElement,
    Table as TableElement,
} from "../../../generated/record-configuration";
/**
 * A layout of nodes in a workflow.
 */
export type NodeLayout = { [key: string]: Array<string> };

/**
 * A point between two nodes in a workflow.
 */
export interface Point {
    from?: string;
    to?: string;
}

// When exported as type, editor language server somehow thinks it is the primitive element "Number"
// Which makes things a little bit awkward
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HasNegativeValue extends NumberElement {}
const negativeValueTypes: FormElementType[] = [FormElementType.Number];
export function canFormElementHaveNegativeValue(formElement: FormElement): formElement is HasNegativeValue {
    return negativeValueTypes.includes(formElement.type);
}

export type HasCharacterLimit = ShortTextElement | LongTextElement;
const characterLimitTypes: FormElementType[] = [FormElementType.ShortText, FormElementType.LongText];
export function canFormElementUseCharacterLimit(formElement: FormElement): formElement is HasCharacterLimit {
    return characterLimitTypes.includes(formElement.type);
}

export type HasHintText =
    | DateTimeElement
    | DropdownElement
    | LongTextElement
    | MultipleSelectElement
    | NumberElement
    | ConfigurationLink
    | ConfigurationRecordLink
    | ShortTextElement
    | SingleSelectElement
    | TableElement;

const hintTextTypes: FormElementType[] = [
    FormElementType.DateTime,
    FormElementType.Dropdown,
    FormElementType.Link,
    FormElementType.LongText,
    FormElementType.MultipleSelect,
    FormElementType.Number,
    FormElementType.RecordLink,
    FormElementType.ShortText,
    FormElementType.SingleSelect,
    FormElementType.Table,
];

export function canFormElementUseHintText(formElement: FormElement): formElement is HasHintText {
    return hintTextTypes.includes(formElement.type);
}

export type HasLinkTypes = ConfigurationLink;
const linkTypes: FormElementType[] = [FormElementType.Link];
export function canFormElementUseLinks(formElement: FormElement): formElement is HasLinkTypes {
    return linkTypes.includes(formElement.type);
}

export type HasRecordLinkTypes = ConfigurationRecordLink;
const recordLinkTypes: FormElementType[] = [FormElementType.RecordLink];
export function canFormElementUseRecordLinks(formElement: FormElement): formElement is HasRecordLinkTypes {
    return recordLinkTypes.includes(formElement.type);
}

export type HasMultiple = DropdownElement;
export function canFormElementUseMultiple(formElement: FormElement): formElement is HasMultiple {
    return formElement.type === FormElementType.Dropdown;
}

export type HasOptions = DropdownElement | SingleSelectElement | MultipleSelectElement;
const optionsTypes: FormElementType[] = [
    FormElementType.Dropdown,
    FormElementType.SingleSelect,
    FormElementType.MultipleSelect,
];
export function canFormElementUseOptions(formElement: FormElement): formElement is HasOptions {
    return optionsTypes.includes(formElement.type);
}

export type HasPlaceholder = DropdownElement | LongTextElement | NumberElement | ShortTextElement;
const placeholderTypes: FormElementType[] = [
    FormElementType.Dropdown,
    FormElementType.LongText,
    FormElementType.Number,
    FormElementType.ShortText,
];
export function canFormElementUsePlaceholder(formElement: FormElement): formElement is HasPlaceholder {
    return placeholderTypes.includes(formElement.type);
}
