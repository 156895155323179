/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface LockElementRequest {
    elementId: string;
}

export interface UnlockElementRequest {
    elementId: string;
}

/**
 * 
 */
export class RecordElementLockUnlockApi extends runtime.BaseAPI {

    /**
     * Request the specified element be locked for editing
     */
    async lockElementRaw(requestParameters: LockElementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.elementId === null || requestParameters.elementId === undefined) {
            throw new runtime.RequiredError('elementId','Required parameter requestParameters.elementId was null or undefined when calling lockElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/element/v1/{elementId}/action/lock`.replace(`{${"elementId"}}`, encodeURIComponent(String(requestParameters.elementId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request the specified element be locked for editing
     */
    async lockElement(requestParameters: LockElementRequest, initOverrides?: RequestInit): Promise<void> {
        await this.lockElementRaw(requestParameters, initOverrides);
    }

    /**
     * Request the specified element be unlocked from editing
     */
    async unlockElementRaw(requestParameters: UnlockElementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.elementId === null || requestParameters.elementId === undefined) {
            throw new runtime.RequiredError('elementId','Required parameter requestParameters.elementId was null or undefined when calling unlockElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/element/v1/{elementId}/action/unlock`.replace(`{${"elementId"}}`, encodeURIComponent(String(requestParameters.elementId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request the specified element be unlocked from editing
     */
    async unlockElement(requestParameters: UnlockElementRequest, initOverrides?: RequestInit): Promise<void> {
        await this.unlockElementRaw(requestParameters, initOverrides);
    }

}
