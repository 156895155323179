import flagsmith from "flagsmith";
import { applicationSettingsStore } from "../store";

export const localFeatureFlagsStorageKey = "featureFlags";
export type FeatureFlagsMap<TKey extends string> = Partial<{
    [key in TKey]: boolean;
}>;

export function hasFeature(flagName: string) {
    const localFlags = getLocalFeatureFlags();
    return flagName in localFlags ? !!localFlags[flagName] : flagsmith.hasFeature(flagName);
}

export function getLocalFeatureFlags<TKey extends string>() {
    const flags = JSON.parse(localStorage.getItem(localFeatureFlagsStorageKey) || "{}");
    return flags as FeatureFlagsMap<TKey>;
}

// Use this to make sure that we don't re-identify the user in flagsmith when their token renews
let userIdentificationCache: { tenantId: string | null; userId: string | null } = {
    tenantId: null,
    userId: null,
};
export function identifyFeatureFlagUser() {
    applicationSettingsStore.subscribe(async ({ accessToken, uid, claims }, { accessToken: oldAccessToken }) => {
        if (accessToken !== oldAccessToken) {
            const userId = uid ?? null;
            const tenantId = claims?.tenantId ?? null;
            if (
                userId &&
                tenantId &&
                (userId !== userIdentificationCache.userId || tenantId !== userIdentificationCache.tenantId)
            ) {
                userIdentificationCache = {
                    tenantId,
                    userId,
                };
                const hostName = window?.location?.hostname;
                await flagsmith.identify(userId, { hostName, tenantId });
            }
        }
    });
}
