/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormElement } from './FormElement';
import {
    FormElementFromJSON,
    FormElementFromJSONTyped,
    FormElementToJSON,
} from './FormElement';
import type { FormElementType } from './FormElementType';
import {
    FormElementTypeFromJSON,
    FormElementTypeFromJSONTyped,
    FormElementTypeToJSON,
} from './FormElementType';

/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment extends FormElement {
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    return json;
}

export function AttachmentToJSON(value?: Attachment | null): any {
    return value;
}

