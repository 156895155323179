/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Esignature
 */
export interface Esignature {
    /**
     * 
     * @type {string}
     * @memberof Esignature
     */
    type?: EsignatureTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Esignature
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof Esignature
     */
    notes?: string;
}

/**
* @export
* @enum {string}
*/
export enum EsignatureTypeEnum {
    Jwt = 'okta-jwt',
    Ticket = 'okta-ticket'
}

export function EsignatureFromJSON(json: any): Esignature {
    return EsignatureFromJSONTyped(json, false);
}

export function EsignatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Esignature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function EsignatureToJSON(value?: Esignature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'token': value.token,
        'notes': value.notes,
    };
}

