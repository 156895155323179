/* tslint:disable */
/* eslint-disable */
/**
 * List-Service OpenAPI Definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PcsListType {
    CustomList = 'CUSTOM_LIST',
    MastercontrolList = 'MASTERCONTROL_LIST'
}

export function PcsListTypeFromJSON(json: any): PcsListType {
    return PcsListTypeFromJSONTyped(json, false);
}

export function PcsListTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PcsListType {
    return json as PcsListType;
}

export function PcsListTypeToJSON(value?: PcsListType | null): any {
    return value as any;
}

