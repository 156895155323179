/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Rule } from './Rule';
import {
    RuleFromJSON,
    RuleFromJSONTyped,
    RuleToJSON,
} from './Rule';
import type { RuleActionValue } from './RuleActionValue';
import {
    RuleActionValueFromJSON,
    RuleActionValueFromJSONTyped,
    RuleActionValueToJSON,
} from './RuleActionValue';
import type { RuleType } from './RuleType';
import {
    RuleTypeFromJSON,
    RuleTypeFromJSONTyped,
    RuleTypeToJSON,
} from './RuleType';

/**
 * 
 * @export
 * @interface FormOptionNodeRule
 */
export interface FormOptionNodeRule extends Rule {
    /**
     * the node that is the target of this rule
     * @type {string}
     * @memberof FormOptionNodeRule
     */
    targetNodeId?: string;
    /**
     * the form element on which this rule is defined on
     * @type {string}
     * @memberof FormOptionNodeRule
     */
    sourceFormElementId?: string;
    /**
     * the option on which this rule is defined on
     * @type {string}
     * @memberof FormOptionNodeRule
     */
    sourceOptionId?: string;
    /**
     * 
     * @type {RuleActionValue}
     * @memberof FormOptionNodeRule
     */
    ruleActionValue?: RuleActionValue;
}

export function FormOptionNodeRuleFromJSON(json: any): FormOptionNodeRule {
    return FormOptionNodeRuleFromJSONTyped(json, false);
}

export function FormOptionNodeRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormOptionNodeRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...RuleFromJSONTyped(json, ignoreDiscriminator),
        'targetNodeId': !exists(json, 'targetNodeId') ? undefined : json['targetNodeId'],
        'sourceFormElementId': !exists(json, 'sourceFormElementId') ? undefined : json['sourceFormElementId'],
        'sourceOptionId': !exists(json, 'sourceOptionId') ? undefined : json['sourceOptionId'],
        'ruleActionValue': !exists(json, 'ruleActionValue') ? undefined : RuleActionValueFromJSON(json['ruleActionValue']),
    };
}

export function FormOptionNodeRuleToJSON(value?: FormOptionNodeRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...RuleToJSON(value),
        'targetNodeId': value.targetNodeId,
        'sourceFormElementId': value.sourceFormElementId,
        'sourceOptionId': value.sourceOptionId,
        'ruleActionValue': RuleActionValueToJSON(value.ruleActionValue),
    };
}

