/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationReport } from './ValidationReport';
import {
    ValidationReportFromJSON,
    ValidationReportFromJSONTyped,
    ValidationReportToJSON,
} from './ValidationReport';

/**
 * 
 * @export
 * @interface CreateValidationReportResponse
 */
export interface CreateValidationReportResponse {
    /**
     * 
     * @type {ValidationReport}
     * @memberof CreateValidationReportResponse
     */
    validationReport?: ValidationReport;
}

export function CreateValidationReportResponseFromJSON(json: any): CreateValidationReportResponse {
    return CreateValidationReportResponseFromJSONTyped(json, false);
}

export function CreateValidationReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateValidationReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validationReport': !exists(json, 'validationReport') ? undefined : ValidationReportFromJSON(json['validationReport']),
    };
}

export function CreateValidationReportResponseToJSON(value?: CreateValidationReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'validationReport': ValidationReportToJSON(value.validationReport),
    };
}

