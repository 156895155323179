/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Correction } from './Correction';
import {
    CorrectionFromJSON,
    CorrectionFromJSONTyped,
    CorrectionToJSON,
} from './Correction';
import type { PaginationResult } from './PaginationResult';
import {
    PaginationResultFromJSON,
    PaginationResultFromJSONTyped,
    PaginationResultToJSON,
} from './PaginationResult';

/**
 * 
 * @export
 * @interface CorrectionListResponse
 */
export interface CorrectionListResponse {
    /**
     * 
     * @type {PaginationResult}
     * @memberof CorrectionListResponse
     */
    pagination?: PaginationResult;
    /**
     * 
     * @type {Array<Correction>}
     * @memberof CorrectionListResponse
     */
    corrections?: Array<Correction>;
}

export function CorrectionListResponseFromJSON(json: any): CorrectionListResponse {
    return CorrectionListResponseFromJSONTyped(json, false);
}

export function CorrectionListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrectionListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationResultFromJSON(json['pagination']),
        'corrections': !exists(json, 'corrections') ? undefined : (json['corrections'] as Array<Correction>).map(CorrectionFromJSON),
    };
}

export function CorrectionListResponseToJSON(value?: CorrectionListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationResultToJSON(value.pagination),
        'corrections': value.corrections === undefined ? undefined : (value.corrections as Array<Correction>).map(CorrectionToJSON),
    };
}

