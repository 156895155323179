/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApproveOrRejectCorrection
 */
export interface ApproveOrRejectCorrection {
    /**
     * 
     * @type {string}
     * @memberof ApproveOrRejectCorrection
     */
    requestState: ApproveOrRejectCorrectionRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ApproveOrRejectCorrection
     */
    comments?: string;
}

/**
* @export
* @enum {string}
*/
export enum ApproveOrRejectCorrectionRequestStateEnum {
    Approve = 'approve',
    Reject = 'reject'
}

export function ApproveOrRejectCorrectionFromJSON(json: any): ApproveOrRejectCorrection {
    return ApproveOrRejectCorrectionFromJSONTyped(json, false);
}

export function ApproveOrRejectCorrectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveOrRejectCorrection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestState': json['requestState'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function ApproveOrRejectCorrectionToJSON(value?: ApproveOrRejectCorrection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestState': value.requestState,
        'comments': value.comments,
    };
}

