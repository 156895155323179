/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationResponseError
 */
export interface ValidationResponseError {
    /**
     * 
     * @type {string}
     * @memberof ValidationResponseError
     */
    elementId?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationResponseError
     */
    errorKey?: string;
}

export function ValidationResponseErrorFromJSON(json: any): ValidationResponseError {
    return ValidationResponseErrorFromJSONTyped(json, false);
}

export function ValidationResponseErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationResponseError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elementId': !exists(json, 'elementId') ? undefined : json['elementId'],
        'errorKey': !exists(json, 'errorKey') ? undefined : json['errorKey'],
    };
}

export function ValidationResponseErrorToJSON(value?: ValidationResponseError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elementId': value.elementId,
        'errorKey': value.errorKey,
    };
}

