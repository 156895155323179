/* tslint:disable */
/* eslint-disable */
/**
 * Session Service OpenAPI Definition
 * Title
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClaimsResponse
 */
export interface ClaimsResponse {
    /**
     * 
     * @type {string}
     * @memberof ClaimsResponse
     */
    iss?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimsResponse
     */
    sub?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimsResponse
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimsResponse
     */
    exp?: string;
}

export function ClaimsResponseFromJSON(json: any): ClaimsResponse {
    return ClaimsResponseFromJSONTyped(json, false);
}

export function ClaimsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iss': !exists(json, 'iss') ? undefined : json['iss'],
        'sub': !exists(json, 'sub') ? undefined : json['sub'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'exp': !exists(json, 'exp') ? undefined : json['exp'],
    };
}

export function ClaimsResponseToJSON(value?: ClaimsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iss': value.iss,
        'sub': value.sub,
        'uid': value.uid,
        'exp': value.exp,
    };
}

