/* tslint:disable */
/* eslint-disable */
/**
 * Session Service OpenAPI Definition
 * Title
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionStart
 */
export interface SessionStart {
    /**
     * 
     * @type {string}
     * @memberof SessionStart
     */
    pcsSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof SessionStart
     */
    qxSessionId: string;
}

export function SessionStartFromJSON(json: any): SessionStart {
    return SessionStartFromJSONTyped(json, false);
}

export function SessionStartFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionStart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pcsSessionId': json['pcsSessionId'],
        'qxSessionId': json['qxSessionId'],
    };
}

export function SessionStartToJSON(value?: SessionStart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pcsSessionId': value.pcsSessionId,
        'qxSessionId': value.qxSessionId,
    };
}

