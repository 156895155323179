/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewCorrectionCommandRequest
 */
export interface NewCorrectionCommandRequest {
    /**
     * 
     * @type {string}
     * @memberof NewCorrectionCommandRequest
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCorrectionCommandRequest
     */
    nodeToCorrectId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCorrectionCommandRequest
     */
    nodeRequestedFromId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCorrectionCommandRequest
     */
    reason?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewCorrectionCommandRequest
     */
    assignedUserIds?: Array<string>;
}

export function NewCorrectionCommandRequestFromJSON(json: any): NewCorrectionCommandRequest {
    return NewCorrectionCommandRequestFromJSONTyped(json, false);
}

export function NewCorrectionCommandRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewCorrectionCommandRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'nodeToCorrectId': !exists(json, 'nodeToCorrectId') ? undefined : json['nodeToCorrectId'],
        'nodeRequestedFromId': !exists(json, 'nodeRequestedFromId') ? undefined : json['nodeRequestedFromId'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'assignedUserIds': !exists(json, 'assignedUserIds') ? undefined : (json['assignedUserIds'] as Array<string>),
    };
}

export function NewCorrectionCommandRequestToJSON(value?: NewCorrectionCommandRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comments': value.comments,
        'nodeToCorrectId': value.nodeToCorrectId,
        'nodeRequestedFromId': value.nodeRequestedFromId,
        'reason': value.reason,
        'assignedUserIds': value.assignedUserIds === undefined ? undefined : (value.assignedUserIds as Array<string>),
    };
}

