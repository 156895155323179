/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CorrectionStatus } from './CorrectionStatus';
import {
    CorrectionStatusFromJSON,
    CorrectionStatusFromJSONTyped,
    CorrectionStatusToJSON,
} from './CorrectionStatus';

/**
 * 
 * @export
 * @interface Correction
 */
export interface Correction {
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    recordId?: string;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    tenantId?: string;
    /**
     * 
     * @type {CorrectionStatus}
     * @memberof Correction
     */
    status?: CorrectionStatus;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    nodeToCorrectId?: string;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    nodeRequestedFromId?: string;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    userId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Correction
     */
    targetUserIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    comments?: string;
    /**
     * 
     * @type {Date}
     * @memberof Correction
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof Correction
     */
    reason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Correction
     */
    isRework?: boolean;
}

export function CorrectionFromJSON(json: any): Correction {
    return CorrectionFromJSONTyped(json, false);
}

export function CorrectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Correction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'status': !exists(json, 'status') ? undefined : CorrectionStatusFromJSON(json['status']),
        'nodeToCorrectId': !exists(json, 'nodeToCorrectId') ? undefined : json['nodeToCorrectId'],
        'nodeRequestedFromId': !exists(json, 'nodeRequestedFromId') ? undefined : json['nodeRequestedFromId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'targetUserIds': !exists(json, 'targetUserIds') ? undefined : (json['targetUserIds'] as Array<string>),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'timestamp': !exists(json, 'timestamp') || json['timestamp'] === '' ? undefined : new Date(json['timestamp']),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'isRework': !exists(json, 'isRework') ? undefined : json['isRework'],
    };
}

export function CorrectionToJSON(value?: Correction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recordId': value.recordId,
        'tenantId': value.tenantId,
        'status': CorrectionStatusToJSON(value.status),
        'nodeToCorrectId': value.nodeToCorrectId,
        'nodeRequestedFromId': value.nodeRequestedFromId,
        'userId': value.userId,
        'targetUserIds': value.targetUserIds === undefined ? undefined : (value.targetUserIds as Array<string>),
        'comments': value.comments,
        'timestamp': value.timestamp === undefined ? undefined : value.timestamp.toISOString(),
        'reason': value.reason,
        'isRework': value.isRework,
    };
}

