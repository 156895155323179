/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationWarningCodeKey } from './ValidationWarningCodeKey';
import {
    ValidationWarningCodeKeyFromJSON,
    ValidationWarningCodeKeyFromJSONTyped,
    ValidationWarningCodeKeyToJSON,
} from './ValidationWarningCodeKey';

/**
 * 
 * @export
 * @interface ValidationWarning
 */
export interface ValidationWarning {
    /**
     * 
     * @type {string}
     * @memberof ValidationWarning
     */
    id?: string;
    /**
     * 
     * @type {Array<ValidationWarningCodeKey>}
     * @memberof ValidationWarning
     */
    validationWarningCodeKey?: Array<ValidationWarningCodeKey>;
}

export function ValidationWarningFromJSON(json: any): ValidationWarning {
    return ValidationWarningFromJSONTyped(json, false);
}

export function ValidationWarningFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationWarning {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'validationWarningCodeKey': !exists(json, 'validationWarningCodeKey') ? undefined : (json['validationWarningCodeKey'] as Array<ValidationWarningCodeKey>).map(ValidationWarningCodeKeyFromJSON),
    };
}

export function ValidationWarningToJSON(value?: ValidationWarning | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'validationWarningCodeKey': value.validationWarningCodeKey === undefined ? undefined : (value.validationWarningCodeKey as Array<ValidationWarningCodeKey>).map(ValidationWarningCodeKeyToJSON),
    };
}

