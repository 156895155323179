/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActionItem,
    ActionItemFromJSON,
    ActionItemToJSON,
    ActionItemsOnNodeResponse,
    ActionItemsOnNodeResponseFromJSON,
    ActionItemsOnNodeResponseToJSON,
    CreateActionItemCommandRequest,
    CreateActionItemCommandRequestFromJSON,
    CreateActionItemCommandRequestToJSON,
    CreateActionItemCommandResponse,
    CreateActionItemCommandResponseFromJSON,
    CreateActionItemCommandResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface CreateActionItemRequest {
    createActionItemCommandRequest: CreateActionItemCommandRequest;
}

export interface GetActionItemByRecordIdRequest {
    recordId: string;
}

export interface GetActionItemsByNodeIdRequest {
    nodeId: string;
}

/**
 * 
 */
export class ActionItemApi extends runtime.BaseAPI {

    /**
     * Create an action item - license required is Full or Basic
     * Create an action item
     */
    async createActionItemRaw(requestParameters: CreateActionItemRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateActionItemCommandResponse>> {
        if (requestParameters.createActionItemCommandRequest === null || requestParameters.createActionItemCommandRequest === undefined) {
            throw new runtime.RequiredError('createActionItemCommandRequest','Required parameter requestParameters.createActionItemCommandRequest was null or undefined when calling createActionItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/action-item`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateActionItemCommandRequestToJSON(requestParameters.createActionItemCommandRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateActionItemCommandResponseFromJSON(jsonValue));
    }

    /**
     * Create an action item - license required is Full or Basic
     * Create an action item
     */
    async createActionItem(requestParameters: CreateActionItemRequest, initOverrides?: RequestInit): Promise<CreateActionItemCommandResponse> {
        const response = await this.createActionItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the action item that represents this record. If the record is not an action item, it will return a 400
     * Get the action item by its record id
     */
    async getActionItemByRecordIdRaw(requestParameters: GetActionItemByRecordIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionItem>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling getActionItemByRecordId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/action-item`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionItemFromJSON(jsonValue));
    }

    /**
     * Get the action item that represents this record. If the record is not an action item, it will return a 400
     * Get the action item by its record id
     */
    async getActionItemByRecordId(requestParameters: GetActionItemByRecordIdRequest, initOverrides?: RequestInit): Promise<ActionItem> {
        const response = await this.getActionItemByRecordIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get action items on a node given a nodeId. License required is Full or Basic.
     * Get action items on a node
     */
    async getActionItemsByNodeIdRaw(requestParameters: GetActionItemsByNodeIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionItemsOnNodeResponse>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getActionItemsByNodeId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/action-items`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionItemsOnNodeResponseFromJSON(jsonValue));
    }

    /**
     * Get action items on a node given a nodeId. License required is Full or Basic.
     * Get action items on a node
     */
    async getActionItemsByNodeId(requestParameters: GetActionItemsByNodeIdRequest, initOverrides?: RequestInit): Promise<ActionItemsOnNodeResponse> {
        const response = await this.getActionItemsByNodeIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
