/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Setting,
    SettingFromJSON,
    SettingToJSON,
    SettingKey,
    SettingKeyFromJSON,
    SettingKeyToJSON,
    SettingLevel,
    SettingLevelFromJSON,
    SettingLevelToJSON,
    SettingV2,
    SettingV2FromJSON,
    SettingV2ToJSON,
} from '../models';

export interface GetAvailableOptionsByKeyRequest {
    mcApplicationId: string;
    settingKey: SettingKey;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface GetAvailableOptionsByKeyV2Request {
    mcApplicationId: string;
    settingKey: string;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface GetSettingByKeyRequest {
    mcApplicationId: string;
    settingKeys: Array<SettingKey>;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
    level?: SettingLevel;
}

export interface GetSettingByKeyV2Request {
    mcApplicationId: string;
    settingKeys: Array<string>;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
    level?: SettingLevel;
}

export interface UpdateSettingsRequest {
    mcApplicationId: string;
    setting: Array<Setting>;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface UpdateSettingsV2Request {
    mcApplicationId: string;
    settingV2: Array<SettingV2>;
    pcsTenantId?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

/**
 * 
 */
export class PreferencesApi extends runtime.BaseAPI {

    /**
     * Gets available options for given setting by key.
     */
    async getAvailableOptionsByKeyRaw(requestParameters: GetAvailableOptionsByKeyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling getAvailableOptionsByKey.');
        }

        if (requestParameters.settingKey === null || requestParameters.settingKey === undefined) {
            throw new runtime.RequiredError('settingKey','Required parameter requestParameters.settingKey was null or undefined when calling getAvailableOptionsByKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/settings/{setting-key}/options`.replace(`{${"setting-key"}}`, encodeURIComponent(String(requestParameters.settingKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Gets available options for given setting by key.
     */
    async getAvailableOptionsByKey(requestParameters: GetAvailableOptionsByKeyRequest, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.getAvailableOptionsByKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets available options for given setting by key V2.
     */
    async getAvailableOptionsByKeyV2Raw(requestParameters: GetAvailableOptionsByKeyV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<object>>> {
        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling getAvailableOptionsByKeyV2.');
        }

        if (requestParameters.settingKey === null || requestParameters.settingKey === undefined) {
            throw new runtime.RequiredError('settingKey','Required parameter requestParameters.settingKey was null or undefined when calling getAvailableOptionsByKeyV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/settings/v2/{setting-key}/options`.replace(`{${"setting-key"}}`, encodeURIComponent(String(requestParameters.settingKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Gets available options for given setting by key V2.
     */
    async getAvailableOptionsByKeyV2(requestParameters: GetAvailableOptionsByKeyV2Request, initOverrides?: RequestInit): Promise<Array<object>> {
        const response = await this.getAvailableOptionsByKeyV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets preference for user by keys. Default behavior is get to get lowest level setting (user->tenant->system) unless overriden for given setting.
     */
    async getSettingByKeyRaw(requestParameters: GetSettingByKeyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Setting>>> {
        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling getSettingByKey.');
        }

        if (requestParameters.settingKeys === null || requestParameters.settingKeys === undefined) {
            throw new runtime.RequiredError('settingKeys','Required parameter requestParameters.settingKeys was null or undefined when calling getSettingByKey.');
        }

        const queryParameters: any = {};

        if (requestParameters.settingKeys) {
            queryParameters['setting-keys'] = requestParameters.settingKeys;
        }

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SettingFromJSON));
    }

    /**
     * Gets preference for user by keys. Default behavior is get to get lowest level setting (user->tenant->system) unless overriden for given setting.
     */
    async getSettingByKey(requestParameters: GetSettingByKeyRequest, initOverrides?: RequestInit): Promise<Array<Setting>> {
        const response = await this.getSettingByKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets preference for user by keys. Default behavior is get to get lowest level setting (user->tenant->system) unless overriden for given setting.
     */
    async getSettingByKeyV2Raw(requestParameters: GetSettingByKeyV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SettingV2>>> {
        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling getSettingByKeyV2.');
        }

        if (requestParameters.settingKeys === null || requestParameters.settingKeys === undefined) {
            throw new runtime.RequiredError('settingKeys','Required parameter requestParameters.settingKeys was null or undefined when calling getSettingByKeyV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.settingKeys) {
            queryParameters['setting-keys'] = requestParameters.settingKeys;
        }

        if (requestParameters.level !== undefined) {
            queryParameters['level'] = requestParameters.level;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/settings/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SettingV2FromJSON));
    }

    /**
     * Gets preference for user by keys. Default behavior is get to get lowest level setting (user->tenant->system) unless overriden for given setting.
     */
    async getSettingByKeyV2(requestParameters: GetSettingByKeyV2Request, initOverrides?: RequestInit): Promise<Array<SettingV2>> {
        const response = await this.getSettingByKeyV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates settings. Setting keys type must be known type. Cannot update Default level.
     */
    async updateSettingsRaw(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Setting>>> {
        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling updateSettings.');
        }

        if (requestParameters.setting === null || requestParameters.setting === undefined) {
            throw new runtime.RequiredError('setting','Required parameter requestParameters.setting was null or undefined when calling updateSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setting.map(SettingToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SettingFromJSON));
    }

    /**
     * Updates settings. Setting keys type must be known type. Cannot update Default level.
     */
    async updateSettings(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit): Promise<Array<Setting>> {
        const response = await this.updateSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates settings. Setting keys type must be known type. Cannot update Default level.
     */
    async updateSettingsV2Raw(requestParameters: UpdateSettingsV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SettingV2>>> {
        if (requestParameters.mcApplicationId === null || requestParameters.mcApplicationId === undefined) {
            throw new runtime.RequiredError('mcApplicationId','Required parameter requestParameters.mcApplicationId was null or undefined when calling updateSettingsV2.');
        }

        if (requestParameters.settingV2 === null || requestParameters.settingV2 === undefined) {
            throw new runtime.RequiredError('settingV2','Required parameter requestParameters.settingV2 was null or undefined when calling updateSettingsV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.mcApplicationId !== undefined && requestParameters.mcApplicationId !== null) {
            headerParameters['Mc-Application-Id'] = String(requestParameters.mcApplicationId);
        }

        if (requestParameters.pcsTenantId !== undefined && requestParameters.pcsTenantId !== null) {
            headerParameters['pcs-tenantId'] = String(requestParameters.pcsTenantId);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/preferences/settings/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.settingV2.map(SettingV2ToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SettingV2FromJSON));
    }

    /**
     * Updates settings. Setting keys type must be known type. Cannot update Default level.
     */
    async updateSettingsV2(requestParameters: UpdateSettingsV2Request, initOverrides?: RequestInit): Promise<Array<SettingV2>> {
        const response = await this.updateSettingsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
