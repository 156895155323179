/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResult } from './PaginationResult';
import {
    PaginationResultFromJSON,
    PaginationResultFromJSONTyped,
    PaginationResultToJSON,
} from './PaginationResult';
import type { Task } from './Task';
import {
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
} from './Task';

/**
 * 
 * @export
 * @interface TaskListResponse
 */
export interface TaskListResponse {
    /**
     * 
     * @type {PaginationResult}
     * @memberof TaskListResponse
     */
    pagination?: PaginationResult;
    /**
     * 
     * @type {Array<Task>}
     * @memberof TaskListResponse
     */
    tasks?: Array<Task>;
}

export function TaskListResponseFromJSON(json: any): TaskListResponse {
    return TaskListResponseFromJSONTyped(json, false);
}

export function TaskListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationResultFromJSON(json['pagination']),
        'tasks': !exists(json, 'tasks') ? undefined : (json['tasks'] as Array<Task>).map(TaskFromJSON),
    };
}

export function TaskListResponseToJSON(value?: TaskListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationResultToJSON(value.pagination),
        'tasks': value.tasks === undefined ? undefined : (value.tasks as Array<Task>).map(TaskToJSON),
    };
}

